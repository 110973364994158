import { UserApi } from 'promsApi';
import {
  LOGIN_HAS_ERROR,
  LOGIN_IS_LOADING,
  LOGIN, LOGOUT,
  USER_PERMISSIONS
} from './Actions';

import getConfiguation from './Configuration';

const loginHasError = (bool, errorMsg) => {
  return {
    type: LOGIN_HAS_ERROR,
    hasError: bool,
    errorMsg
  };
};

const loginIsLoading = (bool) => {
  return {
    type: LOGIN_IS_LOADING,
    isLoading: bool
  };
};

const updateTokens = (payload) => {
  return {
    type: LOGIN,
    payload
  };
};

const updatePermissions = (response) => {
  console.log(response);
  return {
    type: USER_PERMISSIONS,
    permissions: response.permissions
  };
};

const logout = () => {
  return {
    type: LOGOUT
  };
};

const timeOutLogout = () => {
  return {
    type: LOGOUT,
    timeOut: true
  };
};

const getPermissions = (payload, successFunction) => {
  console.log('Fetching permissions');

  return (dispatch, getState) => {
    dispatch(loginIsLoading(true));
    dispatch(updateTokens(payload));

    const config = getConfiguation(dispatch, getState);
    const userApi = new UserApi(config);
    const request = userApi.getPermissions();

    request.then((response) => {
      console.log(response);
      dispatch(loginIsLoading(false));
      dispatch(updatePermissions(response));
      dispatch(successFunction);
    }).catch((e) => {
      // Report error
      console.log('Error');
      console.log(e);
      dispatch(loginIsLoading(false));
      dispatch(loginHasError(true));
    });
  };
};

export default {
  logout,
  timeOutLogout,
  getPermissions
};
