/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AuditDto } from './AuditDto';
import {
    AuditDtoFromJSON,
    AuditDtoFromJSONTyped,
    AuditDtoToJSON,
} from './AuditDto';

/**
 * Returns audit search results 
 * @export
 * @interface AuditSearchResponse
 */
export interface AuditSearchResponse {
    /**
     * Search terms which produced results
     * @type {{ [key: string]: string; }}
     * @memberof AuditSearchResponse
     */
    searchTerms?: { [key: string]: string; };
    /**
     * Paging page size
     * @type {number}
     * @memberof AuditSearchResponse
     */
    pageSize?: number;
    /**
     * Paging start record
     * @type {number}
     * @memberof AuditSearchResponse
     */
    start?: number;
    /**
     * Total number of patient profiles
     * @type {number}
     * @memberof AuditSearchResponse
     */
    total?: number;
    /**
     * Ordered by
     * @type {string}
     * @memberof AuditSearchResponse
     */
    orderTerm?: string;
    /**
     * Order direction
     * @type {string}
     * @memberof AuditSearchResponse
     */
    orderDirection?: string;
    /**
     * List of audit entries
     * @type {Array<AuditDto>}
     * @memberof AuditSearchResponse
     */
    auditList?: Array<AuditDto>;
}

/**
 * Check if a given object implements the AuditSearchResponse interface.
 */
export function instanceOfAuditSearchResponse(value: object): value is AuditSearchResponse {
    return true;
}

export function AuditSearchResponseFromJSON(json: any): AuditSearchResponse {
    return AuditSearchResponseFromJSONTyped(json, false);
}

export function AuditSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditSearchResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'searchTerms': json['searchTerms'] == null ? undefined : json['searchTerms'],
        'pageSize': json['pageSize'] == null ? undefined : json['pageSize'],
        'start': json['start'] == null ? undefined : json['start'],
        'total': json['total'] == null ? undefined : json['total'],
        'orderTerm': json['orderTerm'] == null ? undefined : json['orderTerm'],
        'orderDirection': json['orderDirection'] == null ? undefined : json['orderDirection'],
        'auditList': json['auditList'] == null ? undefined : ((json['auditList'] as Array<any>).map(AuditDtoFromJSON)),
    };
}

export function AuditSearchResponseToJSON(value?: AuditSearchResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'searchTerms': value['searchTerms'],
        'pageSize': value['pageSize'],
        'start': value['start'],
        'total': value['total'],
        'orderTerm': value['orderTerm'],
        'orderDirection': value['orderDirection'],
        'auditList': value['auditList'] == null ? undefined : ((value['auditList'] as Array<any>).map(AuditDtoToJSON)),
    };
}

