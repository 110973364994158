/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PathwayDto } from './PathwayDto';
import {
    PathwayDtoFromJSON,
    PathwayDtoFromJSONTyped,
    PathwayDtoToJSON,
} from './PathwayDto';

/**
 * Request for an Pathway composition
 * @export
 * @interface PathwayCompositionDto
 */
export interface PathwayCompositionDto {
    /**
     * 
     * @type {PathwayDto}
     * @memberof PathwayCompositionDto
     */
    pathway?: PathwayDto;
    /**
     * The user for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof PathwayCompositionDto
     */
    userId?: string;
    /**
     * The namespace for the userId for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof PathwayCompositionDto
     */
    userIdNamespace?: string;
}

/**
 * Check if a given object implements the PathwayCompositionDto interface.
 */
export function instanceOfPathwayCompositionDto(value: object): value is PathwayCompositionDto {
    return true;
}

export function PathwayCompositionDtoFromJSON(json: any): PathwayCompositionDto {
    return PathwayCompositionDtoFromJSONTyped(json, false);
}

export function PathwayCompositionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PathwayCompositionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'pathway': json['pathway'] == null ? undefined : PathwayDtoFromJSON(json['pathway']),
        'userId': json['userId'] == null ? undefined : json['userId'],
        'userIdNamespace': json['userIdNamespace'] == null ? undefined : json['userIdNamespace'],
    };
}

export function PathwayCompositionDtoToJSON(value?: PathwayCompositionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pathway': PathwayDtoToJSON(value['pathway']),
        'userId': value['userId'],
        'userIdNamespace': value['userIdNamespace'],
    };
}

