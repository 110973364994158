import { WidgetDataEndpointApi } from 'promsApi';
import {
  WIDGET_DATA_FETCH,
  WIDGET_DATA_IS_LOADING,
  WIDGET_DATA_ERROR
} from './Actions';

import getConfiguation from './Configuration';
import onRequest from './Request';

const widgetDataError = (bool, errorMsg) => {
  return {
    type: WIDGET_DATA_ERROR,
    hasError: bool,
    errorMsg
  };
};

const widgetDataLoading = (bool) => {
  return {
    type: WIDGET_DATA_IS_LOADING,
    isLoading: bool
  };
};

const clearWidgetDataError = () => {
  return {
    type: WIDGET_DATA_ERROR,
    hasError: false,
    errorMsg: null
  };
};

const fetchWidgetDataSuccessHandler = (payload) => {
  return {
    type: WIDGET_DATA_FETCH,
    payload
  };
};

const fetchWidgetData = () => {
  console.log('Fetch widget data');

  return (dispatch, getState) => {
    dispatch(widgetDataLoading(true));

    const config = getConfiguation(dispatch, getState);
    const widgetApi = new WidgetDataEndpointApi(config);
    const request = widgetApi.getData();
    onRequest(request, dispatch, fetchWidgetDataSuccessHandler, widgetDataError, widgetDataLoading);
  };
};

export default {
  clearWidgetDataError,
  fetchWidgetData
};
