import {
  FETCH_SITES_LIST,
  ADD_SITE,
  UPDATE_SITE,
  DELETE_SITE,
  CONFIG_LOADING,
  CONFIG_ERROR,
  SITE_SUCCESS_CLEAR,
  FETCH_ROLE_DEFAULT_PERMISSIONS,
  FETCH_PROMS_EMAIL_CONFIG,
  UPDATE_PROMS_EMAIL_CONFIG,
  FETCH_PROMS_PRINT_CONFIG,
  UPDATE_PROMS_PRINT_CONFIG,
  FETCH_LANGUAGE_LIST,
  FETCH_ENABLED_LANGUAGE_LIST,
  LANGUAGE_ENABLE_STATE,
  REPORTING_URLS,
  LOGOUT
} from '../actions/Actions';

const initialState = {
   sites: {list:[]},
   rolePermissions: [],
   permissionCategories: [],
   promsEmailConfig: null,
   promsSmsConfig: null,
   promsPrintConfig: null,
   supportedLanguages: {list:[]},
   enabledLanguages: [],
   reportingUrls: []
};

function updateSiteInArray(array, site) {
    return array.map((item, index) => {
      if (item.id !== site.id) {
        // This isn't the item we care about - keep it as-is
        return item;
      }
  
      return {
        ...item,
        ...site
      };
    });
  }

function updatePromsEmailConfigInArray(array, emailConfig) {
  return array
    .map((item, index) => {
      if (item.id !== emailConfig.id) {
        // This isn't the item we care about - keep it as-is
        return item;
      }

      return emailConfig;
    })
    .sort((a, b) => a.id - b.id);
}

function groupPermissionByCategory(permissionDescriptions) {
  return permissionDescriptions.reduce((r, a) => {
    r[a.category] = r[a.category] || [];
    r[a.category].push(a);
    return r;
  }, Object.create(null));
}

const configResourcesReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case FETCH_ROLE_DEFAULT_PERMISSIONS: {
      console.log('config reducer ', action);

      const permissionCategories = groupPermissionByCategory(action.permissionDescriptions);
      console.log(permissionCategories);
      return {
        ...state,
        rolePermissions: action.rolePermissions,
        permissionCategories,
        isLoading: false
      };
    }
    case FETCH_SITES_LIST: {
      console.log('config reducer ', action);
      const { sites } = action;

      let updatedSites = state.sites;
      if (action.pageStart > 0) {
        const updatedSitesArray = [...updatedSites.list, ...sites];

        updatedSites = {
          ...state.sites,
          list: updatedSitesArray,
          currentTotal: updatedSitesArray.length,
          isLoading: false
        };
      } else {
        updatedSites = {
          ...state,
          list: action.sites,
          currentTotal: sites.length,
          total: action.total,
          searchTerm: action.searchTerm,
          orderTerm: action.orderTerm,
          orderDirection: action.orderDirection,
          isLoading: false,
          fetchTime: action.fetchTime
        };
      }

      return { ...state, sites: updatedSites };
    }
    case ADD_SITE: {
      console.log('config reducer ', action);

      const updatedSitesArray = [...state.sites.list, action.site];
      const updatedSites = {
        ...state.sites,
        list: updatedSitesArray,
        currentTotal: updatedSitesArray.length,
        isLoading: false
      };

      return {
        ...state,
        sites: updatedSites,
        isLoading: false
      };
    }
    case UPDATE_SITE: {
      console.log('config reducer ', action);
      const updatedArray = updateSiteInArray(state.sites.list, action.site);
      const updatedSites = {
        ...state.sites,
        list: updatedArray,
        isLoading: false
      };

      return {
        ...state,
        sites: updatedSites,
        isLoading: false
      };
    }
    case DELETE_SITE: {
      console.log('config reducer ', action);
      const deletedSite = action.site;
      const updatedArray = state.sites.list.filter((site) => site.id !== deletedSite.id);
      const updatedSites = {
        ...state.sites,
        list: updatedArray,
        currentTotal: updatedArray.length,
        isLoading: false
      };

      return {
        ...state,
        sites: updatedSites,
        isLoading: false
      };
    }
    case LOGOUT:
      return initialState;
    case CONFIG_ERROR:
      console.log('config reducer - haserror', action);

      return {
        ...state,
        hasError: action.hasError,
        errorMsg: action.errorMsg,
        updateSuccess: false
      };
    case CONFIG_LOADING:
      console.log('config reducer - isloading', action);

      return { ...state, isLoading: action.isLoading, };
    case SITE_SUCCESS_CLEAR: {
      console.log('config reducer - success clear', action);

      return {
        ...state,
        updateSuccess: false,
        updateSuccessMessage: null
      };
    }
    case FETCH_PROMS_EMAIL_CONFIG: {
      console.log('config reducer - fetch proms email config', action);

            const sortedArray = action.promsEmailConfigs.sort((a,b) => a.id - b.id);

            return Object.assign({}, state, {
                promsEmailConfig: sortedArray.filter(a => a.type === "Long"),
                promsSmsConfig: sortedArray.filter(a => a.type === "Short"),
                isLoading: false
            });
    }
    case FETCH_PROMS_PRINT_CONFIG: {
      console.log('config reducer - fetch proms print config', action);

      return {
        ...state,
        promsPrintConfig: action.promsPrintConfig,
        isLoading: false
      };
    }
        case UPDATE_PROMS_EMAIL_CONFIG:{
            console.log('config reducer - fetch proms email config', action);

            var promsEmailConfig = state.promsEmailConfig;
            var promsSmsConfig = state.promsSmsConfig;
            if (action.promsEmailConfig.type === "Long"){
                promsEmailConfig = updatePromsEmailConfigInArray(state.promsEmailConfig, action.promsEmailConfig);
            } else {
                promsSmsConfig = updatePromsEmailConfigInArray(state.promsSmsConfig, action.promsEmailConfig);
            }

            return Object.assign({}, state, {
                promsEmailConfig: promsEmailConfig,
                promsSmsConfig: promsSmsConfig,
                isLoading: false,
                updateSuccess: true,
                updateSuccessMessage:"Updated proms email config"
            });
        }
    case UPDATE_PROMS_PRINT_CONFIG: {
      console.log('config reducer - fetch proms print config', action);

      return {
        ...state,
        promsPrintConfig: action.promsPrintConfig,
        isLoading: false,
        updateSuccess: true,
        updateSuccessMessage: 'Updated proms printing config'
      };
    }
    case FETCH_LANGUAGE_LIST: {
      console.log('config reducer ', action);
      const { supportedLanguages } = action;

      let updatedSupportedLanguages = state.supportedLanguages;
      if (action.pageStart > 0) {
        const updatedLanguagesArray = [...updatedSupportedLanguages.list, ...supportedLanguages];

        console.log(updatedLanguagesArray);

        updatedSupportedLanguages = {
          ...state.supportedLanguages,
          list: updatedLanguagesArray,
          currentTotal: updatedLanguagesArray.length,
          isLoading: false
        };
      } else {
        updatedSupportedLanguages = {
          ...state,
          list: action.supportedLanguages,
          currentTotal: supportedLanguages.length,
          total: action.total,
          searchTerm: action.searchTerm,
          orderTerm: action.orderTerm,
          orderDirection: action.orderDirection,
          isLoading: false,
          fetchTime: action.fetchTime
        };
      }

      return { ...state, supportedLanguages: updatedSupportedLanguages };
    }
    case FETCH_ENABLED_LANGUAGE_LIST: {
      return {
        ...state,
        enabledLanguages: action.enabledLanguages,
        isLoading: false,
      };
    }
    case LANGUAGE_ENABLE_STATE: {
      const { languageCode } = action;
      const enabled = action.enable;

      const { supportedLanguages } = state;

      const updatedLanguageArray = supportedLanguages.list.map((sp) => {
        if (sp.languageCode === languageCode) {
          console.log('MATCHING CODE');
          sp.enabled = enabled;
        }
        return sp;
      });

      const updatedSupportedLanguages = { ...state.supportedLanguages, list: updatedLanguageArray };

      return {
        ...state,
        enabledLanguages: action.enabledLanguages,
        isLoading: false,
        supportedLanguages: updatedSupportedLanguages
      };
    }
    case REPORTING_URLS: {
      return {
        ...state,
        reportingUrls: action.reportUrls,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default configResourcesReducer;
