/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents procedure and it's templates
 * @export
 * @interface ProcedureExportMethodDto
 */
export interface ProcedureExportMethodDto {
    /**
     * id
     * @type {number}
     * @memberof ProcedureExportMethodDto
     */
    id: number;
    /**
     * Export method name
     * @type {string}
     * @memberof ProcedureExportMethodDto
     */
    name: string;
    /**
     * Procedure exported for the method
     * @type {boolean}
     * @memberof ProcedureExportMethodDto
     */
    validity: boolean;
    /**
     * Generic Code
     * @type {string}
     * @memberof ProcedureExportMethodDto
     */
    genericCode?: string;
    /**
     * Specific Code
     * @type {string}
     * @memberof ProcedureExportMethodDto
     */
    specificCode: string;
}

/**
 * Check if a given object implements the ProcedureExportMethodDto interface.
 */
export function instanceOfProcedureExportMethodDto(value: object): value is ProcedureExportMethodDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('validity' in value) || value['validity'] === undefined) return false;
    if (!('specificCode' in value) || value['specificCode'] === undefined) return false;
    return true;
}

export function ProcedureExportMethodDtoFromJSON(json: any): ProcedureExportMethodDto {
    return ProcedureExportMethodDtoFromJSONTyped(json, false);
}

export function ProcedureExportMethodDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcedureExportMethodDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'validity': json['validity'],
        'genericCode': json['genericCode'] == null ? undefined : json['genericCode'],
        'specificCode': json['specificCode'],
    };
}

export function ProcedureExportMethodDtoToJSON(value?: ProcedureExportMethodDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'validity': value['validity'],
        'genericCode': value['genericCode'],
        'specificCode': value['specificCode'],
    };
}

