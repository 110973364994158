/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a permission description
 * @export
 * @interface PermissionDescriptionDto
 */
export interface PermissionDescriptionDto {
    /**
     * Permission
     * @type {string}
     * @memberof PermissionDescriptionDto
     */
    permission: string;
    /**
     * Label
     * @type {string}
     * @memberof PermissionDescriptionDto
     */
    label: string;
    /**
     * Category
     * @type {string}
     * @memberof PermissionDescriptionDto
     */
    category: string;
}

/**
 * Check if a given object implements the PermissionDescriptionDto interface.
 */
export function instanceOfPermissionDescriptionDto(value: object): value is PermissionDescriptionDto {
    if (!('permission' in value) || value['permission'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('category' in value) || value['category'] === undefined) return false;
    return true;
}

export function PermissionDescriptionDtoFromJSON(json: any): PermissionDescriptionDto {
    return PermissionDescriptionDtoFromJSONTyped(json, false);
}

export function PermissionDescriptionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionDescriptionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'permission': json['permission'],
        'label': json['label'],
        'category': json['category'],
    };
}

export function PermissionDescriptionDtoToJSON(value?: PermissionDescriptionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'permission': value['permission'],
        'label': value['label'],
        'category': value['category'],
    };
}

