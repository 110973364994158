import {
  WIDGET_DATA_FETCH, WIDGET_DATA_IS_LOADING, WIDGET_DATA_ERROR, LOGOUT
} from '../actions/Actions';

const initialState = {
  data: {
    taskTypeCountList: [],
    responseRateList: [],
    expiringProms: 0,
    overDueProms: 0
  },

  hasError: false,
  isLoading: false,
  errorMsg: null,
};

const widgetDataReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case WIDGET_DATA_FETCH: {
      console.log('Fetch dashboard widget data', action);

      const { payload } = action;

      return {
        ...state,
        data: payload,
        isLoading: false
      };
    }
    case LOGOUT:
      return initialState;
    case WIDGET_DATA_IS_LOADING:
      console.log('isloading', action);

      return { ...state, isLoading: action.isLoading, };
    case WIDGET_DATA_ERROR:
      console.log('haserror', action);

      return {
        ...state,
        hasError: action.hasError,
        errorMsg: action.errorMsg
      };
    default:
      return state;
  }
};

export default widgetDataReducer;
