/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PathwayDto } from './PathwayDto';
import {
    PathwayDtoFromJSON,
    PathwayDtoFromJSONTyped,
    PathwayDtoToJSON,
} from './PathwayDto';

/**
 * Request for an Pathway composition
 * @export
 * @interface PathwayResponseDto
 */
export interface PathwayResponseDto {
    /**
     * 
     * @type {PathwayDto}
     * @memberof PathwayResponseDto
     */
    pathway?: PathwayDto;
    /**
     * Uid
     * @type {string}
     * @memberof PathwayResponseDto
     */
    uuid?: string;
}

/**
 * Check if a given object implements the PathwayResponseDto interface.
 */
export function instanceOfPathwayResponseDto(value: object): value is PathwayResponseDto {
    return true;
}

export function PathwayResponseDtoFromJSON(json: any): PathwayResponseDto {
    return PathwayResponseDtoFromJSONTyped(json, false);
}

export function PathwayResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PathwayResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'pathway': json['pathway'] == null ? undefined : PathwayDtoFromJSON(json['pathway']),
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
    };
}

export function PathwayResponseDtoToJSON(value?: PathwayResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pathway': PathwayDtoToJSON(value['pathway']),
        'uuid': value['uuid'],
    };
}

