/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAuthError,
  ApiValidationErrors,
  HospitalSiteDto,
  HospitalSiteSearchResponse,
  PromsEmailConfigDto,
  PromsPrintingConfigDto,
  ReportUrlDto,
  RoleConfigResponseDto,
  SearchRequest,
  SupportedLanguageDto,
  SupportedLanguagesSearchResponse,
  UpdateSupportedLanguageDto,
} from '../models/index';
import {
    ApiAuthErrorFromJSON,
    ApiAuthErrorToJSON,
    ApiValidationErrorsFromJSON,
    ApiValidationErrorsToJSON,
    HospitalSiteDtoFromJSON,
    HospitalSiteDtoToJSON,
    HospitalSiteSearchResponseFromJSON,
    HospitalSiteSearchResponseToJSON,
    PromsEmailConfigDtoFromJSON,
    PromsEmailConfigDtoToJSON,
    PromsPrintingConfigDtoFromJSON,
    PromsPrintingConfigDtoToJSON,
    ReportUrlDtoFromJSON,
    ReportUrlDtoToJSON,
    RoleConfigResponseDtoFromJSON,
    RoleConfigResponseDtoToJSON,
    SearchRequestFromJSON,
    SearchRequestToJSON,
    SupportedLanguageDtoFromJSON,
    SupportedLanguageDtoToJSON,
    SupportedLanguagesSearchResponseFromJSON,
    SupportedLanguagesSearchResponseToJSON,
    UpdateSupportedLanguageDtoFromJSON,
    UpdateSupportedLanguageDtoToJSON,
} from '../models/index';

export interface AddHospitalSiteRequest {
    hospitalSiteDto: HospitalSiteDto;
}

export interface AddPromsEmailConfigRequest {
    promsEmailConfigDto: PromsEmailConfigDto;
}

export interface AddPromsPrintingConfigRequest {
    promsPrintingConfigDto: PromsPrintingConfigDto;
}

export interface DeleteHospitalSiteRequest {
    siteId: string;
    siteName: string;
}

export interface FindHospitalSitesRequest {
    searchRequest: SearchRequest;
}

export interface GetSupportedLanguagesRequest {
    searchRequest: SearchRequest;
}

export interface UpdateSupportedLanguageRequest {
    updateSupportedLanguageDto: UpdateSupportedLanguageDto;
}

/**
 * 
 */
export class ConfigDataEndpointApi extends runtime.BaseAPI {

    /**
     */
    async addHospitalSiteRaw(requestParameters: AddHospitalSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['hospitalSiteDto'] == null) {
            throw new runtime.RequiredError(
                'hospitalSiteDto',
                'Required parameter "hospitalSiteDto" was null or undefined when calling addHospitalSite().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/sites/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HospitalSiteDtoToJSON(requestParameters['hospitalSiteDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addHospitalSite(requestParameters: AddHospitalSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addHospitalSiteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async addPromsEmailConfigRaw(requestParameters: AddPromsEmailConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['promsEmailConfigDto'] == null) {
            throw new runtime.RequiredError(
                'promsEmailConfigDto',
                'Required parameter "promsEmailConfigDto" was null or undefined when calling addPromsEmailConfig().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/promsEmail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PromsEmailConfigDtoToJSON(requestParameters['promsEmailConfigDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addPromsEmailConfig(requestParameters: AddPromsEmailConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addPromsEmailConfigRaw(requestParameters, initOverrides);
    }

    /**
     */
    async addPromsPrintingConfigRaw(requestParameters: AddPromsPrintingConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['promsPrintingConfigDto'] == null) {
            throw new runtime.RequiredError(
                'promsPrintingConfigDto',
                'Required parameter "promsPrintingConfigDto" was null or undefined when calling addPromsPrintingConfig().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/promsPrint`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PromsPrintingConfigDtoToJSON(requestParameters['promsPrintingConfigDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addPromsPrintingConfig(requestParameters: AddPromsPrintingConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addPromsPrintingConfigRaw(requestParameters, initOverrides);
    }

    /**
     * Delete Hospital site
     * Delete Hospital site
     */
    async deleteHospitalSiteRaw(requestParameters: DeleteHospitalSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['siteId'] == null) {
            throw new runtime.RequiredError(
                'siteId',
                'Required parameter "siteId" was null or undefined when calling deleteHospitalSite().'
            );
        }

        if (requestParameters['siteName'] == null) {
            throw new runtime.RequiredError(
                'siteName',
                'Required parameter "siteName" was null or undefined when calling deleteHospitalSite().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['siteName'] != null) {
            queryParameters['siteName'] = requestParameters['siteName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/sites/{siteId}`.replace(`{${"siteId"}}`, encodeURIComponent(String(requestParameters['siteId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Hospital site
     * Delete Hospital site
     */
    async deleteHospitalSite(requestParameters: DeleteHospitalSiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteHospitalSiteRaw(requestParameters, initOverrides);
    }

    /**
     * Return list of sites with search terms
     * Hospital sites
     */
    async findHospitalSitesRaw(requestParameters: FindHospitalSitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HospitalSiteSearchResponse>> {
        if (requestParameters['searchRequest'] == null) {
            throw new runtime.RequiredError(
                'searchRequest',
                'Required parameter "searchRequest" was null or undefined when calling findHospitalSites().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/sites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters['searchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HospitalSiteSearchResponseFromJSON(jsonValue));
    }

    /**
     * Return list of sites with search terms
     * Hospital sites
     */
    async findHospitalSites(requestParameters: FindHospitalSitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HospitalSiteSearchResponse> {
        const response = await this.findHospitalSitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return list of enabled supported languages
     * Enabled Supported Languages
     */
    async getEnabledSupportedLanguagesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SupportedLanguageDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/languages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SupportedLanguageDtoFromJSON));
    }

    /**
     * Return list of enabled supported languages
     * Enabled Supported Languages
     */
    async getEnabledSupportedLanguages(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SupportedLanguageDto>> {
        const response = await this.getEnabledSupportedLanguagesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPermissionConfigRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleConfigResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/permissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleConfigResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPermissionConfig(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleConfigResponseDto> {
        const response = await this.getPermissionConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPromsEmailConfigRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PromsEmailConfigDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/promsEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PromsEmailConfigDtoFromJSON));
    }

    /**
     */
    async getPromsEmailConfig(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PromsEmailConfigDto>> {
        const response = await this.getPromsEmailConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPromsPrintingConfigRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PromsPrintingConfigDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/promsPrint`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PromsPrintingConfigDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPromsPrintingConfig(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PromsPrintingConfigDto> {
        const response = await this.getPromsPrintingConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Return list of report urls
     * Report urls
     */
    async getReportUrlsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReportUrlDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/reports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportUrlDtoFromJSON));
    }

    /**
     * Return list of report urls
     * Report urls
     */
    async getReportUrls(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReportUrlDto>> {
        const response = await this.getReportUrlsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Return list of supported languages
     * Supported Languages
     */
    async getSupportedLanguagesRaw(requestParameters: GetSupportedLanguagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupportedLanguagesSearchResponse>> {
        if (requestParameters['searchRequest'] == null) {
            throw new runtime.RequiredError(
                'searchRequest',
                'Required parameter "searchRequest" was null or undefined when calling getSupportedLanguages().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/languages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters['searchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupportedLanguagesSearchResponseFromJSON(jsonValue));
    }

    /**
     * Return list of supported languages
     * Supported Languages
     */
    async getSupportedLanguages(requestParameters: GetSupportedLanguagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupportedLanguagesSearchResponse> {
        const response = await this.getSupportedLanguagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update supported language
     * Supported Languages
     */
    async updateSupportedLanguageRaw(requestParameters: UpdateSupportedLanguageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SupportedLanguageDto>>> {
        if (requestParameters['updateSupportedLanguageDto'] == null) {
            throw new runtime.RequiredError(
                'updateSupportedLanguageDto',
                'Required parameter "updateSupportedLanguageDto" was null or undefined when calling updateSupportedLanguage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/languages`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSupportedLanguageDtoToJSON(requestParameters['updateSupportedLanguageDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SupportedLanguageDtoFromJSON));
    }

    /**
     * Update supported language
     * Supported Languages
     */
    async updateSupportedLanguage(requestParameters: UpdateSupportedLanguageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SupportedLanguageDto>> {
        const response = await this.updateSupportedLanguageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
