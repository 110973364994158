import {
  FETCH_STAFF_LIST,
  ADD_STAFF_PROFILE,
  UPDATE_STAFF_PROFILE,
  DELETE_STAFF_PROFILE,
  STAFF_UPDATE_ERROR,
  STAFF_LOADING,
  STAFF_SUCCESS_CLEAR,
  DISABLE_STAFF_PROFILE,
  LOGOUT
} from '../actions/Actions';

const initialState = {
  staffList: [],

  hasError: false,
  isLoading: false,
  errorMsg: null,
  updateSuccess: false,
  updateSuccessMessage: null
};

function updateStaffProfileInArray(array, staff) {
  return array.map((item) => {
    if (item.username !== staff.username) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    return {
      ...item,
      ...staff
    };
  });
}

const staffReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case FETCH_STAFF_LIST: {
      console.log('Staff reducer ', action);
      const { staffList } = action;

      if (action.pageStart > 0) {
        const updatedStaffArray = [...state.staffList, ...staffList];

        return {
          ...state,
          staffList: updatedStaffArray,
          currentTotal: updatedStaffArray.length,
          isLoading: false
        };
      }
      return {
        ...state,
        staffList,
        currentTotal: staffList.length,
        total: action.total,
        searchTerm: action.searchTerm,
        orderTerm: action.orderTerm,
        orderDirection: action.orderDirection,
        isLoading: false,
        fetchTime: action.fetchTime
      };
    }
    case ADD_STAFF_PROFILE: {
      console.log('Staff reducer ', action);
      console.log(state.staffList);
      return {
        ...state,
        staffList: [...state.staffList, action.staffProfile],
        isLoading: false
      };
    }
    case UPDATE_STAFF_PROFILE: {
      console.log('Staff reducer ', action);
      const updatedArray = updateStaffProfileInArray(state.staffList, action.staffProfile);
      return {
        ...state,
        staffList: updatedArray,
        isLoading: false
      };
    }
    case DELETE_STAFF_PROFILE: {
      console.log('Staff reducer ', action);
      const deletedProfile = action.staffProfile;
      const updatedArray = state.staffList.filter((p) => p.username !== deletedProfile.username);
      return {
        ...state,
        staffList: updatedArray,
        isLoading: false
      };
    }
    case DISABLE_STAFF_PROFILE: {
      console.log('profile disabled success', action);
      const updatedArray = updateStaffProfileInArray(state.staffList, action.staffProfile);
      return {
        ...state,
        staffList: updatedArray,
        isLoading: false
      };
    }
    case LOGOUT:
      console.log('Staff reducer ', action);
      return initialState;
    case STAFF_UPDATE_ERROR:
      console.log('Staff reducer - haserror', action);

      return {
        ...state,
        hasError: action.hasError,
        errorMsg: action.errorMsg,
        updateSuccess: false
      };
    case STAFF_LOADING:
      console.log('Staff reducer - isloading', action);

      return { ...state, isLoading: action.isLoading, };
    case STAFF_SUCCESS_CLEAR: {
      console.log('Staff reducer - sucess clear', action);

      return {
        ...state,
        updateSuccess: false,
        updateSuccessMessage: null
      };
    }
    default:
      return state;
  }
};

export default staffReducer;
