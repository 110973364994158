/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAuthError,
  ApiValidationErrors,
  SearchRequest,
  UiTemplateDto,
  UiTemplateSearchResponse,
} from '../models/index';
import {
    ApiAuthErrorFromJSON,
    ApiAuthErrorToJSON,
    ApiValidationErrorsFromJSON,
    ApiValidationErrorsToJSON,
    SearchRequestFromJSON,
    SearchRequestToJSON,
    UiTemplateDtoFromJSON,
    UiTemplateDtoToJSON,
    UiTemplateSearchResponseFromJSON,
    UiTemplateSearchResponseToJSON,
} from '../models/index';

export interface DeletePromsQuestionnaireRequest {
    fileName: string;
    templateId: string;
    promsId: string;
}

export interface DownloadPromsQuestionnaireRequest {
    fileName: string;
    templateId: string;
    promsId: string;
}

export interface GetPromUiTemplateListRequest {
    searchRequest: SearchRequest;
}

export interface GetUiTemplateJsonRequest {
    ids: Array<string>;
}

export interface ImportUiTemplateRequest {
    uiTemplateDto: UiTemplateDto;
}

export interface UploadPromsQuestionnaireRequest {
    templateId: string;
    promsId: string;
    file: Blob;
}

/**
 * 
 */
export class PromsUiTemplateEndpointApi extends runtime.BaseAPI {

    /**
     */
    async deletePromsQuestionnaireRaw(requestParameters: DeletePromsQuestionnaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['fileName'] == null) {
            throw new runtime.RequiredError(
                'fileName',
                'Required parameter "fileName" was null or undefined when calling deletePromsQuestionnaire().'
            );
        }

        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling deletePromsQuestionnaire().'
            );
        }

        if (requestParameters['promsId'] == null) {
            throw new runtime.RequiredError(
                'promsId',
                'Required parameter "promsId" was null or undefined when calling deletePromsQuestionnaire().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['fileName'] != null) {
            queryParameters['fileName'] = requestParameters['fileName'];
        }

        if (requestParameters['templateId'] != null) {
            queryParameters['templateId'] = requestParameters['templateId'];
        }

        if (requestParameters['promsId'] != null) {
            queryParameters['promsId'] = requestParameters['promsId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/uitemplate/upload`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePromsQuestionnaire(requestParameters: DeletePromsQuestionnaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePromsQuestionnaireRaw(requestParameters, initOverrides);
    }

    /**
     */
    async downloadPromsQuestionnaireRaw(requestParameters: DownloadPromsQuestionnaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['fileName'] == null) {
            throw new runtime.RequiredError(
                'fileName',
                'Required parameter "fileName" was null or undefined when calling downloadPromsQuestionnaire().'
            );
        }

        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling downloadPromsQuestionnaire().'
            );
        }

        if (requestParameters['promsId'] == null) {
            throw new runtime.RequiredError(
                'promsId',
                'Required parameter "promsId" was null or undefined when calling downloadPromsQuestionnaire().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['fileName'] != null) {
            queryParameters['fileName'] = requestParameters['fileName'];
        }

        if (requestParameters['templateId'] != null) {
            queryParameters['templateId'] = requestParameters['templateId'];
        }

        if (requestParameters['promsId'] != null) {
            queryParameters['promsId'] = requestParameters['promsId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/uitemplate/download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async downloadPromsQuestionnaire(requestParameters: DownloadPromsQuestionnaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadPromsQuestionnaireRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllPromUiTemplateJsonRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UiTemplateDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/uitemplate/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UiTemplateDtoFromJSON));
    }

    /**
     */
    async getAllPromUiTemplateJson(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UiTemplateDto>> {
        const response = await this.getAllPromUiTemplateJsonRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPromUiTemplateListRaw(requestParameters: GetPromUiTemplateListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UiTemplateSearchResponse>> {
        if (requestParameters['searchRequest'] == null) {
            throw new runtime.RequiredError(
                'searchRequest',
                'Required parameter "searchRequest" was null or undefined when calling getPromUiTemplateList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/uitemplate/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters['searchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UiTemplateSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async getPromUiTemplateList(requestParameters: GetPromUiTemplateListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UiTemplateSearchResponse> {
        const response = await this.getPromUiTemplateListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUiTemplateJsonRaw(requestParameters: GetUiTemplateJsonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UiTemplateDto>>> {
        if (requestParameters['ids'] == null) {
            throw new runtime.RequiredError(
                'ids',
                'Required parameter "ids" was null or undefined when calling getUiTemplateJson().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ids'] != null) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/uitemplate/fetch`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UiTemplateDtoFromJSON));
    }

    /**
     */
    async getUiTemplateJson(requestParameters: GetUiTemplateJsonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UiTemplateDto>> {
        const response = await this.getUiTemplateJsonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async importUiTemplateRaw(requestParameters: ImportUiTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uiTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'uiTemplateDto',
                'Required parameter "uiTemplateDto" was null or undefined when calling importUiTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/uitemplate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UiTemplateDtoToJSON(requestParameters['uiTemplateDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async importUiTemplate(requestParameters: ImportUiTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.importUiTemplateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async uploadPromsQuestionnaireRaw(requestParameters: UploadPromsQuestionnaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling uploadPromsQuestionnaire().'
            );
        }

        if (requestParameters['promsId'] == null) {
            throw new runtime.RequiredError(
                'promsId',
                'Required parameter "promsId" was null or undefined when calling uploadPromsQuestionnaire().'
            );
        }

        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling uploadPromsQuestionnaire().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['templateId'] != null) {
            queryParameters['templateId'] = requestParameters['templateId'];
        }

        if (requestParameters['promsId'] != null) {
            queryParameters['promsId'] = requestParameters['promsId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/api/config/uitemplate/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async uploadPromsQuestionnaire(requestParameters: UploadPromsQuestionnaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uploadPromsQuestionnaireRaw(requestParameters, initOverrides);
    }

}
