/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HcpDto
 */
export interface HcpDto {
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    nhsId: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    firstNames: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    telNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof HcpDto
     */
    role: string;
    /**
     * 
     * @type {boolean}
     * @memberof HcpDto
     */
    disabled?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof HcpDto
     */
    permissions?: Array<string>;
}

/**
 * Check if a given object implements the HcpDto interface.
 */
export function instanceOfHcpDto(value: object): value is HcpDto {
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('emailAddress' in value) || value['emailAddress'] === undefined) return false;
    if (!('nhsId' in value) || value['nhsId'] === undefined) return false;
    if (!('firstNames' in value) || value['firstNames'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    return true;
}

export function HcpDtoFromJSON(json: any): HcpDto {
    return HcpDtoFromJSONTyped(json, false);
}

export function HcpDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HcpDto {
    if (json == null) {
        return json;
    }
    return {
        
        'username': json['username'],
        'emailAddress': json['emailAddress'],
        'nhsId': json['nhsId'],
        'title': json['title'] == null ? undefined : json['title'],
        'firstNames': json['firstNames'],
        'lastName': json['lastName'],
        'jobTitle': json['jobTitle'] == null ? undefined : json['jobTitle'],
        'location': json['location'] == null ? undefined : json['location'],
        'telNumber': json['telNumber'] == null ? undefined : json['telNumber'],
        'role': json['role'],
        'disabled': json['disabled'] == null ? undefined : json['disabled'],
        'permissions': json['permissions'] == null ? undefined : json['permissions'],
    };
}

export function HcpDtoToJSON(value?: HcpDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'username': value['username'],
        'emailAddress': value['emailAddress'],
        'nhsId': value['nhsId'],
        'title': value['title'],
        'firstNames': value['firstNames'],
        'lastName': value['lastName'],
        'jobTitle': value['jobTitle'],
        'location': value['location'],
        'telNumber': value['telNumber'],
        'role': value['role'],
        'disabled': value['disabled'],
        'permissions': value['permissions'],
    };
}

