import {
  TASKS_FETCH_LIST,
  TASKS_MARK_AS_COMPLETE,
  TASKS_GET_TYPE_COUNT_LIST,
  TASKS_IS_LOADING,
  TASKS_ERROR, LOGOUT
} from '../actions/Actions';

const initialState = {
  tasks: [],
  total: 0,
  currentTotal: 0,
  searchTerm: '',
  orderTerm: '',
  orderDirection: 'asc',
  fetchTime: null,

  taskCountList: [],

  hasError: false,
  isLoading: false,
  errorMsg: null,

};

const tasksReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case TASKS_FETCH_LIST: {
      console.log('Fetch tasks', action);

      const { tasks } = action;

      if (action.pageStart > 0) {
        const updatedTaskArray = [...state.tasks, ...tasks];

        return {
          ...state,
          tasks: updatedTaskArray,
          currentTotal: updatedTaskArray.length,
          isLoading: false
        };
      }
      return {
        ...state,
        tasks,
        currentTotal: tasks.length,
        total: action.total,
        searchTerm: action.searchTerm,
        orderTerm: action.orderTerm,
        orderDirection: action.orderDirection,
        isLoading: false
      };
    }
    case TASKS_MARK_AS_COMPLETE: {
      console.log('Mark task as complete');

      const { taskId } = action;

      console.log(taskId);

      const updatedArray = state.tasks.filter((task) => task.id !== taskId);
      return {
        ...state,
        tasks: updatedArray,
        isLoading: false
      };
    }
    case TASKS_GET_TYPE_COUNT_LIST: {
      console.log('Get task count list');

      const { taskCountList } = action;

      return {
        ...state,
        taskCountList,
        isLoading: false
      };
    }
    case LOGOUT:
      return initialState;
    case TASKS_IS_LOADING:
      console.log('isloading', action);

      return { ...state, isLoading: action.isLoading, };
    case TASKS_ERROR:
      console.log('haserror', action);

      return {
        ...state,
        hasError: action.hasError,
        errorMsg: action.errorMsg
      };
    default:
      return state;
  }
};

export default tasksReducer;
