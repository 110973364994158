/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Procedure } from './Procedure';
import {
    ProcedureFromJSON,
    ProcedureFromJSONTyped,
    ProcedureToJSON,
} from './Procedure';

/**
 * Request for an update to a pathway procedure
 * @export
 * @interface PathwayProcedureDto
 */
export interface PathwayProcedureDto {
    /**
     * 
     * @type {Procedure}
     * @memberof PathwayProcedureDto
     */
    procedure?: Procedure;
    /**
     * List of ids of subprocedure to be re-scheduled with the procedure
     * @type {Array<string>}
     * @memberof PathwayProcedureDto
     */
    subProcedureRescheduleList?: Array<string>;
    /**
     * List of ids of prom events to be re-scheduled with a sub procedure
     * @type {Array<string>}
     * @memberof PathwayProcedureDto
     */
    promRescheduleList?: Array<string>;
    /**
     * The user for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof PathwayProcedureDto
     */
    userId?: string;
    /**
     * The namespace for the userId for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof PathwayProcedureDto
     */
    userIdNamespace?: string;
    /**
     * The uuid of the pathway composition that is being updated
     * @type {string}
     * @memberof PathwayProcedureDto
     */
    pathwayUUID?: string;
    /**
     * The id of the pathway that is being updated
     * @type {string}
     * @memberof PathwayProcedureDto
     */
    pathwayId?: string;
    /**
     * The name of the pathway that is being updated
     * @type {string}
     * @memberof PathwayProcedureDto
     */
    pathwayName?: string;
}

/**
 * Check if a given object implements the PathwayProcedureDto interface.
 */
export function instanceOfPathwayProcedureDto(value: object): value is PathwayProcedureDto {
    return true;
}

export function PathwayProcedureDtoFromJSON(json: any): PathwayProcedureDto {
    return PathwayProcedureDtoFromJSONTyped(json, false);
}

export function PathwayProcedureDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PathwayProcedureDto {
    if (json == null) {
        return json;
    }
    return {
        
        'procedure': json['procedure'] == null ? undefined : ProcedureFromJSON(json['procedure']),
        'subProcedureRescheduleList': json['subProcedureRescheduleList'] == null ? undefined : json['subProcedureRescheduleList'],
        'promRescheduleList': json['promRescheduleList'] == null ? undefined : json['promRescheduleList'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'userIdNamespace': json['userIdNamespace'] == null ? undefined : json['userIdNamespace'],
        'pathwayUUID': json['pathwayUUID'] == null ? undefined : json['pathwayUUID'],
        'pathwayId': json['pathwayId'] == null ? undefined : json['pathwayId'],
        'pathwayName': json['pathwayName'] == null ? undefined : json['pathwayName'],
    };
}

export function PathwayProcedureDtoToJSON(value?: PathwayProcedureDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'procedure': ProcedureToJSON(value['procedure']),
        'subProcedureRescheduleList': value['subProcedureRescheduleList'],
        'promRescheduleList': value['promRescheduleList'],
        'userId': value['userId'],
        'userIdNamespace': value['userIdNamespace'],
        'pathwayUUID': value['pathwayUUID'],
        'pathwayId': value['pathwayId'],
        'pathwayName': value['pathwayName'],
    };
}

