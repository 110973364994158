import { TasksEndpointApi } from 'promsApi';
import {
  TASKS_FETCH_LIST,
  TASKS_MARK_AS_COMPLETE,
  TASKS_GET_TYPE_COUNT_LIST,
  TASKS_IS_LOADING,
  TASKS_ERROR
} from './Actions';

import getConfiguation from './Configuration';
import onRequest from './Request';

const moment = require('moment');

const tasksError = (bool, errorMsg) => {
  return {
    type: TASKS_ERROR,
    hasError: bool,
    errorMsg
  };
};

const tasksLoading = (bool) => {
  return {
    type: TASKS_IS_LOADING,
    isLoading: bool
  };
};

const clearTasksError = () => {
  return {
    type: TASKS_ERROR,
    hasError: false,
    errorMsg: null
  };
};

const fetchTasksSuccessHandler = (payload, searchTerm) => {
  return {
    type: TASKS_FETCH_LIST,
    tasks: payload == null ? {} : payload.tasks,
    total: payload.total,
    pageStart: payload.start,
    searchTerm,
    orderTerm: payload.orderTerm,
    orderDirection: payload.orderDirection
  };
};

const fetchTasks = (searchTerm, searchTermMapping, pageSize, start, orderTerm, orderDirection) => {
  console.log('Fetching task list');

  return (dispatch, getState) => {
    dispatch(tasksLoading(true));

    const config = getConfiguation(dispatch, getState);
    const tasksApi = new TasksEndpointApi(config);

    const tasksSearchRequest = {
      pageSize,
      start,
      searchTerms: searchTermMapping || [],
      orderTerm,
      orderDirection
    };

    const request = tasksApi.findTasks({ searchRequest: tasksSearchRequest });
    onRequest(request, dispatch, fetchTasksSuccessHandler, tasksError, tasksLoading, searchTerm);
  };
};

const markTaskCompleteSuccessHandler = (payload, taskId) => {
  return {
    type: TASKS_MARK_AS_COMPLETE,
    taskId,
  };
};

const markTaskAsComplete = (taskId) => {
  console.log('Mark task as complete');

  const completeDate = moment(new Date()).format('YYYY-MM-DD');

  const taskComplete = {
    id: taskId,
    complete: completeDate
  };

  console.log(taskComplete);

  return (dispatch, getState) => {
    dispatch(tasksLoading(true));

    const config = getConfiguation(dispatch, getState);
    const tasksApi = new TasksEndpointApi(config);
    const request = tasksApi.markTaskComplete({ taskCompleteDto: taskComplete });
    onRequest(request, dispatch, markTaskCompleteSuccessHandler, tasksError, tasksLoading, taskId);
  };
};

const fetchTaskCountListSuccessHandler = (payload) => {
  return {
    type: TASKS_GET_TYPE_COUNT_LIST,
    taskCountList: payload
  };
};

const fetchTaskCountList = () => {
  console.log('Fetch task count');

  return (dispatch, getState) => {
    dispatch(tasksLoading(true));

    const config = getConfiguation(dispatch, getState);
    const tasksApi = new TasksEndpointApi(config);
    const request = tasksApi.getTaskTypeCountList();
    onRequest(request, dispatch, fetchTaskCountListSuccessHandler, tasksError, tasksLoading);
  };
};

export default {
  clearTasksError,
  fetchTasks,
  markTaskAsComplete,
  fetchTaskCountList
};
