import { HealthRecordEndpointApi, PathwayEndpointApi } from 'promsApi';
import PromQueryUtils from 'src/Common/PromQueryUtils';
import {
  ADD_PATIENT_DATA_COMPOSITION,
  FETCH_PATIENT_DATA_COMPOSITION,
  FETCH_PATIENT_PATHWAY_RESULTS,
  FETCH_PATIENT_PATHWAYS,
  FETCH_PATIENT_PATHWAY_BY_UUID,
  PATIENT_DATA_ERROR,
  PATIENT_DATA_UPDATE_ERROR,
  PATIENT_DATA_CLEAR_ERROR,
  PATIENT_DATA_IS_LOADING,
  PATIENT_DATA_SUCCESS_CLEAR,
  PATIENT_DATA_DELETE_SUCCESS_CLEAR,
  ADD_PATIENT_PATHWAY,
  UPDATE_PATIENT_PATHWAY,
  DELETE_PATIENT_PATHWAY
} from './Actions';

import getConfiguation from './Configuration';
import onRequest from './Request';

const hasError = (bool, errorMsg) => {
  return {
    type: PATIENT_DATA_ERROR,
    hasError: bool,
    errorMsg
  };
};

const hasUpdateError = (bool, errorMsg) => {
  return {
    type: PATIENT_DATA_UPDATE_ERROR,
    hasError: bool,
    errorMsg
  };
};

const clearError = () => {
  return {
    type: PATIENT_DATA_CLEAR_ERROR,
  };
};

const isLoading = (bool) => {
  return {
    type: PATIENT_DATA_IS_LOADING,
    isLoading: bool
  };
};

const clearUpdateSuccess = () => {
  return {
    type: PATIENT_DATA_SUCCESS_CLEAR
  };
};

const clearDeleteSuccess = () => {
  return {
    type: PATIENT_DATA_DELETE_SUCCESS_CLEAR
  };
};

const addCompositionSucessHandler = () => {
  return {
    type: ADD_PATIENT_DATA_COMPOSITION
  };
};

const addComposition = (userId, userIdNamespace, composition, compositionUid) => {
  const successData = {
    timeStamp: new Date().getTime()
  };

  if (userId != null) {
    composition.userId = userId;
    composition.userIdNamespace = userIdNamespace;
  }

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const userApi = new HealthRecordEndpointApi(config);
    let request;
    if (compositionUid !== undefined && compositionUid !== null) {
      request = userApi.createComposition({ compositionDto: composition, compositionUid });
    } else {
      console.log('~~~~~~~~~~~~~~~~~~~~~~');
      console.log(composition);
      request = userApi.createComposition2({ compositionDto: composition });
    }

    onRequest(request, dispatch, addCompositionSucessHandler, hasUpdateError, isLoading, successData, 'Error updating questionaire data');
  };
};

const fetchPathwayResultsSuccessHandler = (patientData, successData) => {
  console.log(patientData);
  return {
    type: FETCH_PATIENT_PATHWAY_RESULTS,
    promResults: patientData.promResults,
    procedureCompositions: patientData.procedureCompositions,
    pathwayId: successData.pathwayId,
    userId: successData.userId,
    userIdNamespace: successData.userIdNamespace
  };
};

const fetchPathwayResults = (userId, userIdNamespace, pathway, procedureCompositionUUIDs) => {
  console.log(pathway);

  const promsList = PromQueryUtils.generatePromList(pathway);

  const pathwayQueryRequest = {
    userId,
    userIdNamespace,
    pathwayId: pathway.pathwayId,
    promQueryList: promsList,
    procedureCompositionUUIDs: procedureCompositionUUIDs || []
  };

  const successData = {
    pathwayId: pathway.uuid,
    userId,
    userIdNamespace
  };

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const userApi = new HealthRecordEndpointApi(config);
    const request = userApi.runPathwayQuery({ pathwayQueryDto: pathwayQueryRequest });
    onRequest(request, dispatch, fetchPathwayResultsSuccessHandler, hasError, isLoading, successData, 'Error fetching patient data');
  };
};

const fetchPatientPathwaysSuccess = (response, ids) => {
  return {
    type: FETCH_PATIENT_PATHWAYS,
    payload: response,
    patientPathwayFetchTime: new Date().getTime(),
    userId: ids.userId,
    userIdNamespace: ids.userIdNamespace
  };
};

const fetchPatientPathways = (userId, userIdNamespace) => {
  return (dispatch, getState) => {
    // Check time since pathways last fetched.
    const lastFetchTime = getState().patientData.patientPathwayFetchTime;
    const timeSinceFetch = new Date().getTime() - lastFetchTime;

    const lastUserIdFetch = getState().patientData.userId;
    const lastUserIdNamespaceFetch = getState().patientData.userIdNamespace;

    if (timeSinceFetch > window._env_.REACT_APP_PATIENT_PATHWAY_CACHE_TIME
       || userId !== lastUserIdFetch || userIdNamespace !== lastUserIdNamespaceFetch) {
      dispatch(isLoading(true));
      const config = getConfiguation(dispatch, getState);
      const pathwayApi = new PathwayEndpointApi(config);
      const request = pathwayApi.getAllPathwayCompositions({ userId, userIdNamespace });
      onRequest(request, dispatch, fetchPatientPathwaysSuccess, hasError, isLoading, { userId, userIdNamespace }, 'Error fetching patient data');
    }
  };
};

const fetchPatientPathwayByUUIDSuccess = (response, pathwayUUID) => {
  const { pathway } = response;
  pathway.uuid = pathwayUUID;
  return {
    type: FETCH_PATIENT_PATHWAY_BY_UUID,
    pathway: response.pathway,
    patientPathwayFetchTime: new Date().getTime()
  };
};

const fetchPatientPathwayByUUID = (userId, userIdNamespace, pathwayUUID) => {
  return (dispatch, getState) => {
    const requestParameters = {
      userId,
      userNamespace: userIdNamespace,
      compositionUid: pathwayUUID
    };

    dispatch(isLoading(true));
    const config = getConfiguation(dispatch, getState);
    const pathwayApi = new PathwayEndpointApi(config);
    const request = pathwayApi.getComposition(requestParameters);
    onRequest(request, dispatch, fetchPatientPathwayByUUIDSuccess, hasError, isLoading, pathwayUUID, 'Error fetching patient pathway');
  };
};

const onAddPathwaySuccess = (response, data) => {
  return {
    type: ADD_PATIENT_PATHWAY,
    userId: data.userId,
    userIdNamespace: data.userIdNamespace,
    pathway: response.pathway
  };
};

const addPatientPathway = (userId, userIdNamespace, pathway, procedureList) => {
  pathway.lastModified = new Date();

  // Extract procedures
  const procedures = pathway.steps.filter((step) => step.type === 'procedure')
    .map((p) => {
      const procedure = procedureList.find((pl) => pl.id === p.procedureId);
      const procedureCopy = JSON.parse(JSON.stringify(procedure)); // Deep copy
      procedureCopy.id = p.id;
      procedureCopy.procedureId = p.procedureId;
      procedureCopy.title = p.title;
      procedureCopy.scheduled = p.scheduled;
      procedureCopy.additionalInfo = p.additionalInfo;
      if (p.procedureSide !== 'na') {
        procedureCopy.procedureSide = p.procedureSide;
      }

      return procedureCopy;
    });
  pathway.primaryProcedure = procedures.find((p) => p.id === 0);
  pathway.secondaryProcedures = procedures.filter((p) => p.id !== 0);

  const data = {
    pathway,
    userId,
    userIdNamespace
  };

  console.log(data);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const pathwayApi = new PathwayEndpointApi(config);
    const request = pathwayApi.createComposition1({ pathwayCompositionDto: data });
    onRequest(request, dispatch, onAddPathwaySuccess, hasError, isLoading, { userId, userIdNamespace }, 'Error adding pathway');
  };
};

const onUpdatePathwaySuccess = (response, data) => {
  console.log('PATHWAY UPDATE');
  console.log(response);

  return {
    type: UPDATE_PATIENT_PATHWAY,
    patientId: data.patientId,
    pathway: response.pathway
  };
};

const updatePatientPathwayStep = (userId, userIdNamespace, pathway, pathwayStep) => {
  const data = {
    step: pathwayStep,
    userId,
    userIdNamespace,
    pathwayUUID: pathway.uuid,
    pathwayId: pathway.pathwayId,
    pathwayName: pathway.pathway
  };

  console.log('******************************');
  console.log(pathway);
  console.log(data);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const pathwayApi = new PathwayEndpointApi(config);
    const request = pathwayApi.updatePathwaySteps({ pathwayStepDto: data });
    onRequest(request, dispatch, onUpdatePathwaySuccess, hasError, isLoading, { userId, userIdNamespace }, 'Error updating pathway');
  };
};

const deletePatientPathwayStep = (userId, userIdNamespace, pathway, pathwayStep) => {
  const data = {
    step: pathwayStep,
    userId,
    userIdNamespace,
    pathwayUUID: pathway.uuid,
    pathwayId: pathway.pathwayId,
    pathwayName: pathway.pathway
  };

  console.log('******************************');
  console.log(pathway);
  console.log(data);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const pathwayApi = new PathwayEndpointApi(config);
    const request = pathwayApi.removePathwayStep({ pathwayStepDto: data });
    onRequest(request, dispatch, onUpdatePathwaySuccess, hasError, isLoading, { userId, userIdNamespace }, 'Error updating pathway');
  };
};

const deletePatientPathwayProcedure = (userId, userIdNamespace, pathway, procedure) => {
  const data = {
    procedure,
    userId,
    userIdNamespace,
    pathwayUUID: pathway.uuid,
    pathwayId: pathway.pathwayId,
    pathwayName: pathway.pathway
  };

  console.log('******************************');
  console.log(pathway);
  console.log(data);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const pathwayApi = new PathwayEndpointApi(config);
    const request = pathwayApi.removePathwayProcedure({ pathwayProcedureDto: data });
    onRequest(request, dispatch, onUpdatePathwaySuccess, hasError, isLoading, { userId, userIdNamespace }, 'Error updating pathway');
  };
};

const onDeletePathwaySuccess = (response, data) => {
  console.log('PATHWAY DELETE');
  console.log(data);

  return {
    type: DELETE_PATIENT_PATHWAY,
    data
  };
};

const deletePatientPathway = (userId, userIdNamespace, pathway) => {
  const data = {
    userId,
    userIdNamespace,
    pathway
  };

  console.log('******************************');
  console.log(data);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const pathwayApi = new PathwayEndpointApi(config);
    const request = pathwayApi.removeCompositionRaw({ pathwayCompositionDto: data });
    onRequest(request, dispatch, onDeletePathwaySuccess, hasError, isLoading, { userId, userIdNamespace, pathway }, 'Error updating pathway');
  };
};

const updatePatientProcedure = (
  userId,
  userIdNamespace,
  pathway,
  procedure,
  subProcedureRescheduleList,
  promRescheduleList
) => {
  const data = {
    procedure,
    userId,
    userIdNamespace,
    pathwayUUID: pathway.uuid,
    pathwayId: pathway.pathwayId,
    pathwayName: pathway.pathway,
    subProcedureRescheduleList,
    promRescheduleList
  };

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const pathwayApi = new PathwayEndpointApi(config);
    const request = pathwayApi.updatePathwayProcedure({ pathwayProcedureDto: data });
    onRequest(request, dispatch, onUpdatePathwaySuccess, hasError, isLoading, { userId, userIdNamespace }, 'Error updating pathway');
  };
};

const fetchCompositionSuccessHandler = (composition, successData) => {
  return {
    type: FETCH_PATIENT_DATA_COMPOSITION,
    payload: composition,
    compositionUUID: successData.compositionUUID,
    pathwayId: successData.pathwayId,
    userId: successData.userId,
    userIdNamespace: successData.userIdNamespace,
  };
};

const fetchComposition = (compositionUUID, pathwayId, userId, userIdNamespace) => {
  const successData = {
    pathwayId,
    userId,
    userIdNamespace,
    compositionUUID
  };

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const userApi = new HealthRecordEndpointApi(config);

    const requestParameters = {
      compositionUid: compositionUUID,
      userId,
      userIdNamespace
    };

    const request = userApi.getComposition1(requestParameters);
    onRequest(request, dispatch, fetchCompositionSuccessHandler, hasError, isLoading, successData, 'Error fetching patient procedure data');
  };
};

export default {
  addComposition,
  fetchComposition,
  clearUpdateSuccess,
  hasError,
  clearError,
  fetchPatientPathways,
  fetchPatientPathwayByUUID,
  fetchPathwayResults,
  addPatientPathway,
  updatePatientProcedure,
  updatePatientPathwayStep,
  deletePatientPathwayStep,
  deletePatientPathwayProcedure,
  deletePatientPathway,
  clearDeleteSuccess
};
