import { HcpApi } from 'promsApi';
import {
  FETCH_STAFF_LIST,
  ADD_STAFF_PROFILE,
  UPDATE_STAFF_PROFILE,
  DELETE_STAFF_PROFILE,
  STAFF_UPDATE_ERROR,
  STAFF_LOADING,
  STAFF_SUCCESS_CLEAR,
  DISABLE_STAFF_PROFILE
} from './Actions';

import getConfiguation from './Configuration';
import onRequest from './Request';

const staffError = (bool, errorMsg) => {
  return {
    type: STAFF_UPDATE_ERROR,
    hasError: bool,
    errorMsg
  };
};

const staffLoading = (bool) => {
  return {
    type: STAFF_LOADING,
    isLoading: bool
  };
};

const clearStaffSuccess = () => {
  return {
    type: STAFF_SUCCESS_CLEAR
  };
};

const fetchStaffListSuccessHandler = (payload, searchTerm) => {
  console.log('++++++++++++');
  console.log(payload);
  return {
    type: FETCH_STAFF_LIST,
    staffList: payload == null ? {} : payload.profiles,
    total: payload.total,
    pageStart: payload.start,
    searchTerm,
    orderTerm: payload.orderTerm,
    orderDirection: payload.orderDirection,
    fetchTime: new Date().getTime()
  };
};

const fetchStaffList = (searchTerm, pageSize, start, orderTerm, orderDirection) => {
  console.log('Fetching staff list');
  console.log(searchTerm);

  return (dispatch, getState) => {
    dispatch(staffLoading(true));

    const config = getConfiguation(dispatch, getState);

    // Split search term for name or nhsId
    let searchTermMapping = {};
    const containsNumber = /\d/.test(searchTerm);
    if (containsNumber) {
      searchTermMapping = { nhsId: searchTerm };
    } else if (searchTerm !== '') {
      searchTermMapping = { lastName: searchTerm };
    }

    const staffSearchRequest = {
      searchTerms: searchTermMapping,
      pageSize,
      start,
      orderTerm,
      orderDirection
    };

    const hcpApi = new HcpApi(config);
    const request = hcpApi.findStaff({ searchRequest: staffSearchRequest });

    onRequest(request, dispatch, fetchStaffListSuccessHandler, staffError, staffLoading, searchTerm, 'Error fetching users');
  };
};

const fetchClinicalStaffList = (searchTerm, pageSize, start) => {
  console.log('Fetching clinical staff list');

  return (dispatch, getState) => {
    dispatch(staffLoading(true));

    const config = getConfiguation(dispatch, getState);

    const staffSearchRequest = {
      searchTerms: {
        permission: 'PERFORM_CLINICAL_PROCEDURES',
        lastName: searchTerm
      },
      pageSize,
      start,
      orderTerm: 'lastName',
      orderDirection: 'asc'
    };

    const hcpApi = new HcpApi(config);
    const request = hcpApi.findStaff({ searchRequest: staffSearchRequest });
    onRequest(request, dispatch, fetchStaffListSuccessHandler, staffError, staffLoading, null, 'Error fetching staff list');
  };
};

const fetchAuditStaffList = (searchTerm, pageSize, start) => {
  console.log('Fetching clinical staff list');

  return (dispatch, getState) => {
    dispatch(staffLoading(true));

    const config = getConfiguation(dispatch, getState);

    const staffSearchRequest = {
      pageSize,
      start,
      orderTerm: 'lastName',
      orderDirection: 'asc'
    };

    if (searchTerm !== '') {
      staffSearchRequest.searchTerms = {
        lastName: searchTerm
      };
    }

    const hcpApi = new HcpApi(config);
    const request = hcpApi.findStaff({ searchRequest: staffSearchRequest });
    onRequest(request, dispatch, fetchStaffListSuccessHandler, staffError, staffLoading, null, 'Error fetching staff list');
  };
};

const createStaffProfileSuccessHandler = (response) => {
  return {
    type: ADD_STAFF_PROFILE,
    staffProfile: response
  };
};

const createStaffProfile = (profile) => {
  console.log('Create Staff Member');

  profile.username = profile.emailAddress; // TODO Check if email address should be used as username

  return (dispatch, getState) => {
    dispatch(staffLoading(true));

    const config = getConfiguation(dispatch, getState);
    const hcpApi = new HcpApi(config);
    const request = hcpApi.create({ hcpDto: profile });
    onRequest(request, dispatch, createStaffProfileSuccessHandler, staffError, staffLoading, null, 'Error creating user');
  };
};

const updateStaffProfileSuccessHandler = (response, profile) => {
  return {
    type: UPDATE_STAFF_PROFILE,
    staffProfile: profile
  };
};

const updateStaffProfile = (profile) => {
  console.log('Update Staff Member');

  console.log(profile);

  return (dispatch, getState) => {
    dispatch(staffLoading(true));

    const config = getConfiguation(dispatch, getState);
    const hcpApi = new HcpApi(config);
    const request = hcpApi.updateProfile({ hcpDto: profile });
    onRequest(request, dispatch, updateStaffProfileSuccessHandler, staffError, staffLoading, profile, 'Error updating user');
  };
};

const deleteStaffProfileSuccessHandler = (response, profile) => {
  return {
    type: DELETE_STAFF_PROFILE,
    staffProfile: profile
  };
};

const deleteStaffProfile = (profile) => {
  console.log('Delete Staff Member');

  console.log(profile);

  return (dispatch, getState) => {
    dispatch(staffLoading(true));

    const config = getConfiguation(dispatch, getState);
    const hcpApi = new HcpApi(config);
    const request = hcpApi.deleteProfile({ username: profile.username });
    onRequest(request, dispatch, deleteStaffProfileSuccessHandler, staffError, staffLoading, profile, 'Error deleting user');
  };
};

const disableStaffProfileSuccessHandler = (response, profile) => {
  return {
    type: DISABLE_STAFF_PROFILE,
    staffProfile: profile
  };
};

const disableStaffProfile = (profile, disable) => {
  const successProfile = { ...profile };
  successProfile.disabled = disable;

  return (dispatch, getState) => {
    dispatch(staffLoading(true));

    const config = getConfiguation(dispatch, getState);
    const hcpApi = new HcpApi(config);
    const request = disable
      ? hcpApi.disableProfile({ hcpDto: profile }) : hcpApi.enableProfile({ hcpDto: profile });
    onRequest(request, dispatch, disableStaffProfileSuccessHandler, staffError, staffLoading, successProfile, 'Error updating user');
  };
};

export default {
  fetchStaffList,
  updateStaffProfile,
  createStaffProfile,
  deleteStaffProfile,
  clearStaffSuccess,
  disableStaffProfile,
  fetchClinicalStaffList,
  fetchAuditStaffList
};
