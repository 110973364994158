import { Configuration } from 'promsApi';

const timeoutFetch = (input, init) => {
  const timeoutPromise = new Promise((resolve) => {
    setTimeout(resolve, window._env_.REACT_APP_FETCH_TIMEOUT, {
      status: 'error',
      code: 666,
      data: 'TIMEOUT',
    });
  });

  return Promise.race([
    timeoutPromise,
    fetch(input, init)
  ]);
};

const getConfiguation = (dispatch, getState) => {
    
    const { serverConfig } = getState();

    var config = new Configuration(
        {
            accessToken: function() {
                // return a string or Promise<String>

                var loginState = getState().login;
                var keycloak = loginState.keycloak;
                if (keycloak.isTokenExpired(20) === true) {
                    return new Promise(function(resolve, reject){
                        keycloak.updateToken(20).then(function(refreshed) {
                            if (refreshed) {
                                 console.info("Auth token refreshed");
                               } // else still valid
                               resolve(`Bearer ${keycloak.token}`);
                             }).catch(function() {
                               // Failed to refresh token
                               console.error("Auth token refresh failed");
                               reject(`Bearer ${keycloak.token}`);
                             });
                    });

                } else {
                    return `Bearer ${keycloak.token}`;
                }
            },

            basePath: serverConfig.serverUrl,
            fetchApi: timeoutFetch
        }
    );
    return config;
}

export default getConfiguation;
