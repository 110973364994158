/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PathwayTemplateItemDto } from './PathwayTemplateItemDto';
import {
    PathwayTemplateItemDtoFromJSON,
    PathwayTemplateItemDtoFromJSONTyped,
    PathwayTemplateItemDtoToJSON,
} from './PathwayTemplateItemDto';

/**
 * Returns a pathway template search
 * @export
 * @interface PathwayTemplateSearchResponse
 */
export interface PathwayTemplateSearchResponse {
    /**
     * Search terms which produced results
     * @type {{ [key: string]: string; }}
     * @memberof PathwayTemplateSearchResponse
     */
    searchTerms?: { [key: string]: string; };
    /**
     * Paging page size
     * @type {number}
     * @memberof PathwayTemplateSearchResponse
     */
    pageSize?: number;
    /**
     * Paging start record
     * @type {number}
     * @memberof PathwayTemplateSearchResponse
     */
    start?: number;
    /**
     * Total number of patient profiles
     * @type {number}
     * @memberof PathwayTemplateSearchResponse
     */
    total?: number;
    /**
     * Ordered by
     * @type {string}
     * @memberof PathwayTemplateSearchResponse
     */
    orderTerm?: string;
    /**
     * Order direction
     * @type {string}
     * @memberof PathwayTemplateSearchResponse
     */
    orderDirection?: string;
    /**
     * List of templates items
     * @type {Array<PathwayTemplateItemDto>}
     * @memberof PathwayTemplateSearchResponse
     */
    pathwayList?: Array<PathwayTemplateItemDto>;
}

/**
 * Check if a given object implements the PathwayTemplateSearchResponse interface.
 */
export function instanceOfPathwayTemplateSearchResponse(value: object): value is PathwayTemplateSearchResponse {
    return true;
}

export function PathwayTemplateSearchResponseFromJSON(json: any): PathwayTemplateSearchResponse {
    return PathwayTemplateSearchResponseFromJSONTyped(json, false);
}

export function PathwayTemplateSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PathwayTemplateSearchResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'searchTerms': json['searchTerms'] == null ? undefined : json['searchTerms'],
        'pageSize': json['pageSize'] == null ? undefined : json['pageSize'],
        'start': json['start'] == null ? undefined : json['start'],
        'total': json['total'] == null ? undefined : json['total'],
        'orderTerm': json['orderTerm'] == null ? undefined : json['orderTerm'],
        'orderDirection': json['orderDirection'] == null ? undefined : json['orderDirection'],
        'pathwayList': json['pathwayList'] == null ? undefined : ((json['pathwayList'] as Array<any>).map(PathwayTemplateItemDtoFromJSON)),
    };
}

export function PathwayTemplateSearchResponseToJSON(value?: PathwayTemplateSearchResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'searchTerms': value['searchTerms'],
        'pageSize': value['pageSize'],
        'start': value['start'],
        'total': value['total'],
        'orderTerm': value['orderTerm'],
        'orderDirection': value['orderDirection'],
        'pathwayList': value['pathwayList'] == null ? undefined : ((value['pathwayList'] as Array<any>).map(PathwayTemplateItemDtoToJSON)),
    };
}

