import {
  LOGIN_HAS_ERROR,
  LOGIN_IS_LOADING,
  LOGIN, LOGOUT,
  USER_PERMISSIONS
} from '../actions/Actions';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMsg: null,
  timeOut: false,

  username: null,
  keycloak: null,

  adminMode: false,
  permissions: []
};

const loginReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_HAS_ERROR:
      console.log('haserror', action);

      return {
        ...state,
        hasError: action.hasError,
        errorMsg: action.errorMsg
      };
    case LOGIN_IS_LOADING:
      console.log('isloading', action);

      return { ...state, isLoading: action.isLoading, };
    case LOGIN:
      console.log('login', action);
      return {
        ...state,
        keycloak: payload.keycloak,
        username: payload.username,
        adminMode: payload.role !== 'PATIENT',
        timeOut: false
      };
    case USER_PERMISSIONS: {
      console.log('login', action);
      return { ...state, permissions: action.permissions };
    }
    case LOGOUT:
      console.log('logout', action);
      return {
        ...state,
        keycloak: null,
        permissions: [],
        timeOut: action.timeOut ? action.timeOut : false
      };
    default:
      return state;
  }
};

export default loginReducer;
