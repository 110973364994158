/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Accessible name localisation
 * @export
 * @interface AccessibleNameDto
 */
export interface AccessibleNameDto {
    /**
     * 
     * @type {string}
     * @memberof AccessibleNameDto
     */
    localisation?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessibleNameDto
     */
    languageCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessibleNameDto
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessibleNameDto
     */
    languageName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessibleNameDto
     */
    localisedName?: string;
}

/**
 * Check if a given object implements the AccessibleNameDto interface.
 */
export function instanceOfAccessibleNameDto(value: object): value is AccessibleNameDto {
    return true;
}

export function AccessibleNameDtoFromJSON(json: any): AccessibleNameDto {
    return AccessibleNameDtoFromJSONTyped(json, false);
}

export function AccessibleNameDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessibleNameDto {
    if (json == null) {
        return json;
    }
    return {
        
        'localisation': json['localisation'] == null ? undefined : json['localisation'],
        'languageCode': json['languageCode'] == null ? undefined : json['languageCode'],
        'language': json['language'] == null ? undefined : json['language'],
        'languageName': json['languageName'] == null ? undefined : json['languageName'],
        'localisedName': json['localisedName'] == null ? undefined : json['localisedName'],
    };
}

export function AccessibleNameDtoToJSON(value?: AccessibleNameDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'localisation': value['localisation'],
        'languageCode': value['languageCode'],
        'language': value['language'],
        'languageName': value['languageName'],
        'localisedName': value['localisedName'],
    };
}

