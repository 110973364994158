import {
  AUDIT_FETCH_LIST, AUDIT_IS_LOADING, AUDIT_ERROR, LOGOUT
} from '../actions/Actions';

const initialState = {
  audit: [],
  total: 0,
  currentTotal: 0,
  searchTerms: '',
  orderTerm: '',
  orderDirection: 'desc',
  fetchTime: null,

  hasError: false,
  isLoading: false,
  errorMsg: null,

};

const auditReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case AUDIT_FETCH_LIST: {
      console.log('Fetch audit', action);

      const { audit } = action;

      if (action.pageStart > 0) {
        const updatedAuditArray = [...state.audit, ...audit];

        return {
          ...state,
          audit: updatedAuditArray,
          currentTotal: updatedAuditArray.length,
          isLoading: false
        };
      }
      return {
        ...state,
        audit,
        currentTotal: audit.length,
        total: action.total,
        searchTerms: action.searchTerms,
        orderTerm: action.orderTerm,
        orderDirection: action.orderDirection,
        isLoading: false
      };
    }
    case LOGOUT:
      return initialState;
    case AUDIT_IS_LOADING:
      console.log('isloading', action);

      return { ...state, isLoading: action.isLoading, };
    case AUDIT_ERROR:
      console.log('haserror', action);

      return {
        ...state,
        hasError: action.hasError,
        errorMsg: action.errorMsg
      };
    default:
      return state;
  }
};

export default auditReducer;
