import {
  ADD_PROCEDURE_TEMPLATE,
  PROCEDURE_TEMPLATE_LOADING,
  PROCEDURE_TEMPLATE_FETCH,
  PROCEDURE_TEMPLATE_FETCH_LIST,
  PROCEDURE_TEMPLATE_ERROR,
  PROCEDURE_TEMPLATE_SUCCESS_CLEAR, ADD_PROCEDURE,
  ADD_SUBPROCEDURE, PROCEDURE_FETCH_ALL,
  PROCEDURE_FETCH,
  DELETE_PROCEDURE,
  DELETE_SUBPROCEDURE,
  LOGOUT
} from '../actions/Actions';

const initialState = {
  procedureTemplateList: { list: [], searchTerm: '' },
  procedureTemplates: [],
  procedureList: { list: [], searchTerm: '' },
  fetchAllTime: 0,

  hasError: false,
  isLoading: false,
  errorMsg: null,
  success: false,
  updateSuccess: false,
  updateSuccessMessage: null
};

function updateProcedureTemplateList(array, procedure) {
  let found = false;
  let updatedArray = array.map((item) => {
    if (item.id !== procedure.id) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    found = true;
    return {
      ...procedure
    };
  });

  if (!found) {
    updatedArray = [...array, procedure];
  }

  return updatedArray;
}

function updateProcedureList(array, procedure) {
  return array.map((item) => {
    if (item.id !== procedure.id) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...procedure
    };
  });
}

function replaceProcedureTemplates(oldArray, newArray) {
  const filteredArray = oldArray.filter((item) => {
    const foundItem = newArray.find((t) => t.id === item.id);
    return foundItem === undefined;
  });

  return filteredArray.concat(newArray);
}

const proceduresReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case PROCEDURE_FETCH_ALL: {
      console.log('fetch PROCEDUREs', action);

      let procedureList = action.procedures;

      if (action.pageStart > 0) {
        const updatedTemplateArray = [...state.procedureList.list, ...procedureList];

        procedureList = {
          ...state.procedureList,
          list: updatedTemplateArray,
          currentTotal: updatedTemplateArray.length,
        };
      } else {
        procedureList = {
          ...state.procedureList,
          list: procedureList,
          currentTotal: procedureList.length,
          total: action.total,
          searchTerm: action.searchTerm,
          orderTerm: action.orderTerm,
          orderDirection: action.orderDirection,
          fetchTime: action.fetchTime,
        };
      }

      return {
        ...state,
        procedureList,
        isLoading: false,
        success: true
      };
    }
    case PROCEDURE_FETCH: {
      const updatedTemplateArray = [...state.procedureList.list, ...action.procedures];
      const procedureList = {
        ...state.procedureList,
        list: updatedTemplateArray,
        currentTotal: updatedTemplateArray.length,
        total: action.total + action.procedures.length
      };

      return {
        ...state,
        procedureList,
        isLoading: false,
        updateSuccess: action.updateSuccess
      };
    }
    case ADD_PROCEDURE:
      const { newProcedure } = action;
      if (newProcedure) {
        const updatedTemplateArray = [...state.procedureList.list, action.procedure];
        const procedureList = {
          ...state.procedureList,
          list: updatedTemplateArray,
          currentTotal: updatedTemplateArray.length,
          total: action.total + 1
        };

        return {
          ...state,
          procedureList,
          isLoading: false,
          updateSuccess: action.updateSuccess
        };
      } else {
        return state;
      }
      break;
    case DELETE_PROCEDURE: {
      console.log('Delete procedure', action);
      console.log(state.procedureList.list);
      const { procedureId } = action;
      const updatedTemplateArray = state.procedureList.list.filter((p) => p.id !== procedureId);
      const procedureList = {
        ...state.procedureList,
        list: updatedTemplateArray,
        currentTotal: updatedTemplateArray.length,
        total: state.total - 1
      };

      return {
        ...state,
        procedureList,
        isLoading: false,
        updateSuccess: action.updateSuccess
      };
    }
    case ADD_SUBPROCEDURE:
    case DELETE_SUBPROCEDURE: {
      console.log('Update sub procedures', action);

      const updatedList = updateProcedureList(state.procedureList.list, action.procedure);
      const procedureList = { ...state.procedureList, list: updatedList };

      return {
        ...state,
        procedureList,
        isLoading: false,
        updateSuccess: true
      };
    }
    case PROCEDURE_TEMPLATE_FETCH_LIST: {
      console.log('fetch PROCEDURE templates', action);

      const { templateList } = action;
      let { procedureTemplateList } = state;

      if (action.pageStart > 0) {
        const updatedTemplateArray = [...state.procedureTemplateList.list, ...templateList];

        procedureTemplateList = {
          ...procedureTemplateList,
          list: updatedTemplateArray,
          currentTotal: updatedTemplateArray.length,
        };
      } else {
        procedureTemplateList = {
          ...procedureTemplateList,
          list: templateList,
          currentTotal: templateList.length,
          total: action.total,
          searchTerm: action.searchTerm,
          orderTerm: action.orderTerm,
          orderDirection: action.orderDirection,
          fetchTime: action.fetchTime,
        };
      }

      return {
        ...state,
        procedureTemplateList,
        isLoading: false,
        success: true
      };
    }
    case PROCEDURE_TEMPLATE_FETCH: {
      console.log('fetched PROCEDURE template', action);

      const { payload } = action;

      const fetchTime = new Date().getTime();
      const procedureTemplates = payload.map((t) => {
        const template = t == null ? null : JSON.parse(t.template);
        if (template !== null) {
          template.fetchTime = fetchTime;
        }
        return template;
      }).filter((filter) => filter !== null);

      const newList = replaceProcedureTemplates(state.procedureTemplates, procedureTemplates);

      return {
        ...state,
        procedureTemplates: newList,
        isLoading: false,
        success: true
      };
    }
    case PROCEDURE_TEMPLATE_ERROR: {
      console.log('PROCEDURE template error', action);
      return {
        ...state,
        hasError: action.hasError,
        errorMsg: action.errorMsg,
        success: false
      };
    }
    case PROCEDURE_TEMPLATE_LOADING: {
      console.log('isloading', action);

      return { ...state, isLoading: action.isLoading, };
    }
    case ADD_PROCEDURE_TEMPLATE: {
      console.log('Add PROCEDURE template', action);
      const template = action.procedureTemplate;

      const procedureList = state.procedureTemplateList.list;
      const updatedTemplateArray = updateProcedureTemplateList(procedureList, template);

      const procedureTemplateList = {
        ...state.procedureTemplateList,
        list: updatedTemplateArray,
        currentTotal: updatedTemplateArray.length,
      };

      return {
        ...state,
        procedureTemplateList,
        isLoading: false,
        success: true
      };
    }
    case PROCEDURE_TEMPLATE_SUCCESS_CLEAR: {
      console.log('Clear success flag');

      return {
        ...state,
        success: false,
        updateSuccess: false
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default proceduresReducer;
