/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Permission
 */
export interface Permission {
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    description?: string;
    /**
     * 
     * @type {object}
     * @memberof Permission
     */
    state?: object;
}

/**
 * Check if a given object implements the Permission interface.
 */
export function instanceOfPermission(value: object): value is Permission {
    return true;
}

export function PermissionFromJSON(json: any): Permission {
    return PermissionFromJSONTyped(json, false);
}

export function PermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Permission {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'state': json['state'] == null ? undefined : json['state'],
    };
}

export function PermissionToJSON(value?: Permission | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'state': value['state'],
    };
}

