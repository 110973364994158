/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a supported language
 * @export
 * @interface SupportedLanguageDto
 */
export interface SupportedLanguageDto {
    /**
     * Language code
     * @type {string}
     * @memberof SupportedLanguageDto
     */
    languageCode: string;
    /**
     * Language name
     * @type {string}
     * @memberof SupportedLanguageDto
     */
    languageName: string;
    /**
     * Language name
     * @type {string}
     * @memberof SupportedLanguageDto
     */
    localisedName: string;
    /**
     * Enabled
     * @type {boolean}
     * @memberof SupportedLanguageDto
     */
    enabled: boolean;
    /**
     * Permanent
     * @type {boolean}
     * @memberof SupportedLanguageDto
     */
    permanent?: boolean;
}

/**
 * Check if a given object implements the SupportedLanguageDto interface.
 */
export function instanceOfSupportedLanguageDto(value: object): value is SupportedLanguageDto {
    if (!('languageCode' in value) || value['languageCode'] === undefined) return false;
    if (!('languageName' in value) || value['languageName'] === undefined) return false;
    if (!('localisedName' in value) || value['localisedName'] === undefined) return false;
    if (!('enabled' in value) || value['enabled'] === undefined) return false;
    return true;
}

export function SupportedLanguageDtoFromJSON(json: any): SupportedLanguageDto {
    return SupportedLanguageDtoFromJSONTyped(json, false);
}

export function SupportedLanguageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportedLanguageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'languageCode': json['languageCode'],
        'languageName': json['languageName'],
        'localisedName': json['localisedName'],
        'enabled': json['enabled'],
        'permanent': json['permanent'] == null ? undefined : json['permanent'],
    };
}

export function SupportedLanguageDtoToJSON(value?: SupportedLanguageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'languageCode': value['languageCode'],
        'languageName': value['languageName'],
        'localisedName': value['localisedName'],
        'enabled': value['enabled'],
        'permanent': value['permanent'],
    };
}

