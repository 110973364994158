/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OverrideMessageDto } from './OverrideMessageDto';
import {
    OverrideMessageDtoFromJSON,
    OverrideMessageDtoFromJSONTyped,
    OverrideMessageDtoToJSON,
} from './OverrideMessageDto';
import type { AddressDto } from './AddressDto';
import {
    AddressDtoFromJSON,
    AddressDtoFromJSONTyped,
    AddressDtoToJSON,
} from './AddressDto';

/**
 * Represents a User Profile of the API
 * @export
 * @interface ProfileDto
 */
export interface ProfileDto {
    /**
     * 
     * @type {Array<OverrideMessageDto>}
     * @memberof ProfileDto
     */
    overrideMessages?: Array<OverrideMessageDto>;
    /**
     * User ids. Key is namespace, Value is id
     * @type {{ [key: string]: string; }}
     * @memberof ProfileDto
     */
    userIds: { [key: string]: string; };
    /**
     * Title
     * @type {string}
     * @memberof ProfileDto
     */
    title?: string;
    /**
     * First primaryEntityName(s)
     * @type {string}
     * @memberof ProfileDto
     */
    firstNames?: string;
    /**
     * Last primaryEntityName
     * @type {string}
     * @memberof ProfileDto
     */
    lastName?: string;
    /**
     * Users date of birth
     * @type {string}
     * @memberof ProfileDto
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {AddressDto}
     * @memberof ProfileDto
     */
    address?: AddressDto;
    /**
     * Mobile number
     * @type {string}
     * @memberof ProfileDto
     */
    mobileNumber?: string;
    /**
     * Second contact number
     * @type {string}
     * @memberof ProfileDto
     */
    telNumber?: string;
    /**
     * Gender, valid responses MALE / FEMALE / OTHER / UNKNOWN
     * @type {string}
     * @memberof ProfileDto
     */
    gender?: string;
    /**
     * Gender code, NHS AdministrativeGender coding
     * @type {number}
     * @memberof ProfileDto
     */
    genderCode?: number;
    /**
     * Ethnicity Code
     * @type {string}
     * @memberof ProfileDto
     */
    ethnicityCode?: string;
    /**
     * Ethnicity Code, namespace
     * @type {string}
     * @memberof ProfileDto
     */
    ethnicityCodeNamespace?: string;
    /**
     * Users email address
     * @type {string}
     * @memberof ProfileDto
     */
    emailAddress?: string;
    /**
     * If communications sent via email is allowed
     * @type {boolean}
     * @memberof ProfileDto
     */
    emailAcceptable?: boolean;
    /**
     * If communications sent via SMS is allowed
     * @type {boolean}
     * @memberof ProfileDto
     */
    smsAcceptable?: boolean;
    /**
     * Users date of death
     * @type {string}
     * @memberof ProfileDto
     */
    dateOfDeath?: string;
    /**
     * If the patient is deceased
     * @type {boolean}
     * @memberof ProfileDto
     */
    patientDeceased?: boolean;
}

/**
 * Check if a given object implements the ProfileDto interface.
 */
export function instanceOfProfileDto(value: object): value is ProfileDto {
    if (!('userIds' in value) || value['userIds'] === undefined) return false;
    return true;
}

export function ProfileDtoFromJSON(json: any): ProfileDto {
    return ProfileDtoFromJSONTyped(json, false);
}

export function ProfileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileDto {
    if (json == null) {
        return json;
    }
    return {
        
        'overrideMessages': json['overrideMessages'] == null ? undefined : ((json['overrideMessages'] as Array<any>).map(OverrideMessageDtoFromJSON)),
        'userIds': json['userIds'],
        'title': json['title'] == null ? undefined : json['title'],
        'firstNames': json['firstNames'] == null ? undefined : json['firstNames'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'dateOfBirth': json['dateOfBirth'] == null ? undefined : json['dateOfBirth'],
        'address': json['address'] == null ? undefined : AddressDtoFromJSON(json['address']),
        'mobileNumber': json['mobileNumber'] == null ? undefined : json['mobileNumber'],
        'telNumber': json['telNumber'] == null ? undefined : json['telNumber'],
        'gender': json['gender'] == null ? undefined : json['gender'],
        'genderCode': json['genderCode'] == null ? undefined : json['genderCode'],
        'ethnicityCode': json['ethnicityCode'] == null ? undefined : json['ethnicityCode'],
        'ethnicityCodeNamespace': json['ethnicityCodeNamespace'] == null ? undefined : json['ethnicityCodeNamespace'],
        'emailAddress': json['emailAddress'] == null ? undefined : json['emailAddress'],
        'emailAcceptable': json['emailAcceptable'] == null ? undefined : json['emailAcceptable'],
        'smsAcceptable': json['smsAcceptable'] == null ? undefined : json['smsAcceptable'],
        'dateOfDeath': json['dateOfDeath'] == null ? undefined : json['dateOfDeath'],
        'patientDeceased': json['patientDeceased'] == null ? undefined : json['patientDeceased'],
    };
}

export function ProfileDtoToJSON(value?: ProfileDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'overrideMessages': value['overrideMessages'] == null ? undefined : ((value['overrideMessages'] as Array<any>).map(OverrideMessageDtoToJSON)),
        'userIds': value['userIds'],
        'title': value['title'],
        'firstNames': value['firstNames'],
        'lastName': value['lastName'],
        'dateOfBirth': value['dateOfBirth'],
        'address': AddressDtoToJSON(value['address']),
        'mobileNumber': value['mobileNumber'],
        'telNumber': value['telNumber'],
        'gender': value['gender'],
        'genderCode': value['genderCode'],
        'ethnicityCode': value['ethnicityCode'],
        'ethnicityCodeNamespace': value['ethnicityCodeNamespace'],
        'emailAddress': value['emailAddress'],
        'emailAcceptable': value['emailAcceptable'],
        'smsAcceptable': value['smsAcceptable'],
        'dateOfDeath': value['dateOfDeath'],
        'patientDeceased': value['patientDeceased'],
    };
}

