/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAuthError,
  WidgetDataResponse,
} from '../models/index';
import {
    ApiAuthErrorFromJSON,
    ApiAuthErrorToJSON,
    WidgetDataResponseFromJSON,
    WidgetDataResponseToJSON,
} from '../models/index';

/**
 * 
 */
export class WidgetDataEndpointApi extends runtime.BaseAPI {

    /**
     */
    async getDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WidgetDataResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/widgets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetDataResponseFromJSON(jsonValue));
    }

    /**
     */
    async getData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WidgetDataResponse> {
        const response = await this.getDataRaw(initOverrides);
        return await response.value();
    }

}
