import { v4 as uuid } from 'uuid';
import { PathwayTemplateEndpointApi } from 'promsApi';
import {
  FETCH_PATHWAY_LIST,
  FETCH_PATHWAYS,
  ADD_PATHWAY,
  UPDATE_PATHWAY,
  DELETE_PATHWAY,
  PATHWAY_LOADING,
  PATHWAY_UPDATE_ERROR,
  PATHWAY_SUCCESS_CLEAR
} from './Actions';

import getConfiguation from './Configuration';
import onRequest from './Request';

const clearError = () => {
  return {
    type: PATHWAY_UPDATE_ERROR,
    hasError: false
  };
};

const hasError = (bool, errorMsg) => {
  return {
    type: PATHWAY_UPDATE_ERROR,
    hasError: bool,
    errorMsg
  };
};

const isLoading = (bool) => {
  return {
    type: PATHWAY_LOADING,
    isLoading: bool
  };
};

const clearUpdateSuccess = () => {
  return {
    type: PATHWAY_SUCCESS_CLEAR
  };
};

const fetchPathwayListSuccessHandler = (payload, searchTerm) => {
  console.log('++++++++++++');
  console.log(payload);
  return {
    type: FETCH_PATHWAY_LIST,
    pathwayList: payload == null ? {} : payload.pathwayList,
    total: payload.total,
    pageStart: payload.start,
    searchTerm,
    orderTerm: payload.orderTerm,
    orderDirection: payload.orderDirection,
    fetchTime: new Date().getTime()
  };
};

const fetchPathwayList = (searchTerm, pageSize, start, orderTerm, orderDirection) => {
  console.log('Fetching pathway list');
  console.log(searchTerm);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);

    // Split search term
    const searchTermMapping = {
      NAME: searchTerm
    };

    const searchRequest = {
      searchTerms: searchTermMapping,
      pageSize,
      start,
      orderTerm,
      orderDirection
    };

    const pathwayTemplateApi = new PathwayTemplateEndpointApi(config);
    const request = pathwayTemplateApi.getTemplateList({ searchRequest });

    onRequest(request, dispatch, fetchPathwayListSuccessHandler, hasError, isLoading, searchTerm, 'Error fetching pathways');
  };
};

const onFetchPathwaysSuccess = (response) => {
  return {
    type: FETCH_PATHWAYS,
    pathways: response,
    success: true
  };
};

const fetchPathways = (templateIds) => {
  console.log('Fetch template');
  console.log(templateIds);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const ids = [templateIds];

    const config = getConfiguation(dispatch, getState);
    const pathwayTemplateApi = new PathwayTemplateEndpointApi(config);
    const request = pathwayTemplateApi.getPathwayTemplateJson({ ids });
    onRequest(request, dispatch, onFetchPathwaysSuccess, hasError, isLoading, null, 'Error fetching pathways');
  };
};

const addPathwaySuccess = (pathway, body) => {
  return {
    type: ADD_PATHWAY,
    pathway,
    pathwayListItem: {
      id: body.id,
      description: body.description,
      name: body.name,
      summary: body.summary
    }
  };
};

const addPathway = (pathwayTemplate) => {
  console.log('upload pathway Template');
  console.log(pathwayTemplate);

  pathwayTemplate.id = uuid();

  const summary = pathwayTemplate.steps.map((step) => step.title).join(' -> ');

  const primaryProcedure = pathwayTemplate.steps.find((step) => step.id === 0);
  const { prepopulatedFields } = primaryProcedure;

  const body = {
    name: pathwayTemplate.title,
    description: pathwayTemplate.description,
    id: pathwayTemplate.id,
    template: JSON.stringify(pathwayTemplate),
    summary,
    prepopulatedFields
  };

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const pathwayTemplateApi = new PathwayTemplateEndpointApi(config);
    const request = pathwayTemplateApi.importPathwayTemplate({ pathwayTemplateDto: body });
    onRequest(request, dispatch, addPathwaySuccess, hasError, isLoading, body, 'Error adding pathway');
  };
};

const editPathwaySuccess = (pathway) => {
  return {
    type: UPDATE_PATHWAY,
    pathway
  };
};

const editPathway = (pathwayTemplate) => {
  console.log('upload pathway Template');

  const summary = pathwayTemplate.steps.map((step) => step.title).join(' -> ');

  const body = {
    name: pathwayTemplate.title,
    description: pathwayTemplate.description,
    id: pathwayTemplate.id,
    template: JSON.stringify(pathwayTemplate),
    summary
  };

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const pathwayTemplateApi = new PathwayTemplateEndpointApi(config);
    const request = pathwayTemplateApi.importPathwayTemplate({ pathwayTemplateDto: body });
    onRequest(request, dispatch, editPathwaySuccess, hasError, isLoading, null, 'Error saving edits');
  };
};

const onDeletePathwaySuccess = (response, pathway) => {
  return {
    type: DELETE_PATHWAY,
    pathway
  };
};

const deletePathway = (pathway) => {
  console.log('delete pathway Template');

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const api = new PathwayTemplateEndpointApi(config);
    const request = api.deletePathway({ pathwayId: pathway.id, pathwayName: pathway.name });
    onRequest(request, dispatch, onDeletePathwaySuccess, hasError, isLoading, pathway, 'Error deleting pathway');
  };
};

export default {
  fetchPathwayList,
  fetchPathways,
  addPathway,
  editPathway,
  deletePathway,
  clearUpdateSuccess,
  clearError
};
