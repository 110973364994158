/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RescheduleNotificationRequest
 */
export interface RescheduleNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof RescheduleNotificationRequest
     */
    ehrId?: string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleNotificationRequest
     */
    pathwayUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleNotificationRequest
     */
    patientId?: string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleNotificationRequest
     */
    patientIdNamespace?: string;
}

/**
 * Check if a given object implements the RescheduleNotificationRequest interface.
 */
export function instanceOfRescheduleNotificationRequest(value: object): value is RescheduleNotificationRequest {
    return true;
}

export function RescheduleNotificationRequestFromJSON(json: any): RescheduleNotificationRequest {
    return RescheduleNotificationRequestFromJSONTyped(json, false);
}

export function RescheduleNotificationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RescheduleNotificationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'ehrId': json['ehrId'] == null ? undefined : json['ehrId'],
        'pathwayUuid': json['pathwayUuid'] == null ? undefined : json['pathwayUuid'],
        'patientId': json['patientId'] == null ? undefined : json['patientId'],
        'patientIdNamespace': json['patientIdNamespace'] == null ? undefined : json['patientIdNamespace'],
    };
}

export function RescheduleNotificationRequestToJSON(value?: RescheduleNotificationRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ehrId': value['ehrId'],
        'pathwayUuid': value['pathwayUuid'],
        'patientId': value['patientId'],
        'patientIdNamespace': value['patientIdNamespace'],
    };
}

