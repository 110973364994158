import { AuditEndpointApi } from 'promsApi';
import {
  AUDIT_FETCH_LIST,
  AUDIT_IS_LOADING,
  AUDIT_ERROR
} from './Actions';

import getConfiguation from './Configuration';
import onRequest from './Request';

const moment = require('moment');

const auditError = (bool, errorMsg) => {
  return {
    type: AUDIT_ERROR,
    hasError: bool,
    errorMsg
  };
};

const auditLoading = (bool) => {
  return {
    type: AUDIT_IS_LOADING,
    isLoading: bool
  };
};

const clearAuditError = () => {
  return {
    type: AUDIT_ERROR,
    hasError: false,
    errorMsg: null
  };
};

const fetchAuditSuccessHandler = (payload, searchTermMapping) => {
  return {
    type: AUDIT_FETCH_LIST,
    audit: payload == null ? [] : payload.auditList,
    total: payload.total,
    pageStart: payload.start,
    orderTerm: payload.orderTerm,
    orderDirection: payload.orderDirection,
    searchTerms: searchTermMapping
  };
};

const fetchAudit = (searchTerms, pageSize, start, orderTerm, orderDirection) => {
  console.log('Fetching audit list');

  return (dispatch, getState) => {
    dispatch(auditLoading(true));

    const config = getConfiguation(dispatch, getState);
    const auditApi = new AuditEndpointApi(config); // TODO

    const searchTermMapping = { ...searchTerms };
    if (searchTerms.user) {
      searchTermMapping.username = searchTerms.user.username;
      delete searchTermMapping.user;
    }

    searchTermMapping.start = moment(searchTerms.start).utc();
    searchTermMapping.end = moment(searchTerms.end).utc();

    const auditSearchRequest = {
      pageSize,
      start,
      searchTerms: searchTermMapping,
      orderTerm,
      orderDirection
    };

    const request = auditApi.findAuditEntries({ searchRequest: auditSearchRequest });
    onRequest(request, dispatch, fetchAuditSuccessHandler, auditError, auditLoading, searchTerms);
  };
};

const createAuditSuccessHandler = (payload) => {
  console.log(payload);
  return {
    type: 'IGNORE'
  };
};

const userLoggedIn = () => {
  console.log('User logged in');

  return (dispatch, getState) => {
    const config = getConfiguation(dispatch, getState);
    const auditApi = new AuditEndpointApi(config); // TODO

    const createAuditRequest = {
      type: 'USER',
      action: 'LOGGED_IN',
      attributes: { action: 'LOGGED_IN' }
    };

    const request = auditApi.createAuditEntry({ externalAuditDto: createAuditRequest });
    onRequest(request, dispatch, createAuditSuccessHandler, auditError, auditLoading);
  };
};

const userLoggedOut = () => {
  console.log('User logged in');

  return (dispatch, getState) => {
    const config = getConfiguation(dispatch, getState);
    const auditApi = new AuditEndpointApi(config); // TODO

    const createAuditRequest = {
      type: 'USER',
      action: 'LOGGED_IN',
      attributes: { action: 'LOGGED_OUT' }
    };

    const request = auditApi.createAuditEntry({ externalAuditDto: createAuditRequest });
    onRequest(request, dispatch, createAuditSuccessHandler, auditError, auditLoading);
  };
};



export default {
  clearAuditError,
  fetchAudit,
  userLoggedIn,
  userLoggedOut
};
