import { UPDATE_SERVER_CONFIG } from './Actions';

const updateServerConfig = (serverUrl) => {
  return {
    type: UPDATE_SERVER_CONFIG,
    payload: {
      serverUrl
    }
  };
};

export default {
  updateServerConfig
};
