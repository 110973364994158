/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents an opt template
 * @export
 * @interface OptTemplateDto
 */
export interface OptTemplateDto {
    /**
     * The opt template
     * @type {string}
     * @memberof OptTemplateDto
     */
    template: string;
}

/**
 * Check if a given object implements the OptTemplateDto interface.
 */
export function instanceOfOptTemplateDto(value: object): value is OptTemplateDto {
    if (!('template' in value) || value['template'] === undefined) return false;
    return true;
}

export function OptTemplateDtoFromJSON(json: any): OptTemplateDto {
    return OptTemplateDtoFromJSONTyped(json, false);
}

export function OptTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptTemplateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'template': json['template'],
    };
}

export function OptTemplateDtoToJSON(value?: OptTemplateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'template': value['template'],
    };
}

