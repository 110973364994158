import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@mui/material';

import { makeStyles } from '@mui/styles'

import {
  Database as DatabaseIcon,
  Settings as SettingsIcon,
  Users as UsersIcon,
  Home as HomeIcon,
  CheckSquare as TasksIcon,
  GitPullRequest as CarePathwaysIcon,
  GitCommit as ProceduresIcon,
  Clipboard as PROMsIcon,
  User as MyProfileIcon,
  Book as AuditIcon,
  HelpCircle as HelpAndSupportIcon
} from 'react-feather';
import NavItem from './NavItem';
import InputIcon from '@mui/icons-material/Input';
import { useTranslation } from 'react-i18next';

import PermissionMappings from 'src/PermissionMappings';

import generatedGitInfo from 'src/generatedGitInfo.json';

import ReportingSubListItem from './ReportingSubList';

const nonAdminItems = [
  {
    href: '/app/proms',
    icon: DatabaseIcon,
    title: 'navbar_PROMs'
  },
  {
    href: '/app/profile',
    icon: SettingsIcon,
    title: 'navbar_Settings'
  }
];

const adminMenuItems = [
  {
    standard: [
      {
        href: '/app/dashboard',
        icon: HomeIcon,
        title: 'navbar_Dashboard'
      }
    ],
    permissionBased: [
      {
        screen: "patients",
        item: {
          href: '/app/patients',
          icon: UsersIcon,
          title: 'navbar_Patients'
        }
      },
      {
        screen: "reports",
        item: {
          subType: "REPORTS",
        }
      },
      {
        screen: "tasks",
        item: {
          href: '/app/tasks',
          icon: TasksIcon,
          title: 'navbar_Tasks'
        }
      }
    ]
  },
  {
    standard: [
      {
        divider: true
      }
    ],
    permissionBased: [
      {
        screen: "pathways",
        item: {
          href: '/app/pathways',
          icon: CarePathwaysIcon,
          title: 'navbar_Care_pathways'
        }
      },
      {
        screen: "procedures",
        item: {
          href: '/app/procedures',
          icon: ProceduresIcon,
          title: 'navbar_Procedures'
        }
      },
      {
        screen: "proms",
        item:
        {
          href: '/app/proms',
          icon: PROMsIcon,
          title: 'navbar_PROMs'
        }
      },
      {
        screen: "config",
        item:
        {
          href: '/app/config',
          icon: SettingsIcon,
          title: 'navbar_Config'
        }
      }
    ]
  },
  {
    standard: [
      {
        divider: true
      },
      {
        href: '/app/profile',
        icon: MyProfileIcon,
        title: 'navbar_My_profile'
      },
      {
        divider: true
      },
    ],
    permissionBased: [
      {
        screen: "staff",
        item: {
          href: '/app/users',
          icon: UsersIcon,
          title: 'navbar_Users'
        }
      },
      {
        screen: "audit",
        item: {
          href: '/app/audit',
          icon: AuditIcon,
          title: 'navbar_Audit',
          hide: window._env_.REACT_APP_HIDE_AUDIT === "true"
        }
      },
    ]
  },
/*
  TODO enable help and support page
{
    standard: [
      {
        divider: true
      },
      {
        href: '/app/help',
        icon: HelpAndSupportIcon,
        title: 'navbar_Help_and_support'
      }
    ],
    permissionBased: []
  },
  */
]

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 192
  },
  desktopDrawer: {
    width: 192,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile, adminMode, permissions, rolePermissions, staffProfile, reportingUrls, onLogout }) => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);


  const getItems = () => {
    var itemArray;
    if (adminMode) {
      itemArray = [];

      // Get list of "screen" permissions
      if (permissions != null) {
        var permittedScreens = permissions.map((permission) => {
          var item = PermissionMappings.find((mapping) => mapping.permission === permission);
          if (item !== undefined) {
            return item.screen;
          } else {
            return null;
          }
        }
        ).filter((filter) => filter !== null); // Removed nulls from array

        adminMenuItems.forEach(element => {
          itemArray.push(...element.standard);

          element.permissionBased.forEach((permissionScreen) => {
            if (permissionScreen.item.hide !== true && permittedScreens.indexOf(permissionScreen.screen) > -1) {
              // Handle subtypes
              if (permissionScreen.item.subType === "REPORTS") {
                if (reportingUrls.length !== 0) {
                  itemArray.push(permissionScreen.item);
                }
              } else {
                itemArray.push(permissionScreen.item);
              }
            }
          });
        });
      }
    } else {
      itemArray = nonAdminItems
    }

    return itemArray;
  }

  const getName = () => {
    if (staffProfile != null) {
      return staffProfile.firstNames + " " + staffProfile.lastName;
    }
  }

  const getRole = () => {
    var roleLabel = "";
    if (staffProfile != null) {
      var findRole = rolePermissions.find((r) => r.role == staffProfile.role);
      if (findRole != null) {
        roleLabel = findRole.label;
      }
    }

    return roleLabel;
  }

  const logoutButton = (
    <NavItem
      key="Logout"
      title="Logout"
      icon={InputIcon}
      onButtonClick={() => { onLogout() }}
    />
  )

  const getItemSublist = (item) => {
    var sublist = null;
    if ( item.subType === "REPORTS" ){
      return (<ReportingSubListItem reportingUrls={reportingUrls}/>);
    }

    return sublist;
  }

  const getVersion = () => {
    var version = generatedGitInfo.gitTag;
    if (version === null) {
      version = generatedGitInfo.gitCommitHash;
    }

    return version;
  }

  const content = (smallScreen) => (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {getName()}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {getRole()}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {getItems().map((item, index) => {
            if (item.subType) {
              return getItemSublist(item);
            } else if (item.href == null) {
              return (<Divider key={'divider_' + index} />);
            } else {
              return (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={t(item.title)}
                  icon={item.icon}
                  selected={item.href === location.pathname}
                />
              )
            }
          }
          )}
          {smallScreen ? logoutButton : null}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {t("Version")}: {getVersion()}
        </Typography>
      </Box>
    </Box>
  );

  return <>
    <Hidden lgUp>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {content(true)}
      </Drawer>
    </Hidden>
    <Hidden lgDown>
      <Drawer
        anchor="left"
        classes={{ paper: classes.desktopDrawer }}
        open
        variant="persistent"
      >
        {content(false)}
      </Drawer>
    </Hidden>
  </>;
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;
