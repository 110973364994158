/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { QuestionnairePdfDto } from './QuestionnairePdfDto';
import {
    QuestionnairePdfDtoFromJSON,
    QuestionnairePdfDtoFromJSONTyped,
    QuestionnairePdfDtoToJSON,
} from './QuestionnairePdfDto';

/**
 * Represents a ui template json
 * @export
 * @interface UiTemplateDto
 */
export interface UiTemplateDto {
    /**
     * Id
     * @type {string}
     * @memberof UiTemplateDto
     */
    id: string;
    /**
     * The proms questionnaire or procedure template
     * @type {string}
     * @memberof UiTemplateDto
     */
    template: string;
    /**
     * The templateId associated with the template, must be unique throughout the system
     * @type {string}
     * @memberof UiTemplateDto
     */
    templateId: string;
    /**
     * The template name
     * @type {string}
     * @memberof UiTemplateDto
     */
    name: string;
    /**
     * The template description
     * @type {string}
     * @memberof UiTemplateDto
     */
    description: string;
    /**
     * List of pdfs
     * @type {Array<QuestionnairePdfDto>}
     * @memberof UiTemplateDto
     */
    pdfList?: Array<QuestionnairePdfDto>;
    /**
     * List of prepopulated fields
     * @type {Array<string>}
     * @memberof UiTemplateDto
     */
    prepopulatedFields?: Array<string>;
}

/**
 * Check if a given object implements the UiTemplateDto interface.
 */
export function instanceOfUiTemplateDto(value: object): value is UiTemplateDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('template' in value) || value['template'] === undefined) return false;
    if (!('templateId' in value) || value['templateId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    return true;
}

export function UiTemplateDtoFromJSON(json: any): UiTemplateDto {
    return UiTemplateDtoFromJSONTyped(json, false);
}

export function UiTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UiTemplateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'template': json['template'],
        'templateId': json['templateId'],
        'name': json['name'],
        'description': json['description'],
        'pdfList': json['pdfList'] == null ? undefined : ((json['pdfList'] as Array<any>).map(QuestionnairePdfDtoFromJSON)),
        'prepopulatedFields': json['prepopulatedFields'] == null ? undefined : json['prepopulatedFields'],
    };
}

export function UiTemplateDtoToJSON(value?: UiTemplateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'template': value['template'],
        'templateId': value['templateId'],
        'name': value['name'],
        'description': value['description'],
        'pdfList': value['pdfList'] == null ? undefined : ((value['pdfList'] as Array<any>).map(QuestionnairePdfDtoToJSON)),
        'prepopulatedFields': value['prepopulatedFields'],
    };
}

