/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessibleNameDto } from './AccessibleNameDto';
import {
    AccessibleNameDtoFromJSON,
    AccessibleNameDtoFromJSONTyped,
    AccessibleNameDtoToJSON,
} from './AccessibleNameDto';
import type { SubProcedureCode } from './SubProcedureCode';
import {
    SubProcedureCodeFromJSON,
    SubProcedureCodeFromJSONTyped,
    SubProcedureCodeToJSON,
} from './SubProcedureCode';

/**
 * 
 * @export
 * @interface Procedure
 */
export interface Procedure {
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    procedureId?: string;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    scheduled?: string;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    performed?: string;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    performedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    title?: string;
    /**
     * 
     * @type {Array<SubProcedureCode>}
     * @memberof Procedure
     */
    subProcedureCodes?: Array<SubProcedureCode>;
    /**
     * 
     * @type {Array<AccessibleNameDto>}
     * @memberof Procedure
     */
    accessibleNames?: Array<AccessibleNameDto>;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    subProcedureId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Procedure
     */
    updatePendingProms?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    additionalInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    procedureSide?: string;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    uiTemplate?: string;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    compositionUUID?: string;
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    procedureDescription?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Procedure
     */
    prepopulatedFields?: Array<string>;
}

/**
 * Check if a given object implements the Procedure interface.
 */
export function instanceOfProcedure(value: object): value is Procedure {
    return true;
}

export function ProcedureFromJSON(json: any): Procedure {
    return ProcedureFromJSONTyped(json, false);
}

export function ProcedureFromJSONTyped(json: any, ignoreDiscriminator: boolean): Procedure {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'procedureId': json['procedureId'] == null ? undefined : json['procedureId'],
        'scheduled': json['scheduled'] == null ? undefined : json['scheduled'],
        'performed': json['performed'] == null ? undefined : json['performed'],
        'performedBy': json['performedBy'] == null ? undefined : json['performedBy'],
        'title': json['title'] == null ? undefined : json['title'],
        'subProcedureCodes': json['subProcedureCodes'] == null ? undefined : ((json['subProcedureCodes'] as Array<any>).map(SubProcedureCodeFromJSON)),
        'accessibleNames': json['accessibleNames'] == null ? undefined : ((json['accessibleNames'] as Array<any>).map(AccessibleNameDtoFromJSON)),
        'subProcedureId': json['subProcedureId'] == null ? undefined : json['subProcedureId'],
        'updatePendingProms': json['updatePendingProms'] == null ? undefined : json['updatePendingProms'],
        'additionalInfo': json['additionalInfo'] == null ? undefined : json['additionalInfo'],
        'procedureSide': json['procedureSide'] == null ? undefined : json['procedureSide'],
        'uiTemplate': json['uiTemplate'] == null ? undefined : json['uiTemplate'],
        'compositionUUID': json['compositionUUID'] == null ? undefined : json['compositionUUID'],
        'procedureDescription': json['procedureDescription'] == null ? undefined : json['procedureDescription'],
        'prepopulatedFields': json['prepopulatedFields'] == null ? undefined : json['prepopulatedFields'],
    };
}

export function ProcedureToJSON(value?: Procedure | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'procedureId': value['procedureId'],
        'scheduled': value['scheduled'],
        'performed': value['performed'],
        'performedBy': value['performedBy'],
        'title': value['title'],
        'subProcedureCodes': value['subProcedureCodes'] == null ? undefined : ((value['subProcedureCodes'] as Array<any>).map(SubProcedureCodeToJSON)),
        'accessibleNames': value['accessibleNames'] == null ? undefined : ((value['accessibleNames'] as Array<any>).map(AccessibleNameDtoToJSON)),
        'subProcedureId': value['subProcedureId'],
        'updatePendingProms': value['updatePendingProms'],
        'additionalInfo': value['additionalInfo'],
        'procedureSide': value['procedureSide'],
        'uiTemplate': value['uiTemplate'],
        'compositionUUID': value['compositionUUID'],
        'procedureDescription': value['procedureDescription'],
        'prepopulatedFields': value['prepopulatedFields'],
    };
}

