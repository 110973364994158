/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Site
 */
export interface Site {
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    site?: string;
}

/**
 * Check if a given object implements the Site interface.
 */
export function instanceOfSite(value: object): value is Site {
    return true;
}

export function SiteFromJSON(json: any): Site {
    return SiteFromJSONTyped(json, false);
}

export function SiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): Site {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'site': json['site'] == null ? undefined : json['site'],
    };
}

export function SiteToJSON(value?: Site | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'site': value['site'],
    };
}

