/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAuthError,
  ApiError,
  ApiValidationErrors,
  PatientSearchResponse,
  ProfileDto,
  SearchRequest,
  SendPromsNotificationDto,
} from '../models/index';
import {
    ApiAuthErrorFromJSON,
    ApiAuthErrorToJSON,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    ApiValidationErrorsFromJSON,
    ApiValidationErrorsToJSON,
    PatientSearchResponseFromJSON,
    PatientSearchResponseToJSON,
    ProfileDtoFromJSON,
    ProfileDtoToJSON,
    SearchRequestFromJSON,
    SearchRequestToJSON,
    SendPromsNotificationDtoFromJSON,
    SendPromsNotificationDtoToJSON,
} from '../models/index';

export interface Create1Request {
    profileDto: ProfileDto;
}

export interface FindPatientsRequest {
    searchRequest: SearchRequest;
}

export interface FindPatients1Request {
    start: number;
    pageSize: number;
}

export interface GetProfile2Request {
    userId: string;
    userIdNamespace: string;
}

export interface SendPromsNotificationRequest {
    sendPromsNotificationDto: SendPromsNotificationDto;
}

export interface UpdateRequest {
    profileDto: ProfileDto;
}

/**
 * 
 */
export class PatientsEndpointApi extends runtime.BaseAPI {

    /**
     * Creates a new user
     * create
     */
    async create1Raw(requestParameters: Create1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileDto>> {
        if (requestParameters['profileDto'] == null) {
            throw new runtime.RequiredError(
                'profileDto',
                'Required parameter "profileDto" was null or undefined when calling create1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProfileDtoToJSON(requestParameters['profileDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new user
     * create
     */
    async create1(requestParameters: Create1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileDto> {
        const response = await this.create1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return list of patients with search terms
     * patients
     */
    async findPatientsRaw(requestParameters: FindPatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientSearchResponse>> {
        if (requestParameters['searchRequest'] == null) {
            throw new runtime.RequiredError(
                'searchRequest',
                'Required parameter "searchRequest" was null or undefined when calling findPatients().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters['searchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientSearchResponseFromJSON(jsonValue));
    }

    /**
     * Return list of patients with search terms
     * patients
     */
    async findPatients(requestParameters: FindPatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientSearchResponse> {
        const response = await this.findPatientsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return list of patients
     * patients
     */
    async findPatients1Raw(requestParameters: FindPatients1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientSearchResponse>> {
        if (requestParameters['start'] == null) {
            throw new runtime.RequiredError(
                'start',
                'Required parameter "start" was null or undefined when calling findPatients1().'
            );
        }

        if (requestParameters['pageSize'] == null) {
            throw new runtime.RequiredError(
                'pageSize',
                'Required parameter "pageSize" was null or undefined when calling findPatients1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['start'] != null) {
            queryParameters['start'] = requestParameters['start'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientSearchResponseFromJSON(jsonValue));
    }

    /**
     * Return list of patients
     * patients
     */
    async findPatients1(requestParameters: FindPatients1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientSearchResponse> {
        const response = await this.findPatients1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches current profile details for user
     * profile
     */
    async getProfile2Raw(requestParameters: GetProfile2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileDto>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getProfile2().'
            );
        }

        if (requestParameters['userIdNamespace'] == null) {
            throw new runtime.RequiredError(
                'userIdNamespace',
                'Required parameter "userIdNamespace" was null or undefined when calling getProfile2().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['userId'] != null) {
            queryParameters['userId'] = requestParameters['userId'];
        }

        if (requestParameters['userIdNamespace'] != null) {
            queryParameters['userIdNamespace'] = requestParameters['userIdNamespace'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileDtoFromJSON(jsonValue));
    }

    /**
     * Fetches current profile details for user
     * profile
     */
    async getProfile2(requestParameters: GetProfile2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileDto> {
        const response = await this.getProfile2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Used to send proms notification to patient
     * promsNotification
     */
    async sendPromsNotificationRaw(requestParameters: SendPromsNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['sendPromsNotificationDto'] == null) {
            throw new runtime.RequiredError(
                'sendPromsNotificationDto',
                'Required parameter "sendPromsNotificationDto" was null or undefined when calling sendPromsNotification().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient/promsNotification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendPromsNotificationDtoToJSON(requestParameters['sendPromsNotificationDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to send proms notification to patient
     * promsNotification
     */
    async sendPromsNotification(requestParameters: SendPromsNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendPromsNotificationRaw(requestParameters, initOverrides);
    }

    /**
     * Update an existing user
     * update
     */
    async updateRaw(requestParameters: UpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileDto>> {
        if (requestParameters['profileDto'] == null) {
            throw new runtime.RequiredError(
                'profileDto',
                'Required parameter "profileDto" was null or undefined when calling update().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProfileDtoToJSON(requestParameters['profileDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileDtoFromJSON(jsonValue));
    }

    /**
     * Update an existing user
     * update
     */
    async update(requestParameters: UpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileDto> {
        const response = await this.updateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
