/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Step } from './Step';
import {
    StepFromJSON,
    StepFromJSONTyped,
    StepToJSON,
} from './Step';

/**
 * Request for an update to a pathway step
 * @export
 * @interface PathwayStepDto
 */
export interface PathwayStepDto {
    /**
     * 
     * @type {Step}
     * @memberof PathwayStepDto
     */
    step?: Step;
    /**
     * The user for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof PathwayStepDto
     */
    userId?: string;
    /**
     * The namespace for the userId for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof PathwayStepDto
     */
    userIdNamespace?: string;
    /**
     * The uuid of the pathway composition that is being updated
     * @type {string}
     * @memberof PathwayStepDto
     */
    pathwayUUID?: string;
    /**
     * The id of the pathway that is being updated
     * @type {string}
     * @memberof PathwayStepDto
     */
    pathwayId?: string;
    /**
     * The name of the pathway that is being updated
     * @type {string}
     * @memberof PathwayStepDto
     */
    pathwayName?: string;
}

/**
 * Check if a given object implements the PathwayStepDto interface.
 */
export function instanceOfPathwayStepDto(value: object): value is PathwayStepDto {
    return true;
}

export function PathwayStepDtoFromJSON(json: any): PathwayStepDto {
    return PathwayStepDtoFromJSONTyped(json, false);
}

export function PathwayStepDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PathwayStepDto {
    if (json == null) {
        return json;
    }
    return {
        
        'step': json['step'] == null ? undefined : StepFromJSON(json['step']),
        'userId': json['userId'] == null ? undefined : json['userId'],
        'userIdNamespace': json['userIdNamespace'] == null ? undefined : json['userIdNamespace'],
        'pathwayUUID': json['pathwayUUID'] == null ? undefined : json['pathwayUUID'],
        'pathwayId': json['pathwayId'] == null ? undefined : json['pathwayId'],
        'pathwayName': json['pathwayName'] == null ? undefined : json['pathwayName'],
    };
}

export function PathwayStepDtoToJSON(value?: PathwayStepDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'step': StepToJSON(value['step']),
        'userId': value['userId'],
        'userIdNamespace': value['userIdNamespace'],
        'pathwayUUID': value['pathwayUUID'],
        'pathwayId': value['pathwayId'],
        'pathwayName': value['pathwayName'],
    };
}

