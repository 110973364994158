/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OverrideMessageDto
 */
export interface OverrideMessageDto {
    /**
     * 
     * @type {string}
     * @memberof OverrideMessageDto
     */
    field?: string;
    /**
     * 
     * @type {string}
     * @memberof OverrideMessageDto
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof OverrideMessageDto
     */
    pasValue?: string;
    /**
     * 
     * @type {string}
     * @memberof OverrideMessageDto
     */
    overrideValue?: string;
}

/**
 * Check if a given object implements the OverrideMessageDto interface.
 */
export function instanceOfOverrideMessageDto(value: object): value is OverrideMessageDto {
    return true;
}

export function OverrideMessageDtoFromJSON(json: any): OverrideMessageDto {
    return OverrideMessageDtoFromJSONTyped(json, false);
}

export function OverrideMessageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverrideMessageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'field': json['field'] == null ? undefined : json['field'],
        'code': json['code'] == null ? undefined : json['code'],
        'pasValue': json['pasValue'] == null ? undefined : json['pasValue'],
        'overrideValue': json['overrideValue'] == null ? undefined : json['overrideValue'],
    };
}

export function OverrideMessageDtoToJSON(value?: OverrideMessageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'field': value['field'],
        'code': value['code'],
        'pasValue': value['pasValue'],
        'overrideValue': value['overrideValue'],
    };
}

