/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a opt template
 * @export
 * @interface TemplateDto
 */
export interface TemplateDto {
    /**
     * The templateId associated with the template, must be unique throughout the system
     * @type {string}
     * @memberof TemplateDto
     */
    templateId: string;
}

/**
 * Check if a given object implements the TemplateDto interface.
 */
export function instanceOfTemplateDto(value: object): value is TemplateDto {
    if (!('templateId' in value) || value['templateId'] === undefined) return false;
    return true;
}

export function TemplateDtoFromJSON(json: any): TemplateDto {
    return TemplateDtoFromJSONTyped(json, false);
}

export function TemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'templateId': json['templateId'],
    };
}

export function TemplateDtoToJSON(value?: TemplateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'templateId': value['templateId'],
    };
}

