/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a user task
 * @export
 * @interface TaskDto
 */
export interface TaskDto {
    /**
     * Id
     * @type {number}
     * @memberof TaskDto
     */
    id: number;
    /**
     * Task type
     * @type {string}
     * @memberof TaskDto
     */
    taskType: string;
    /**
     * Task class
     * @type {string}
     * @memberof TaskDto
     */
    taskClass: string;
    /**
     * Task status
     * @type {string}
     * @memberof TaskDto
     */
    status: string;
    /**
     * Patient first names
     * @type {string}
     * @memberof TaskDto
     */
    patientFirstNames: string;
    /**
     * Patient last name
     * @type {string}
     * @memberof TaskDto
     */
    patientLastName: string;
    /**
     * Patient id
     * @type {string}
     * @memberof TaskDto
     */
    patientId: string;
    /**
     * Patient id namespace
     * @type {string}
     * @memberof TaskDto
     */
    patientIdNamespace: string;
    /**
     * Name of responsible clinician
     * @type {string}
     * @memberof TaskDto
     */
    clinicianName?: string;
    /**
     * Id of responsible clinician
     * @type {string}
     * @memberof TaskDto
     */
    clinicianId?: string;
    /**
     * Id of procedure
     * @type {string}
     * @memberof TaskDto
     */
    procedureId?: string;
    /**
     * Procedure name
     * @type {string}
     * @memberof TaskDto
     */
    procedureName?: string;
    /**
     * Pathway name
     * @type {string}
     * @memberof TaskDto
     */
    pathwayName?: string;
    /**
     * Pathway uuid
     * @type {string}
     * @memberof TaskDto
     */
    pathwayUID?: string;
    /**
     * Pathway id
     * @type {string}
     * @memberof TaskDto
     */
    pathwayId?: string;
    /**
     * Proms name
     * @type {string}
     * @memberof TaskDto
     */
    promsName?: string;
    /**
     * Step id
     * @type {string}
     * @memberof TaskDto
     */
    stepId?: string;
    /**
     * Due date
     * @type {string}
     * @memberof TaskDto
     */
    dueDate?: string;
    /**
     * Date completed
     * @type {string}
     * @memberof TaskDto
     */
    completionDate?: string;
}

/**
 * Check if a given object implements the TaskDto interface.
 */
export function instanceOfTaskDto(value: object): value is TaskDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('taskType' in value) || value['taskType'] === undefined) return false;
    if (!('taskClass' in value) || value['taskClass'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('patientFirstNames' in value) || value['patientFirstNames'] === undefined) return false;
    if (!('patientLastName' in value) || value['patientLastName'] === undefined) return false;
    if (!('patientId' in value) || value['patientId'] === undefined) return false;
    if (!('patientIdNamespace' in value) || value['patientIdNamespace'] === undefined) return false;
    return true;
}

export function TaskDtoFromJSON(json: any): TaskDto {
    return TaskDtoFromJSONTyped(json, false);
}

export function TaskDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'taskType': json['taskType'],
        'taskClass': json['taskClass'],
        'status': json['status'],
        'patientFirstNames': json['patientFirstNames'],
        'patientLastName': json['patientLastName'],
        'patientId': json['patientId'],
        'patientIdNamespace': json['patientIdNamespace'],
        'clinicianName': json['clinicianName'] == null ? undefined : json['clinicianName'],
        'clinicianId': json['clinicianId'] == null ? undefined : json['clinicianId'],
        'procedureId': json['procedureId'] == null ? undefined : json['procedureId'],
        'procedureName': json['procedureName'] == null ? undefined : json['procedureName'],
        'pathwayName': json['pathwayName'] == null ? undefined : json['pathwayName'],
        'pathwayUID': json['pathwayUID'] == null ? undefined : json['pathwayUID'],
        'pathwayId': json['pathwayId'] == null ? undefined : json['pathwayId'],
        'promsName': json['promsName'] == null ? undefined : json['promsName'],
        'stepId': json['stepId'] == null ? undefined : json['stepId'],
        'dueDate': json['dueDate'] == null ? undefined : json['dueDate'],
        'completionDate': json['completionDate'] == null ? undefined : json['completionDate'],
    };
}

export function TaskDtoToJSON(value?: TaskDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'taskType': value['taskType'],
        'taskClass': value['taskClass'],
        'status': value['status'],
        'patientFirstNames': value['patientFirstNames'],
        'patientLastName': value['patientLastName'],
        'patientId': value['patientId'],
        'patientIdNamespace': value['patientIdNamespace'],
        'clinicianName': value['clinicianName'],
        'clinicianId': value['clinicianId'],
        'procedureId': value['procedureId'],
        'procedureName': value['procedureName'],
        'pathwayName': value['pathwayName'],
        'pathwayUID': value['pathwayUID'],
        'pathwayId': value['pathwayId'],
        'promsName': value['promsName'],
        'stepId': value['stepId'],
        'dueDate': value['dueDate'],
        'completionDate': value['completionDate'],
    };
}

