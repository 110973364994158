/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubProcedureCode
 */
export interface SubProcedureCode {
    /**
     * 
     * @type {string}
     * @memberof SubProcedureCode
     */
    exportMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof SubProcedureCode
     */
    specificCode?: string;
}

/**
 * Check if a given object implements the SubProcedureCode interface.
 */
export function instanceOfSubProcedureCode(value: object): value is SubProcedureCode {
    return true;
}

export function SubProcedureCodeFromJSON(json: any): SubProcedureCode {
    return SubProcedureCodeFromJSONTyped(json, false);
}

export function SubProcedureCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubProcedureCode {
    if (json == null) {
        return json;
    }
    return {
        
        'exportMethod': json['exportMethod'] == null ? undefined : json['exportMethod'],
        'specificCode': json['specificCode'] == null ? undefined : json['specificCode'],
    };
}

export function SubProcedureCodeToJSON(value?: SubProcedureCode | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'exportMethod': value['exportMethod'],
        'specificCode': value['specificCode'],
    };
}

