/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAuthError,
  SearchRequest,
  TaskCompleteDto,
  TaskSearchResponse,
  TaskTypeCountDto,
} from '../models/index';
import {
    ApiAuthErrorFromJSON,
    ApiAuthErrorToJSON,
    SearchRequestFromJSON,
    SearchRequestToJSON,
    TaskCompleteDtoFromJSON,
    TaskCompleteDtoToJSON,
    TaskSearchResponseFromJSON,
    TaskSearchResponseToJSON,
    TaskTypeCountDtoFromJSON,
    TaskTypeCountDtoToJSON,
} from '../models/index';

export interface FindTasksRequest {
    searchRequest: SearchRequest;
}

export interface MarkTaskCompleteRequest {
    taskCompleteDto: TaskCompleteDto;
}

/**
 * 
 */
export class TasksEndpointApi extends runtime.BaseAPI {

    /**
     * Return list of tasks with search terms
     * tasks
     */
    async findTasksRaw(requestParameters: FindTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TaskSearchResponse>> {
        if (requestParameters['searchRequest'] == null) {
            throw new runtime.RequiredError(
                'searchRequest',
                'Required parameter "searchRequest" was null or undefined when calling findTasks().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/tasks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters['searchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskSearchResponseFromJSON(jsonValue));
    }

    /**
     * Return list of tasks with search terms
     * tasks
     */
    async findTasks(requestParameters: FindTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TaskSearchResponse> {
        const response = await this.findTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTaskTypeCountListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TaskTypeCountDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/tasks/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaskTypeCountDtoFromJSON));
    }

    /**
     */
    async getTaskTypeCountList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TaskTypeCountDto>> {
        const response = await this.getTaskTypeCountListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Mark a task as having been completed
     * complete
     */
    async markTaskCompleteRaw(requestParameters: MarkTaskCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['taskCompleteDto'] == null) {
            throw new runtime.RequiredError(
                'taskCompleteDto',
                'Required parameter "taskCompleteDto" was null or undefined when calling markTaskComplete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/tasks/complete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaskCompleteDtoToJSON(requestParameters['taskCompleteDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark a task as having been completed
     * complete
     */
    async markTaskComplete(requestParameters: MarkTaskCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markTaskCompleteRaw(requestParameters, initOverrides);
    }

}
