import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import Page from 'src/components/Page';

import { useKeycloak } from '@react-keycloak/web';

import { LoginActions } from 'src/comms';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${'/static/images/loginbg.png'})`,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = (props) => {
  const {
    dispatch,
    timeout
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const { keycloak, initialized } = useKeycloak();

  const onLogBackInClicked = () => {
    dispatch(LoginActions.logout());
    keycloak.login();
  };

  return (
    <Page
      className={classes.root}
      title={t('Login')}
    >
      {!timeout && initialized && !keycloak.authenticated ? keycloak.login() : null}
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Grid container justifyContent="center">
          <Box
            component="img"
            alt="OPEN OUTCOMES"
            src="/static/images/openOutcomeslogoWhite.png"
            style={{ paddingBottom: 50 }}
          />
        </Grid>
        {timeout
          ? (
            <Grid container justifyContent="center">
              <Card>
                <CardContent>
                  <Typography variant="h5">{t('logout inactivity')}</Typography>
                </CardContent>
                <CardActions
                  style={{
                    borderTop: '1px solid lightgray',
                    margin: 5,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Button color="primary" size="large" type="submit" variant="contained" onClick={() => onLogBackInClicked()}>
                    {t('log back in')}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )
          : null}
      </Box>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    hasError: state.login.hasError,
    errorMessage: state.login.errorMsg,
    timeout: state.login.timeOut
  };
};

LoginView.propTypes = {
  dispatch: PropTypes.func,
  timeout: PropTypes.bool,
};

const connectedLoginView = connect(mapStateToProps)(LoginView);
export default connectedLoginView;
