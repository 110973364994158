/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Results from an EHR Query
 * @export
 * @interface EhrQueryResultDto
 */
export interface EhrQueryResultDto {
    /**
     * The EHR query result rows, which will consist of a JSON Array of JSON objects containing each result row.
     * @type {string}
     * @memberof EhrQueryResultDto
     */
    rows?: string;
    /**
     * The EHR query result columns, which will consist of a JSON Array of JSON objects containing each result column.
     * @type {string}
     * @memberof EhrQueryResultDto
     */
    columns?: string;
    /**
     * The paging start value
     * @type {number}
     * @memberof EhrQueryResultDto
     */
    start?: number;
    /**
     * Paging page size
     * @type {number}
     * @memberof EhrQueryResultDto
     */
    pagesize?: number;
}

/**
 * Check if a given object implements the EhrQueryResultDto interface.
 */
export function instanceOfEhrQueryResultDto(value: object): value is EhrQueryResultDto {
    return true;
}

export function EhrQueryResultDtoFromJSON(json: any): EhrQueryResultDto {
    return EhrQueryResultDtoFromJSONTyped(json, false);
}

export function EhrQueryResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EhrQueryResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'rows': json['rows'] == null ? undefined : json['rows'],
        'columns': json['columns'] == null ? undefined : json['columns'],
        'start': json['start'] == null ? undefined : json['start'],
        'pagesize': json['pagesize'] == null ? undefined : json['pagesize'],
    };
}

export function EhrQueryResultDtoToJSON(value?: EhrQueryResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rows': value['rows'],
        'columns': value['columns'],
        'start': value['start'],
        'pagesize': value['pagesize'],
    };
}

