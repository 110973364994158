/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Number of occurrences of a task type
 * @export
 * @interface TaskTypeCountDto
 */
export interface TaskTypeCountDto {
    /**
     * Task type
     * @type {string}
     * @memberof TaskTypeCountDto
     */
    taskType: string;
    /**
     * Task class
     * @type {number}
     * @memberof TaskTypeCountDto
     */
    count: number;
}

/**
 * Check if a given object implements the TaskTypeCountDto interface.
 */
export function instanceOfTaskTypeCountDto(value: object): value is TaskTypeCountDto {
    if (!('taskType' in value) || value['taskType'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    return true;
}

export function TaskTypeCountDtoFromJSON(json: any): TaskTypeCountDto {
    return TaskTypeCountDtoFromJSONTyped(json, false);
}

export function TaskTypeCountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskTypeCountDto {
    if (json == null) {
        return json;
    }
    return {
        
        'taskType': json['taskType'],
        'count': json['count'],
    };
}

export function TaskTypeCountDtoToJSON(value?: TaskTypeCountDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'taskType': value['taskType'],
        'count': value['count'],
    };
}

