import React from 'react';
import {
  List,
  Collapse
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Users as UsersIcon,
  BarChart2 as ReportingIcon,
} from 'react-feather';

import { useTranslation } from 'react-i18next';

import NavItem from './NavItem';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ReportingSubListItem = ({
  className,
  reportingUrls,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
       <NavItem
                  key={"reports_key"}
                  title={t('navbar_Reports')}
                  icon={ReportingIcon}
                  onButtonClick={handleClick}
                  expandOpen={open}
                  showExpand={true}
                />
    <Collapse in={open} timeout="auto" unmountOnExit>
    <List component="div" disablePadding>
      { reportingUrls.map((url) => {
        return (
          <NavItem
          className={classes.nested}
          key={"reports_key_"+url.name}
          title={url.name}
          icon={ReportingIcon}
          href={"/app/reports/"+url.name}
        />
        );
      })}
    </List>
  </Collapse>
  </div>
  );
};


export default ReportingSubListItem;
