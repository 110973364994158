/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents the parameters for a query openEhr.
 * @export
 * @interface EhrQueryDto
 */
export interface EhrQueryDto {
    /**
     * The columns to select in the query, if no query template is supplied. Comma separators will be inserted between each item.
     * @type {Array<string>}
     * @memberof EhrQueryDto
     */
    selectColumns?: Array<string>;
    /**
     * The conditional clauses for the query, including AQL contains or where terms, if no parameter map is supplied
     * @type {Array<string>}
     * @memberof EhrQueryDto
     */
    whereClauses?: Array<string>;
    /**
     * The primaryEntityName of the query template, if one exists, which has previously been registered with the server for the app to request a set of query terms
     * @type {string}
     * @memberof EhrQueryDto
     */
    queryTemplate?: string;
    /**
     * The user for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof EhrQueryDto
     */
    userId?: string;
    /**
     * The namespace for the userId for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof EhrQueryDto
     */
    userIdNamespace?: string;
    /**
     * The paging start value
     * @type {number}
     * @memberof EhrQueryDto
     */
    start?: number;
    /**
     * Paging page size
     * @type {number}
     * @memberof EhrQueryDto
     */
    pagesize?: number;
    /**
     * Start range for time bounded queries
     * @type {number}
     * @memberof EhrQueryDto
     */
    startDateTime?: number;
    /**
     * End range for time bounded queries
     * @type {number}
     * @memberof EhrQueryDto
     */
    endDateTime?: number;
}

/**
 * Check if a given object implements the EhrQueryDto interface.
 */
export function instanceOfEhrQueryDto(value: object): value is EhrQueryDto {
    return true;
}

export function EhrQueryDtoFromJSON(json: any): EhrQueryDto {
    return EhrQueryDtoFromJSONTyped(json, false);
}

export function EhrQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EhrQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'selectColumns': json['selectColumns'] == null ? undefined : json['selectColumns'],
        'whereClauses': json['whereClauses'] == null ? undefined : json['whereClauses'],
        'queryTemplate': json['queryTemplate'] == null ? undefined : json['queryTemplate'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'userIdNamespace': json['userIdNamespace'] == null ? undefined : json['userIdNamespace'],
        'start': json['start'] == null ? undefined : json['start'],
        'pagesize': json['pagesize'] == null ? undefined : json['pagesize'],
        'startDateTime': json['startDateTime'] == null ? undefined : json['startDateTime'],
        'endDateTime': json['endDateTime'] == null ? undefined : json['endDateTime'],
    };
}

export function EhrQueryDtoToJSON(value?: EhrQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'selectColumns': value['selectColumns'],
        'whereClauses': value['whereClauses'],
        'queryTemplate': value['queryTemplate'],
        'userId': value['userId'],
        'userIdNamespace': value['userIdNamespace'],
        'start': value['start'],
        'pagesize': value['pagesize'],
        'startDateTime': value['startDateTime'],
        'endDateTime': value['endDateTime'],
    };
}

