import {
  PROMS_DEV_IS_LOADING,
  PROMS_DEV_FETCH_OPT_LIST,
  PROMS_DEV_UPLOAD_TEMPLATE,
  PROMS_DEV_HAS_ERROR,
  PROMS_DEV_SUCCESS_CLEAR,
  LOGOUT
} from '../actions/Actions';

const initialState = {
  optTemplateList: {
    proms: {
      list: [],
      currentTotal: 0
    },
    procedure: {
      list: [],
      currentTotal: 0
    }
  },
  hasError: false,
  isLoading: false,
  errorMsg: null,
  templateUpdateSuccess: false,
  templateUpdateMessage: null,
};

const templatesReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case PROMS_DEV_IS_LOADING: {
      console.log('proms dev loading', action);

      return { ...state, isLoading: action.isLoading, };
    }
    case PROMS_DEV_FETCH_OPT_LIST: {
      console.log('proms dev fetch opt list', action);

      const { templateType } = action;
      const { optTemplateList } = state;

      if (action.pageStart > 0) {
        const updatedList = [...optTemplateList[templateType].list, ...action.list];

        const updatedOptTemplateList = {
          ...optTemplateList[templateType],
          list: updatedList,
          currentTotal: updatedList.length,
          isLoading: false
        };

        optTemplateList[templateType] = updatedOptTemplateList;
      } else {
        console.log('**** LIST');
        const { list } = action;
        console.log(list);

        const updatedOptTemplateList = {
          ...state.optTemplateList[templateType],
          list,
          currentTotal: list.length,
          total: action.total,
          searchTerm: action.searchTerm,
          orderTerm: action.orderTerm,
          orderDirection: action.orderDirection,
          isLoading: false,
          fetchTime: action.fetchTime
        };

        optTemplateList[templateType] = updatedOptTemplateList;
      }

      console.log(optTemplateList);

      return { ...state, optTemplateList };
    }
    case PROMS_DEV_UPLOAD_TEMPLATE: {
      console.log('proms dev upload opt template', action);

      const { templateType } = action;
      const { optTemplateList } = state;

      console.log(optTemplateList[templateType]);
      console.log(optTemplateList[templateType].list);

      const list = [...optTemplateList[templateType].list, action.payload];

      const updatedTemplateList = {
        ...state.optTemplateList[templateType],
        list,
        currentTotal: list.length,
        total: optTemplateList[templateType].total + 1,
        isLoading: false
      };

      optTemplateList[templateType] = updatedTemplateList;

      console.log(optTemplateList);

      return {
        ...state,
        optTemplateList,
        templateUpdateSuccess: true,
        templateUpdateMessage: 'Opt template successfully uploaded'
      };
    }
    case PROMS_DEV_HAS_ERROR: {
      console.log('proms dev has error', action);

      return {
        ...state,
        hasError: action.hasError,
        errorMsg: action.errorMsg,
        templateUpdateSuccess: false,
        templateUpdateMessage: null
      };
    }
    case PROMS_DEV_SUCCESS_CLEAR: {
      console.log('proms dev clear success', action);

      return {
        ...state,
        templateUpdateSuccess: false,
        templateUpdateMessage: null
      };
    }
    case LOGOUT:
    default:
      return state;
  }
};

export default templatesReducer;
