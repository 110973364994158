/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAuthError,
  ApiValidationErrors,
  PathwayCompositionDto,
  PathwayDto,
  PathwayProcedureDto,
  PathwayResponseDto,
  PathwayStepDto,
  RescheduleNotificationRequest,
} from '../models/index';
import {
    ApiAuthErrorFromJSON,
    ApiAuthErrorToJSON,
    ApiValidationErrorsFromJSON,
    ApiValidationErrorsToJSON,
    PathwayCompositionDtoFromJSON,
    PathwayCompositionDtoToJSON,
    PathwayDtoFromJSON,
    PathwayDtoToJSON,
    PathwayProcedureDtoFromJSON,
    PathwayProcedureDtoToJSON,
    PathwayResponseDtoFromJSON,
    PathwayResponseDtoToJSON,
    PathwayStepDtoFromJSON,
    PathwayStepDtoToJSON,
    RescheduleNotificationRequestFromJSON,
    RescheduleNotificationRequestToJSON,
} from '../models/index';

export interface CreateComposition1Request {
    pathwayCompositionDto: PathwayCompositionDto;
}

export interface GetAllPathwayCompositionsRequest {
    userId: string;
    userIdNamespace: string;
}

export interface GetCompositionRequest {
    compositionUid: string;
    userId: string;
    userNamespace: string;
}

export interface RemoveCompositionRequest {
    pathwayCompositionDto: PathwayCompositionDto;
}

export interface RemovePathwayProcedureRequest {
    pathwayProcedureDto: PathwayProcedureDto;
}

export interface RemovePathwayStepRequest {
    pathwayStepDto: PathwayStepDto;
}

export interface RescheduleNotificationsRequest {
    rescheduleNotificationRequest: RescheduleNotificationRequest;
}

export interface UpdatePathwayProcedureRequest {
    pathwayProcedureDto: PathwayProcedureDto;
}

export interface UpdatePathwayStepsRequest {
    pathwayStepDto: PathwayStepDto;
}

/**
 * 
 */
export class PathwayEndpointApi extends runtime.BaseAPI {

    /**
     * Create a new pathway on a patient record
     * create pathway composition
     */
    async createComposition1Raw(requestParameters: CreateComposition1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PathwayResponseDto>> {
        if (requestParameters['pathwayCompositionDto'] == null) {
            throw new runtime.RequiredError(
                'pathwayCompositionDto',
                'Required parameter "pathwayCompositionDto" was null or undefined when calling createComposition1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient/ehr/pathway/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PathwayCompositionDtoToJSON(requestParameters['pathwayCompositionDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PathwayResponseDtoFromJSON(jsonValue));
    }

    /**
     * Create a new pathway on a patient record
     * create pathway composition
     */
    async createComposition1(requestParameters: CreateComposition1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PathwayResponseDto> {
        const response = await this.createComposition1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all pathway compositions for a patient record
     * Fetch pathway compositions
     */
    async getAllPathwayCompositionsRaw(requestParameters: GetAllPathwayCompositionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PathwayDto>>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getAllPathwayCompositions().'
            );
        }

        if (requestParameters['userIdNamespace'] == null) {
            throw new runtime.RequiredError(
                'userIdNamespace',
                'Required parameter "userIdNamespace" was null or undefined when calling getAllPathwayCompositions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['userId'] != null) {
            queryParameters['userId'] = requestParameters['userId'];
        }

        if (requestParameters['userIdNamespace'] != null) {
            queryParameters['userIdNamespace'] = requestParameters['userIdNamespace'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient/ehr/pathway/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PathwayDtoFromJSON));
    }

    /**
     * Get all pathway compositions for a patient record
     * Fetch pathway compositions
     */
    async getAllPathwayCompositions(requestParameters: GetAllPathwayCompositionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PathwayDto>> {
        const response = await this.getAllPathwayCompositionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a pathway composition version on a patient record
     * Fetch pathway composition
     */
    async getCompositionRaw(requestParameters: GetCompositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PathwayResponseDto>> {
        if (requestParameters['compositionUid'] == null) {
            throw new runtime.RequiredError(
                'compositionUid',
                'Required parameter "compositionUid" was null or undefined when calling getComposition().'
            );
        }

        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getComposition().'
            );
        }

        if (requestParameters['userNamespace'] == null) {
            throw new runtime.RequiredError(
                'userNamespace',
                'Required parameter "userNamespace" was null or undefined when calling getComposition().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['compositionUid'] != null) {
            queryParameters['compositionUid'] = requestParameters['compositionUid'];
        }

        if (requestParameters['userId'] != null) {
            queryParameters['userId'] = requestParameters['userId'];
        }

        if (requestParameters['userNamespace'] != null) {
            queryParameters['userNamespace'] = requestParameters['userNamespace'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient/ehr/pathway/composition`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PathwayResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get a pathway composition version on a patient record
     * Fetch pathway composition
     */
    async getComposition(requestParameters: GetCompositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PathwayResponseDto> {
        const response = await this.getCompositionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove a  pathway on a patient record
     * Remove pathway composition
     */
    async removeCompositionRaw(requestParameters: RemoveCompositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['pathwayCompositionDto'] == null) {
            throw new runtime.RequiredError(
                'pathwayCompositionDto',
                'Required parameter "pathwayCompositionDto" was null or undefined when calling removeComposition().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient/ehr/pathway/remove`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PathwayCompositionDtoToJSON(requestParameters['pathwayCompositionDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a  pathway on a patient record
     * Remove pathway composition
     */
    async removeComposition(requestParameters: RemoveCompositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeCompositionRaw(requestParameters, initOverrides);
    }

    /**
     * Remove a procedure step
     * Remove a procedure on a pathway composition
     */
    async removePathwayProcedureRaw(requestParameters: RemovePathwayProcedureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PathwayResponseDto>> {
        if (requestParameters['pathwayProcedureDto'] == null) {
            throw new runtime.RequiredError(
                'pathwayProcedureDto',
                'Required parameter "pathwayProcedureDto" was null or undefined when calling removePathwayProcedure().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient/ehr/pathway/remove/procedure`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PathwayProcedureDtoToJSON(requestParameters['pathwayProcedureDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PathwayResponseDtoFromJSON(jsonValue));
    }

    /**
     * Remove a procedure step
     * Remove a procedure on a pathway composition
     */
    async removePathwayProcedure(requestParameters: RemovePathwayProcedureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PathwayResponseDto> {
        const response = await this.removePathwayProcedureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove a pathway step
     * Remove a step on a pathway composition
     */
    async removePathwayStepRaw(requestParameters: RemovePathwayStepRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PathwayResponseDto>> {
        if (requestParameters['pathwayStepDto'] == null) {
            throw new runtime.RequiredError(
                'pathwayStepDto',
                'Required parameter "pathwayStepDto" was null or undefined when calling removePathwayStep().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient/ehr/pathway/remove/step`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PathwayStepDtoToJSON(requestParameters['pathwayStepDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PathwayResponseDtoFromJSON(jsonValue));
    }

    /**
     * Remove a pathway step
     * Remove a step on a pathway composition
     */
    async removePathwayStep(requestParameters: RemovePathwayStepRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PathwayResponseDto> {
        const response = await this.removePathwayStepRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reschedule notifications for a pathway\'s PROMs
     * Reschedule notifications for a pathway\'s PROMs
     */
    async rescheduleNotificationsRaw(requestParameters: RescheduleNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PathwayDto>>> {
        if (requestParameters['rescheduleNotificationRequest'] == null) {
            throw new runtime.RequiredError(
                'rescheduleNotificationRequest',
                'Required parameter "rescheduleNotificationRequest" was null or undefined when calling rescheduleNotifications().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient/ehr/pathway/rescheduleNotifications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RescheduleNotificationRequestToJSON(requestParameters['rescheduleNotificationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PathwayDtoFromJSON));
    }

    /**
     * Reschedule notifications for a pathway\'s PROMs
     * Reschedule notifications for a pathway\'s PROMs
     */
    async rescheduleNotifications(requestParameters: RescheduleNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PathwayDto>> {
        const response = await this.rescheduleNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add or update a procedure step
     * Add or update a procedure on a pathway composition
     */
    async updatePathwayProcedureRaw(requestParameters: UpdatePathwayProcedureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PathwayResponseDto>> {
        if (requestParameters['pathwayProcedureDto'] == null) {
            throw new runtime.RequiredError(
                'pathwayProcedureDto',
                'Required parameter "pathwayProcedureDto" was null or undefined when calling updatePathwayProcedure().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient/ehr/pathway/procedure`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PathwayProcedureDtoToJSON(requestParameters['pathwayProcedureDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PathwayResponseDtoFromJSON(jsonValue));
    }

    /**
     * Add or update a procedure step
     * Add or update a procedure on a pathway composition
     */
    async updatePathwayProcedure(requestParameters: UpdatePathwayProcedureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PathwayResponseDto> {
        const response = await this.updatePathwayProcedureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add or update a pathway step
     * Add or update a step on a pathway composition
     */
    async updatePathwayStepsRaw(requestParameters: UpdatePathwayStepsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PathwayResponseDto>> {
        if (requestParameters['pathwayStepDto'] == null) {
            throw new runtime.RequiredError(
                'pathwayStepDto',
                'Required parameter "pathwayStepDto" was null or undefined when calling updatePathwaySteps().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/patient/ehr/pathway/step`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PathwayStepDtoToJSON(requestParameters['pathwayStepDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PathwayResponseDtoFromJSON(jsonValue));
    }

    /**
     * Add or update a pathway step
     * Add or update a step on a pathway composition
     */
    async updatePathwaySteps(requestParameters: UpdatePathwayStepsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PathwayResponseDto> {
        const response = await this.updatePathwayStepsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
