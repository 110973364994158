import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect, Suspense, } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import { GetRoutes } from 'src/routes';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  LoginActions, ServerConfigActions, AuditActions, HCPDataActions, ConfigActions
} from 'src/comms';
import LoadingView from 'src/components/LoadingView';

import { useKeycloak } from "@react-keycloak/web";

import { useRollbar } from '@rollbar/react';
import { GlobalDebug } from "src/utils/DisableLogging";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const App = (props) => {
  const { dispatch, permissions } = props;

  useEffect(() => {
    dispatch(ServerConfigActions.updateServerConfig(window._env_.REACT_APP_API_SERVER_URL));
  }, []);

  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;
  const rollbar = useRollbar();
  // Assign rollbar to a window object instance for access outside React
  window.rollbar = rollbar;

    /**
   * @REMOVE_CONSOLES
   * // remove the working of console logs
   * // remove any accidental use of console logs
   */
    useEffect(() => {
      // Remove console logs if in production
      (process.env.NODE_ENV === "production" ||
       process.env.REACT_APP_ENV === "STAGING") &&
        GlobalDebug(false);
    }, []);

  const getConfig = (newLogin) => {
    console.log('PERMISSIONS');
    dispatch(HCPDataActions.getHcpProfile());
    dispatch(ConfigActions.fetchRolePermissionsConfig());
    dispatch(ConfigActions.getPromsEmailConfig());
    dispatch(ConfigActions.getPromsPrintConfig());
    dispatch(ConfigActions.getEnabledLanguages());
    dispatch(ConfigActions.fetchReportingUrls());

    if (newLogin) {
      dispatch(AuditActions.userLoggedIn());
    }
  };

  const hasPermissions = () => {
    return permissions.length > 0;
  };

  useEffect(() => {
    console.log('*** UseEffect');
    if (isLoggedIn && keycloak.token) {
      console.log('*** isLoggedIn && keycloak token');
      const payload = {
        keycloak
      };

      keycloak.onAuthLogout = () => {
        console.log('*** onAuthLogout');
        dispatch(LoginActions.timeOutLogout());
        navigate('/app/dashboard', { replace: true });
      };

      // Absence of permissions indicating new login.
      const newLogin = !hasPermissions();

      // Refresh permissions
      dispatch(LoginActions.getPermissions(payload, () => getConfig(newLogin)));
    }
  }, [isLoggedIn]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Suspense fallback={<LoadingView isLoading />}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            {GetRoutes(isLoggedIn && hasPermissions(), permissions)}
          </LocalizationProvider>
        </Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

function mapStateToProps(state) {
  return {
    loginState: state.login,
    permissions: state.login.permissions
  };
}

App.propTypes = {
  dispatch: PropTypes.func,
  permissions: PropTypes.array
};

const connectedApp = connect(mapStateToProps)(App);
export default connectedApp;
