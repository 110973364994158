import { HcpApi } from 'promsApi';
import {
  UPDATE_HCP_PROFILE,
  HCP_DATA_ERROR,
  HCP_DATA_IS_LOADING,
  HCP_DATA_SUCCESS_CLEAR
} from './Actions';

import getConfiguation from './Configuration';
import onRequest from './Request';

const hasError = (bool, errorMsg) => {
  return {
    type: HCP_DATA_ERROR,
    hasError: bool,
    errorMsg
  };
};

const isLoading = (bool) => {
  return {
    type: HCP_DATA_IS_LOADING,
    isLoading: bool
  };
};

const updateHcpProfile = (payload) => {
  return {
    type: UPDATE_HCP_PROFILE,
    payload
  };
};

const saveHcpProfileSuccess = (payload, profile) => {
  return {
    type: UPDATE_HCP_PROFILE,
    payload: profile,
    success: true
  };
};

const clearHcpUpdateSuccess = () => {
  return {
    type: HCP_DATA_SUCCESS_CLEAR
  };
};

const getHcpProfile = () => {
  console.log('Fetching hcp profile');

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const hcpApi = new HcpApi(config);
    const request = hcpApi.getProfile1();
    onRequest(request, dispatch, updateHcpProfile, hasError, isLoading, null, 'Error fetching user profile');
  };
};

const saveHcpProfile = (profile) => {
  console.log('Saving hcp profile');

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const hcpApi = new HcpApi(config);
    const request = hcpApi.createUpdateProfile({ hcpDto: profile });
    onRequest(request, dispatch, saveHcpProfileSuccess, hasError, isLoading, profile, 'Error saving user profile');
  };
};

export default {
  getHcpProfile,
  saveHcpProfile,
  hasError,
  clearHcpUpdateSuccess
};
