/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAuthError,
  AuditSearchResponse,
  ExternalAuditDto,
  SearchRequest,
} from '../models/index';
import {
    ApiAuthErrorFromJSON,
    ApiAuthErrorToJSON,
    AuditSearchResponseFromJSON,
    AuditSearchResponseToJSON,
    ExternalAuditDtoFromJSON,
    ExternalAuditDtoToJSON,
    SearchRequestFromJSON,
    SearchRequestToJSON,
} from '../models/index';

export interface CreateAuditEntryRequest {
    externalAuditDto: ExternalAuditDto;
}

export interface FindAuditEntriesRequest {
    searchRequest: SearchRequest;
}

/**
 * 
 */
export class AuditEndpointApi extends runtime.BaseAPI {

    /**
     * Add audit entry
     * audit
     */
    async createAuditEntryRaw(requestParameters: CreateAuditEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['externalAuditDto'] == null) {
            throw new runtime.RequiredError(
                'externalAuditDto',
                'Required parameter "externalAuditDto" was null or undefined when calling createAuditEntry().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/audit/entry`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalAuditDtoToJSON(requestParameters['externalAuditDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add audit entry
     * audit
     */
    async createAuditEntry(requestParameters: CreateAuditEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createAuditEntryRaw(requestParameters, initOverrides);
    }

    /**
     * Return list of audit entries with search terms
     * audit
     */
    async findAuditEntriesRaw(requestParameters: FindAuditEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuditSearchResponse>> {
        if (requestParameters['searchRequest'] == null) {
            throw new runtime.RequiredError(
                'searchRequest',
                'Required parameter "searchRequest" was null or undefined when calling findAuditEntries().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/audit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters['searchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuditSearchResponseFromJSON(jsonValue));
    }

    /**
     * Return list of audit entries with search terms
     * audit
     */
    async findAuditEntries(requestParameters: FindAuditEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuditSearchResponse> {
        const response = await this.findAuditEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
