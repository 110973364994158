/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents an audit entry
 * @export
 * @interface AuditDto
 */
export interface AuditDto {
    /**
     * Id
     * @type {string}
     * @memberof AuditDto
     */
    id: string;
    /**
     * Type
     * @type {string}
     * @memberof AuditDto
     */
    type: string;
    /**
     * Action
     * @type {string}
     * @memberof AuditDto
     */
    action: string;
    /**
     * Attributes
     * @type {{ [key: string]: string; }}
     * @memberof AuditDto
     */
    attributes: { [key: string]: string; };
    /**
     * Name of user
     * @type {string}
     * @memberof AuditDto
     */
    userName: string;
    /**
     * Id of user
     * @type {string}
     * @memberof AuditDto
     */
    userId: string;
    /**
     * Date/time of entry
     * @type {string}
     * @memberof AuditDto
     */
    dateTime: string;
}

/**
 * Check if a given object implements the AuditDto interface.
 */
export function instanceOfAuditDto(value: object): value is AuditDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('action' in value) || value['action'] === undefined) return false;
    if (!('attributes' in value) || value['attributes'] === undefined) return false;
    if (!('userName' in value) || value['userName'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('dateTime' in value) || value['dateTime'] === undefined) return false;
    return true;
}

export function AuditDtoFromJSON(json: any): AuditDto {
    return AuditDtoFromJSONTyped(json, false);
}

export function AuditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'action': json['action'],
        'attributes': json['attributes'],
        'userName': json['userName'],
        'userId': json['userId'],
        'dateTime': json['dateTime'],
    };
}

export function AuditDtoToJSON(value?: AuditDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'type': value['type'],
        'action': value['action'],
        'attributes': value['attributes'],
        'userName': value['userName'],
        'userId': value['userId'],
        'dateTime': value['dateTime'],
    };
}

