/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {string}
     * @memberof SortObject
     */
    direction?: string;
    /**
     * 
     * @type {string}
     * @memberof SortObject
     */
    nullHandling?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    ascending?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SortObject
     */
    property?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    ignoreCase?: boolean;
}

/**
 * Check if a given object implements the SortObject interface.
 */
export function instanceOfSortObject(value: object): value is SortObject {
    return true;
}

export function SortObjectFromJSON(json: any): SortObject {
    return SortObjectFromJSONTyped(json, false);
}

export function SortObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortObject {
    if (json == null) {
        return json;
    }
    return {
        
        'direction': json['direction'] == null ? undefined : json['direction'],
        'nullHandling': json['nullHandling'] == null ? undefined : json['nullHandling'],
        'ascending': json['ascending'] == null ? undefined : json['ascending'],
        'property': json['property'] == null ? undefined : json['property'],
        'ignoreCase': json['ignoreCase'] == null ? undefined : json['ignoreCase'],
    };
}

export function SortObjectToJSON(value?: SortObject | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'direction': value['direction'],
        'nullHandling': value['nullHandling'],
        'ascending': value['ascending'],
        'property': value['property'],
        'ignoreCase': value['ignoreCase'],
    };
}

