/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a request to resend a patients proms notification request
 * @export
 * @interface SendPromsNotificationDto
 */
export interface SendPromsNotificationDto {
    /**
     * Notification type
     * @type {string}
     * @memberof SendPromsNotificationDto
     */
    notificationType: SendPromsNotificationDtoNotificationTypeEnum;
    /**
     * Title of proms event
     * @type {string}
     * @memberof SendPromsNotificationDto
     */
    title: string;
    /**
     * Pathway id
     * @type {string}
     * @memberof SendPromsNotificationDto
     */
    pathwayId: string;
    /**
     * Pathway uuid
     * @type {string}
     * @memberof SendPromsNotificationDto
     */
    pathwayUid: string;
    /**
     * Pathway name
     * @type {string}
     * @memberof SendPromsNotificationDto
     */
    pathwayName: string;
    /**
     * The step id
     * @type {string}
     * @memberof SendPromsNotificationDto
     */
    stepId?: string;
    /**
     * The user for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof SendPromsNotificationDto
     */
    userId: string;
    /**
     * The namespace for the userId for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof SendPromsNotificationDto
     */
    userIdNamespace: string;
}


/**
 * @export
 */
export const SendPromsNotificationDtoNotificationTypeEnum = {
    Long: 'Long',
    Short: 'Short'
} as const;
export type SendPromsNotificationDtoNotificationTypeEnum = typeof SendPromsNotificationDtoNotificationTypeEnum[keyof typeof SendPromsNotificationDtoNotificationTypeEnum];


/**
 * Check if a given object implements the SendPromsNotificationDto interface.
 */
export function instanceOfSendPromsNotificationDto(value: object): value is SendPromsNotificationDto {
    if (!('notificationType' in value) || value['notificationType'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('pathwayId' in value) || value['pathwayId'] === undefined) return false;
    if (!('pathwayUid' in value) || value['pathwayUid'] === undefined) return false;
    if (!('pathwayName' in value) || value['pathwayName'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('userIdNamespace' in value) || value['userIdNamespace'] === undefined) return false;
    return true;
}

export function SendPromsNotificationDtoFromJSON(json: any): SendPromsNotificationDto {
    return SendPromsNotificationDtoFromJSONTyped(json, false);
}

export function SendPromsNotificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendPromsNotificationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'notificationType': json['notificationType'],
        'title': json['title'],
        'pathwayId': json['pathwayId'],
        'pathwayUid': json['pathwayUid'],
        'pathwayName': json['pathwayName'],
        'stepId': json['stepId'] == null ? undefined : json['stepId'],
        'userId': json['userId'],
        'userIdNamespace': json['userIdNamespace'],
    };
}

export function SendPromsNotificationDtoToJSON(value?: SendPromsNotificationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notificationType': value['notificationType'],
        'title': value['title'],
        'pathwayId': value['pathwayId'],
        'pathwayUid': value['pathwayUid'],
        'pathwayName': value['pathwayName'],
        'stepId': value['stepId'],
        'userId': value['userId'],
        'userIdNamespace': value['userIdNamespace'],
    };
}

