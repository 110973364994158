/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAuthError,
  ApiValidationErrors,
  ProcedureTemplateDto,
  ProcedureTemplateSearchResponse,
  SearchRequest,
  SubProcedureTemplateDto,
  UiTemplateDto,
  UiTemplateSearchResponse,
} from '../models/index';
import {
    ApiAuthErrorFromJSON,
    ApiAuthErrorToJSON,
    ApiValidationErrorsFromJSON,
    ApiValidationErrorsToJSON,
    ProcedureTemplateDtoFromJSON,
    ProcedureTemplateDtoToJSON,
    ProcedureTemplateSearchResponseFromJSON,
    ProcedureTemplateSearchResponseToJSON,
    SearchRequestFromJSON,
    SearchRequestToJSON,
    SubProcedureTemplateDtoFromJSON,
    SubProcedureTemplateDtoToJSON,
    UiTemplateDtoFromJSON,
    UiTemplateDtoToJSON,
    UiTemplateSearchResponseFromJSON,
    UiTemplateSearchResponseToJSON,
} from '../models/index';

export interface AddProcedureRequest {
    procedureTemplateDto: ProcedureTemplateDto;
}

export interface AddSubProcedureRequest {
    subProcedureTemplateDto: SubProcedureTemplateDto;
}

export interface DeleteProcedureRequest {
    procedureId: string;
}

export interface DeleteSubProcedureRequest {
    subProcedureId: string;
    procedureId: string;
}

export interface GetMultipleProceduresByIdRequest {
    ids: Array<string>;
}

export interface GetProcedureListRequest {
    searchRequest: SearchRequest;
}

export interface GetProcedureUiTemplateListRequest {
    searchRequest: SearchRequest;
}

export interface GetUiTemplateJson1Request {
    ids: Array<string>;
}

export interface ImportUiTemplate1Request {
    uiTemplateDto: UiTemplateDto;
}

/**
 * 
 */
export class ProceduresEndpointApi extends runtime.BaseAPI {

    /**
     */
    async addProcedureRaw(requestParameters: AddProcedureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcedureTemplateDto>> {
        if (requestParameters['procedureTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'procedureTemplateDto',
                'Required parameter "procedureTemplateDto" was null or undefined when calling addProcedure().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/procedure`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProcedureTemplateDtoToJSON(requestParameters['procedureTemplateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcedureTemplateDtoFromJSON(jsonValue));
    }

    /**
     */
    async addProcedure(requestParameters: AddProcedureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcedureTemplateDto> {
        const response = await this.addProcedureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async addSubProcedureRaw(requestParameters: AddSubProcedureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcedureTemplateDto>> {
        if (requestParameters['subProcedureTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'subProcedureTemplateDto',
                'Required parameter "subProcedureTemplateDto" was null or undefined when calling addSubProcedure().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/procedure/subProcedure`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubProcedureTemplateDtoToJSON(requestParameters['subProcedureTemplateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcedureTemplateDtoFromJSON(jsonValue));
    }

    /**
     */
    async addSubProcedure(requestParameters: AddSubProcedureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcedureTemplateDto> {
        const response = await this.addSubProcedureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteProcedureRaw(requestParameters: DeleteProcedureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['procedureId'] == null) {
            throw new runtime.RequiredError(
                'procedureId',
                'Required parameter "procedureId" was null or undefined when calling deleteProcedure().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['procedureId'] != null) {
            queryParameters['procedureId'] = requestParameters['procedureId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/procedure/procedure`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProcedure(requestParameters: DeleteProcedureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProcedureRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteSubProcedureRaw(requestParameters: DeleteSubProcedureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcedureTemplateDto>> {
        if (requestParameters['subProcedureId'] == null) {
            throw new runtime.RequiredError(
                'subProcedureId',
                'Required parameter "subProcedureId" was null or undefined when calling deleteSubProcedure().'
            );
        }

        if (requestParameters['procedureId'] == null) {
            throw new runtime.RequiredError(
                'procedureId',
                'Required parameter "procedureId" was null or undefined when calling deleteSubProcedure().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['subProcedureId'] != null) {
            queryParameters['subProcedureId'] = requestParameters['subProcedureId'];
        }

        if (requestParameters['procedureId'] != null) {
            queryParameters['procedureId'] = requestParameters['procedureId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/procedure/subProcedure`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcedureTemplateDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteSubProcedure(requestParameters: DeleteSubProcedureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcedureTemplateDto> {
        const response = await this.deleteSubProcedureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllProcedureUiTemplateJsonRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UiTemplateDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/procedure/template/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UiTemplateDtoFromJSON));
    }

    /**
     */
    async getAllProcedureUiTemplateJson(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UiTemplateDto>> {
        const response = await this.getAllProcedureUiTemplateJsonRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getMultipleProceduresByIdRaw(requestParameters: GetMultipleProceduresByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProcedureTemplateDto>>> {
        if (requestParameters['ids'] == null) {
            throw new runtime.RequiredError(
                'ids',
                'Required parameter "ids" was null or undefined when calling getMultipleProceduresById().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ids'] != null) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/procedure/procedure`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProcedureTemplateDtoFromJSON));
    }

    /**
     */
    async getMultipleProceduresById(requestParameters: GetMultipleProceduresByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProcedureTemplateDto>> {
        const response = await this.getMultipleProceduresByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProcedureListRaw(requestParameters: GetProcedureListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProcedureTemplateSearchResponse>> {
        if (requestParameters['searchRequest'] == null) {
            throw new runtime.RequiredError(
                'searchRequest',
                'Required parameter "searchRequest" was null or undefined when calling getProcedureList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/procedure/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters['searchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcedureTemplateSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async getProcedureList(requestParameters: GetProcedureListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProcedureTemplateSearchResponse> {
        const response = await this.getProcedureListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProcedureUiTemplateListRaw(requestParameters: GetProcedureUiTemplateListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UiTemplateSearchResponse>> {
        if (requestParameters['searchRequest'] == null) {
            throw new runtime.RequiredError(
                'searchRequest',
                'Required parameter "searchRequest" was null or undefined when calling getProcedureUiTemplateList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/procedure/template/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters['searchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UiTemplateSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async getProcedureUiTemplateList(requestParameters: GetProcedureUiTemplateListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UiTemplateSearchResponse> {
        const response = await this.getProcedureUiTemplateListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUiTemplateJson1Raw(requestParameters: GetUiTemplateJson1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UiTemplateDto>>> {
        if (requestParameters['ids'] == null) {
            throw new runtime.RequiredError(
                'ids',
                'Required parameter "ids" was null or undefined when calling getUiTemplateJson1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ids'] != null) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/procedure/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UiTemplateDtoFromJSON));
    }

    /**
     */
    async getUiTemplateJson1(requestParameters: GetUiTemplateJson1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UiTemplateDto>> {
        const response = await this.getUiTemplateJson1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async importUiTemplate1Raw(requestParameters: ImportUiTemplate1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uiTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'uiTemplateDto',
                'Required parameter "uiTemplateDto" was null or undefined when calling importUiTemplate1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/procedure/template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UiTemplateDtoToJSON(requestParameters['uiTemplateDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async importUiTemplate1(requestParameters: ImportUiTemplate1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.importUiTemplate1Raw(requestParameters, initOverrides);
    }

}
