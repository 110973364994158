/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a healthcare professional who is associated with a User in the api.
 * @export
 * @interface HcpSummaryDto
 */
export interface HcpSummaryDto {
    /**
     * NHS id
     * @type {string}
     * @memberof HcpSummaryDto
     */
    nhsId: string;
    /**
     * HCP title
     * @type {string}
     * @memberof HcpSummaryDto
     */
    title?: string;
    /**
     * HCP first names
     * @type {string}
     * @memberof HcpSummaryDto
     */
    firstNames: string;
    /**
     * HCP last primaryEntityName
     * @type {string}
     * @memberof HcpSummaryDto
     */
    lastName: string;
    /**
     * HCP Job title
     * @type {string}
     * @memberof HcpSummaryDto
     */
    jobTitle: string;
    /**
     * HCP department, trust, or location
     * @type {string}
     * @memberof HcpSummaryDto
     */
    location: string;
}

/**
 * Check if a given object implements the HcpSummaryDto interface.
 */
export function instanceOfHcpSummaryDto(value: object): value is HcpSummaryDto {
    if (!('nhsId' in value) || value['nhsId'] === undefined) return false;
    if (!('firstNames' in value) || value['firstNames'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('jobTitle' in value) || value['jobTitle'] === undefined) return false;
    if (!('location' in value) || value['location'] === undefined) return false;
    return true;
}

export function HcpSummaryDtoFromJSON(json: any): HcpSummaryDto {
    return HcpSummaryDtoFromJSONTyped(json, false);
}

export function HcpSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HcpSummaryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'nhsId': json['nhsId'],
        'title': json['title'] == null ? undefined : json['title'],
        'firstNames': json['firstNames'],
        'lastName': json['lastName'],
        'jobTitle': json['jobTitle'],
        'location': json['location'],
    };
}

export function HcpSummaryDtoToJSON(value?: HcpSummaryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'nhsId': value['nhsId'],
        'title': value['title'],
        'firstNames': value['firstNames'],
        'lastName': value['lastName'],
        'jobTitle': value['jobTitle'],
        'location': value['location'],
    };
}

