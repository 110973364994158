import { ProceduresEndpointApi } from 'promsApi';
import {
  ADD_PROCEDURE_TEMPLATE,
  PROCEDURE_TEMPLATE_ERROR,
  PROCEDURE_TEMPLATE_LOADING,
  PROCEDURE_TEMPLATE_SUCCESS_CLEAR,
  PROCEDURE_TEMPLATE_FETCH,
  PROCEDURE_TEMPLATE_FETCH_LIST,
  ADD_PROCEDURE,
  ADD_SUBPROCEDURE,
  PROCEDURE_FETCH_ALL,
  PROCEDURE_FETCH,
  DELETE_PROCEDURE,
  DELETE_SUBPROCEDURE
} from './Actions';

import getConfiguation from './Configuration';
import onRequest from './Request';

const clearError = () => {
  return {
    type: PROCEDURE_TEMPLATE_ERROR,
    hasError: false
  };
};

const onError = (bool, errorMsg) => {
  return {
    type: PROCEDURE_TEMPLATE_ERROR,
    hasError: bool,
    errorMsg
  };
};

const isLoading = (bool) => {
  return {
    type: PROCEDURE_TEMPLATE_LOADING,
    isLoading: bool
  };
};

const clearSuccess = () => {
  return {
    type: PROCEDURE_TEMPLATE_SUCCESS_CLEAR
  };
};

const onFetchListSuccess = (payload, searchTerm) => {
  return {
    type: PROCEDURE_TEMPLATE_FETCH_LIST,
    templateList: payload == null ? {} : payload.templateList,
    total: payload.total,
    pageStart: payload.start,
    searchTerm,
    orderTerm: payload.orderTerm,
    orderDirection: payload.orderDirection,
    fetchTime: new Date().getTime(),
    success: true
  };
};

const fetchTemplateList = (searchTerm, pageSize, start, orderTerm, orderDirection) => {
  console.log('Fetch template list');

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const searchRequest = {
      searchTerms: { NAME: searchTerm },
      pageSize,
      start,
      orderTerm,
      orderDirection
    };

    const config = getConfiguation(dispatch, getState);
    const proceduresTemplateApi = new ProceduresEndpointApi(config);
    const request = proceduresTemplateApi.getProcedureUiTemplateList({ searchRequest });
    onRequest(request, dispatch, onFetchListSuccess, onError, isLoading, searchTerm, 'Error fetching templates');
  };
};

const onUploadTemplateSuccess = (response, body) => {
  return {
    type: ADD_PROCEDURE_TEMPLATE,
    procedureTemplate: body,
    success: true
  };
};

const addProcedureTemplate = (template) => {
  console.log('upload Template');

  console.log(template);

  const body = {
    name: template.name,
    description: template.description,
    templateId: template.templateId,
    id: template.id,
    template: JSON.stringify(template)
  };

  if (template.bodySiteDefaultValueField) {
    body.prepopulatedFields = ['body_site'];
  }

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const proceduresTemplateApi = new ProceduresEndpointApi(config);
    const request = proceduresTemplateApi.importUiTemplate1({ uiTemplateDto: body });
    onRequest(request, dispatch, onUploadTemplateSuccess, onError, isLoading, body, 'Error importing template');
  };
};

const onFetchTemplateSuccess = (response) => {
  return {
    type: PROCEDURE_TEMPLATE_FETCH,
    payload: response,
    success: true
  };
};

const fetchTemplates = (ids) => {
  return (dispatch, getState) => {
    const templateList = getState().proceduresData.procedureTemplates;

    console.log(ids);
    const filteredIds = ids.filter((id) => {
      const template = templateList.find((p) => {
        return p.id === id;
      });
      console.log(template);

      if ( template !== undefined ){
        const templateAge = new Date().getTime() - template.fetchTime;
        const templateExpired = templateAge > window._env_.REACT_APP_RESOURCE_CACHE_TIME;
        return templateExpired;
      }

      return true;
    });

    console.log(filteredIds);
    if (filteredIds.length > 0) {
      dispatch(isLoading(true));
      dispatch(clearSuccess());

      const config = getConfiguation(dispatch, getState);
      const proceduresTemplateApi = new ProceduresEndpointApi(config);
      const request = proceduresTemplateApi.getUiTemplateJson1({ ids });
      onRequest(request, dispatch, onFetchTemplateSuccess, onError, isLoading, null, 'Error fetching templates');
    } else {
      dispatch(onFetchTemplateSuccess([]));
    }
  };
};

const onAddProcedureSuccess = (response, newProcedure) => {
  return {
    type: ADD_PROCEDURE,
    procedure: response,
    newProcedure,
    success: true,
    updateSuccess: true,
    fetchAllTime: new Date().getTime()
  };
};

const addProcedure = (procedureData, templateId, uiTemplateId, prepopulatedFields) => {
  console.log('Add procedure');

  const body = {
    id: procedureData.id,
    procedureName: procedureData.procedureName,
    accessibleNames: procedureData.accessibleNames,
    genericCode: procedureData.genericCode,
    description: procedureData.description,
    templateId,
    uiTemplateId,
    prepopulatedFields
  };

  const newProcedure = procedureData.id === undefined;

  if (newProcedure) {
    // Add default subtemplate
    const exportMethodDto = {
      name: 'openOutcomes SNOMED',
      validity: true,
      genericCode: procedureData.genericCode,
      specificCode: 'Enter SNOMED CODE'
    };

    const subTemplateBody = {
      subProcedureName: procedureData.procedureName,
      exportMethodDtoList: [exportMethodDto]
    };

    body.subProcedureTemplateDtoList = [subTemplateBody];
  }

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const proceduresTemplateApi = new ProceduresEndpointApi(config);
    const request = proceduresTemplateApi.addProcedure({ procedureTemplateDto: body });
    onRequest(request, dispatch, onAddProcedureSuccess, onError, isLoading, newProcedure, 'Error adding procedure');
  };
};

const onAddSubProcedureSuccess = (response) => {
  return {
    type: ADD_SUBPROCEDURE,
    procedure: response,
    success: true,
    fetchAllTime: new Date().getTime()
  };
};

const addSubProcedure = (subProcedureData, procedureId, parentProcedureName) => {
  console.log('Add sub procedure');
  console.log(subProcedureData);

  const dtoList = subProcedureData.exportMethodDtoList;
  const id = dtoList ? dtoList[0].id : undefined;

  const exportMethodDto = {
    id,
    name: 'openOutcomes SNOMED',
    validity: subProcedureData.validity,
    genericCode: subProcedureData.genericCode,
    specificCode: subProcedureData.specificCode
  };

  const body = {
    id: subProcedureData.id,
    subProcedureName: subProcedureData.subProcedureName,
    parentProcedureId: procedureId,
    parentProcedureName,
    exportMethodDtoList: [exportMethodDto]
  };

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const proceduresTemplateApi = new ProceduresEndpointApi(config);
    const request = proceduresTemplateApi.addSubProcedure({ subProcedureTemplateDto: body });
    onRequest(request, dispatch, onAddSubProcedureSuccess, onError, isLoading, null, 'Error adding sub-procedure');
  };
};

const onFetchProceduresSuccess = (payload, searchTerm) => {
  return {
    type: PROCEDURE_FETCH_ALL,
    procedures: payload == null ? {} : payload.procedureList,
    total: payload.total,
    pageStart: payload.start,
    searchTerm,
    orderTerm: payload.orderTerm,
    orderDirection: payload.orderDirection,
    fetchTime: new Date().getTime(),
    success: true
  };
};

const fetchProcedures = (searchTerm, pageSize, start, orderTerm, orderDirection) => {
  console.log('Fetch procedure list');

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const searchRequest = {
      searchTerms: { ALL: searchTerm },
      pageSize,
      start,
      orderTerm,
      orderDirection
    };

    const config = getConfiguation(dispatch, getState);
    const proceduresTemplateApi = new ProceduresEndpointApi(config);
    const request = proceduresTemplateApi.getProcedureList({ searchRequest });
    onRequest(request, dispatch, onFetchProceduresSuccess, onError, isLoading, searchTerm, 'Error fetching procedures');
  };
};

const onFetchProcedureSuccess = (response) => {
  return {
    type: PROCEDURE_FETCH,
    procedures: response,
    success: true,
    updateSuccess: false
  };
};

const fetchProceduresById = (procedureIds) => {
  console.log('Fetch procedure');

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const proceduresTemplateApi = new ProceduresEndpointApi(config);
    const request = proceduresTemplateApi.getMultipleProceduresById({ ids: procedureIds });
    onRequest(request, dispatch, onFetchProcedureSuccess, onError, isLoading, null, 'Error fetching procedures');
  };
};

const onDeleteSubProcedureSuccess = (response) => {
  return {
    type: DELETE_SUBPROCEDURE,
    procedure: response,
    success: true
  };
};

const deleteSubProcedure = (subProcedureId, procedureId) => {
  console.log(`Deleting sub procedure: ${subProcedureId}`);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const proceduresTemplateApi = new ProceduresEndpointApi(config);
    const request = proceduresTemplateApi.deleteSubProcedure({ procedureId, subProcedureId });
    onRequest(request, dispatch, onDeleteSubProcedureSuccess, onError, isLoading, null, 'Error deleting sub-procedure');
  };
};

const onDeleteProcedureSuccess = (response, procedureId) => {
  return {
    type: DELETE_PROCEDURE,
    procedureId,
    success: true
  };
};

const deleteProcedure = (procedureId) => {
  console.log(`Deleting procedure group: ${procedureId}`);

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const proceduresTemplateApi = new ProceduresEndpointApi(config);
    const request = proceduresTemplateApi.deleteProcedure({ procedureId });
    onRequest(request, dispatch, onDeleteProcedureSuccess, onError, isLoading, procedureId, 'Error deleting procedure group');
  };
};

export default {
  addProcedureTemplate,
  addProcedure,
  addSubProcedure,
  deleteProcedure,
  deleteSubProcedure,
  fetchProcedures,
  fetchProceduresById,
  fetchTemplateList,
  fetchTemplates,
  clearError,
  clearSuccess
};
