/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a hospital site
 * @export
 * @interface HospitalSiteDto
 */
export interface HospitalSiteDto {
    /**
     * Id
     * @type {string}
     * @memberof HospitalSiteDto
     */
    id: string;
    /**
     * Hospital site
     * @type {string}
     * @memberof HospitalSiteDto
     */
    site: string;
    /**
     * Location of the hospital site
     * @type {string}
     * @memberof HospitalSiteDto
     */
    location: string;
}

/**
 * Check if a given object implements the HospitalSiteDto interface.
 */
export function instanceOfHospitalSiteDto(value: object): value is HospitalSiteDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('site' in value) || value['site'] === undefined) return false;
    if (!('location' in value) || value['location'] === undefined) return false;
    return true;
}

export function HospitalSiteDtoFromJSON(json: any): HospitalSiteDto {
    return HospitalSiteDtoFromJSONTyped(json, false);
}

export function HospitalSiteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HospitalSiteDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'site': json['site'],
        'location': json['location'],
    };
}

export function HospitalSiteDtoToJSON(value?: HospitalSiteDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'site': value['site'],
        'location': value['location'],
    };
}

