/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PageableObject } from './PageableObject';
import {
    PageableObjectFromJSON,
    PageableObjectFromJSONTyped,
    PageableObjectToJSON,
} from './PageableObject';

/**
 * Details of a search request
 * @export
 * @interface SearchRequest
 */
export interface SearchRequest {
    /**
     * Search terms
     * @type {{ [key: string]: string; }}
     * @memberof SearchRequest
     */
    searchTerms?: { [key: string]: string; };
    /**
     * Paging page size
     * @type {number}
     * @memberof SearchRequest
     */
    pageSize?: number;
    /**
     * Paging start record
     * @type {number}
     * @memberof SearchRequest
     */
    start?: number;
    /**
     * Ordered by
     * @type {string}
     * @memberof SearchRequest
     */
    orderTerm?: string;
    /**
     * Order direction
     * @type {string}
     * @memberof SearchRequest
     */
    orderDirection?: string;
    /**
     * 
     * @type {PageableObject}
     * @memberof SearchRequest
     */
    pageable?: PageableObject;
}

/**
 * Check if a given object implements the SearchRequest interface.
 */
export function instanceOfSearchRequest(value: object): value is SearchRequest {
    return true;
}

export function SearchRequestFromJSON(json: any): SearchRequest {
    return SearchRequestFromJSONTyped(json, false);
}

export function SearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'searchTerms': json['searchTerms'] == null ? undefined : json['searchTerms'],
        'pageSize': json['pageSize'] == null ? undefined : json['pageSize'],
        'start': json['start'] == null ? undefined : json['start'],
        'orderTerm': json['orderTerm'] == null ? undefined : json['orderTerm'],
        'orderDirection': json['orderDirection'] == null ? undefined : json['orderDirection'],
        'pageable': json['pageable'] == null ? undefined : PageableObjectFromJSON(json['pageable']),
    };
}

export function SearchRequestToJSON(value?: SearchRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'searchTerms': value['searchTerms'],
        'pageSize': value['pageSize'],
        'start': value['start'],
        'orderTerm': value['orderTerm'],
        'orderDirection': value['orderDirection'],
        'pageable': PageableObjectToJSON(value['pageable']),
    };
}

