/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents an update to the enabled status of a supported language
 * @export
 * @interface UpdateSupportedLanguageDto
 */
export interface UpdateSupportedLanguageDto {
    /**
     * Language code
     * @type {string}
     * @memberof UpdateSupportedLanguageDto
     */
    languageCode: string;
    /**
     * Enabled
     * @type {boolean}
     * @memberof UpdateSupportedLanguageDto
     */
    enabled: boolean;
}

/**
 * Check if a given object implements the UpdateSupportedLanguageDto interface.
 */
export function instanceOfUpdateSupportedLanguageDto(value: object): value is UpdateSupportedLanguageDto {
    if (!('languageCode' in value) || value['languageCode'] === undefined) return false;
    if (!('enabled' in value) || value['enabled'] === undefined) return false;
    return true;
}

export function UpdateSupportedLanguageDtoFromJSON(json: any): UpdateSupportedLanguageDto {
    return UpdateSupportedLanguageDtoFromJSONTyped(json, false);
}

export function UpdateSupportedLanguageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSupportedLanguageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'languageCode': json['languageCode'],
        'enabled': json['enabled'],
    };
}

export function UpdateSupportedLanguageDtoToJSON(value?: UpdateSupportedLanguageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'languageCode': value['languageCode'],
        'enabled': value['enabled'],
    };
}

