/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents the proms printing configuration
 * @export
 * @interface PromsPrintingConfigDto
 */
export interface PromsPrintingConfigDto {
    /**
     * Logo url
     * @type {string}
     * @memberof PromsPrintingConfigDto
     */
    logoUrl?: string;
    /**
     * Post proms Covering letter
     * @type {string}
     * @memberof PromsPrintingConfigDto
     */
    coveringLetterPost?: string;
    /**
     * Pre proms Covering letter
     * @type {string}
     * @memberof PromsPrintingConfigDto
     */
    coveringLetterPre?: string;
    /**
     * No procedure Covering letter
     * @type {string}
     * @memberof PromsPrintingConfigDto
     */
    coveringLetterNoProcedure?: string;
}

/**
 * Check if a given object implements the PromsPrintingConfigDto interface.
 */
export function instanceOfPromsPrintingConfigDto(value: object): value is PromsPrintingConfigDto {
    return true;
}

export function PromsPrintingConfigDtoFromJSON(json: any): PromsPrintingConfigDto {
    return PromsPrintingConfigDtoFromJSONTyped(json, false);
}

export function PromsPrintingConfigDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromsPrintingConfigDto {
    if (json == null) {
        return json;
    }
    return {
        
        'logoUrl': json['logoUrl'] == null ? undefined : json['logoUrl'],
        'coveringLetterPost': json['coveringLetterPost'] == null ? undefined : json['coveringLetterPost'],
        'coveringLetterPre': json['coveringLetterPre'] == null ? undefined : json['coveringLetterPre'],
        'coveringLetterNoProcedure': json['coveringLetterNoProcedure'] == null ? undefined : json['coveringLetterNoProcedure'],
    };
}

export function PromsPrintingConfigDtoToJSON(value?: PromsPrintingConfigDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'logoUrl': value['logoUrl'],
        'coveringLetterPost': value['coveringLetterPost'],
        'coveringLetterPre': value['coveringLetterPre'],
        'coveringLetterNoProcedure': value['coveringLetterNoProcedure'],
    };
}

