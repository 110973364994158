/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TaskTypeCountDto } from './TaskTypeCountDto';
import {
    TaskTypeCountDtoFromJSON,
    TaskTypeCountDtoFromJSONTyped,
    TaskTypeCountDtoToJSON,
} from './TaskTypeCountDto';
import type { ResponseRateDto } from './ResponseRateDto';
import {
    ResponseRateDtoFromJSON,
    ResponseRateDtoFromJSONTyped,
    ResponseRateDtoToJSON,
} from './ResponseRateDto';

/**
 * 
 * @export
 * @interface WidgetDataResponse
 */
export interface WidgetDataResponse {
    /**
     * 
     * @type {Array<TaskTypeCountDto>}
     * @memberof WidgetDataResponse
     */
    taskTypeCountList?: Array<TaskTypeCountDto>;
    /**
     * 
     * @type {Array<ResponseRateDto>}
     * @memberof WidgetDataResponse
     */
    responseRateList?: Array<ResponseRateDto>;
    /**
     * 
     * @type {number}
     * @memberof WidgetDataResponse
     */
    overDueProms?: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetDataResponse
     */
    expiringProms?: number;
}

/**
 * Check if a given object implements the WidgetDataResponse interface.
 */
export function instanceOfWidgetDataResponse(value: object): value is WidgetDataResponse {
    return true;
}

export function WidgetDataResponseFromJSON(json: any): WidgetDataResponse {
    return WidgetDataResponseFromJSONTyped(json, false);
}

export function WidgetDataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetDataResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'taskTypeCountList': json['taskTypeCountList'] == null ? undefined : ((json['taskTypeCountList'] as Array<any>).map(TaskTypeCountDtoFromJSON)),
        'responseRateList': json['responseRateList'] == null ? undefined : ((json['responseRateList'] as Array<any>).map(ResponseRateDtoFromJSON)),
        'overDueProms': json['overDueProms'] == null ? undefined : json['overDueProms'],
        'expiringProms': json['expiringProms'] == null ? undefined : json['expiringProms'],
    };
}

export function WidgetDataResponseToJSON(value?: WidgetDataResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'taskTypeCountList': value['taskTypeCountList'] == null ? undefined : ((value['taskTypeCountList'] as Array<any>).map(TaskTypeCountDtoToJSON)),
        'responseRateList': value['responseRateList'] == null ? undefined : ((value['responseRateList'] as Array<any>).map(ResponseRateDtoToJSON)),
        'overDueProms': value['overDueProms'],
        'expiringProms': value['expiringProms'],
    };
}

