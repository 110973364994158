/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents the proms email configuration
 * @export
 * @interface PromsEmailConfigDto
 */
export interface PromsEmailConfigDto {
    /**
     * Enabled
     * @type {boolean}
     * @memberof PromsEmailConfigDto
     */
    enabled: boolean;
    /**
     * Communication type
     * @type {string}
     * @memberof PromsEmailConfigDto
     */
    type: PromsEmailConfigDtoTypeEnum;
    /**
     * Id
     * @type {number}
     * @memberof PromsEmailConfigDto
     */
    id: number;
    /**
     * Name
     * @type {string}
     * @memberof PromsEmailConfigDto
     */
    name: string;
    /**
     * Number days before or after the operation the email should be sent
     * @type {number}
     * @memberof PromsEmailConfigDto
     */
    days: number;
    /**
     * Procedure type; Used for email being sent before or after procedure
     * @type {string}
     * @memberof PromsEmailConfigDto
     */
    procedureType: PromsEmailConfigDtoProcedureTypeEnum;
    /**
     * Index
     * @type {number}
     * @memberof PromsEmailConfigDto
     */
    index: number;
    /**
     * Email body template
     * @type {string}
     * @memberof PromsEmailConfigDto
     */
    emailBody?: string;
    /**
     * Email subject template
     * @type {string}
     * @memberof PromsEmailConfigDto
     */
    emailSubject?: string;
    /**
     * Email sender's email address
     * @type {string}
     * @memberof PromsEmailConfigDto
     */
    emailSender?: string;
    /**
     * Email sender
     * @type {string}
     * @memberof PromsEmailConfigDto
     */
    emailSenderAddress?: string;
    /**
     * If this must be sent
     * @type {boolean}
     * @memberof PromsEmailConfigDto
     */
    mandatory?: boolean;
}


/**
 * @export
 */
export const PromsEmailConfigDtoTypeEnum = {
    Long: 'Long',
    Short: 'Short'
} as const;
export type PromsEmailConfigDtoTypeEnum = typeof PromsEmailConfigDtoTypeEnum[keyof typeof PromsEmailConfigDtoTypeEnum];

/**
 * @export
 */
export const PromsEmailConfigDtoProcedureTypeEnum = {
    None: 'None',
    Pre: 'Pre',
    Post: 'Post'
} as const;
export type PromsEmailConfigDtoProcedureTypeEnum = typeof PromsEmailConfigDtoProcedureTypeEnum[keyof typeof PromsEmailConfigDtoProcedureTypeEnum];


/**
 * Check if a given object implements the PromsEmailConfigDto interface.
 */
export function instanceOfPromsEmailConfigDto(value: object): value is PromsEmailConfigDto {
    if (!('enabled' in value) || value['enabled'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('days' in value) || value['days'] === undefined) return false;
    if (!('procedureType' in value) || value['procedureType'] === undefined) return false;
    if (!('index' in value) || value['index'] === undefined) return false;
    return true;
}

export function PromsEmailConfigDtoFromJSON(json: any): PromsEmailConfigDto {
    return PromsEmailConfigDtoFromJSONTyped(json, false);
}

export function PromsEmailConfigDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromsEmailConfigDto {
    if (json == null) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'type': json['type'],
        'id': json['id'],
        'name': json['name'],
        'days': json['days'],
        'procedureType': json['procedureType'],
        'index': json['index'],
        'emailBody': json['emailBody'] == null ? undefined : json['emailBody'],
        'emailSubject': json['emailSubject'] == null ? undefined : json['emailSubject'],
        'emailSender': json['emailSender'] == null ? undefined : json['emailSender'],
        'emailSenderAddress': json['emailSenderAddress'] == null ? undefined : json['emailSenderAddress'],
        'mandatory': json['mandatory'] == null ? undefined : json['mandatory'],
    };
}

export function PromsEmailConfigDtoToJSON(value?: PromsEmailConfigDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'enabled': value['enabled'],
        'type': value['type'],
        'id': value['id'],
        'name': value['name'],
        'days': value['days'],
        'procedureType': value['procedureType'],
        'index': value['index'],
        'emailBody': value['emailBody'],
        'emailSubject': value['emailSubject'],
        'emailSender': value['emailSender'],
        'emailSenderAddress': value['emailSenderAddress'],
        'mandatory': value['mandatory'],
    };
}

