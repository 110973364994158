/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAuthError,
  ApiValidationErrors,
  CompositionDto,
  CompositionResultDto,
  EhrQueryDto,
  EhrQueryResultDto,
  MultiEhrQueryDto,
  PathwayQueryDto,
  PathwayQueryResultsDto,
} from '../models/index';
import {
    ApiAuthErrorFromJSON,
    ApiAuthErrorToJSON,
    ApiValidationErrorsFromJSON,
    ApiValidationErrorsToJSON,
    CompositionDtoFromJSON,
    CompositionDtoToJSON,
    CompositionResultDtoFromJSON,
    CompositionResultDtoToJSON,
    EhrQueryDtoFromJSON,
    EhrQueryDtoToJSON,
    EhrQueryResultDtoFromJSON,
    EhrQueryResultDtoToJSON,
    MultiEhrQueryDtoFromJSON,
    MultiEhrQueryDtoToJSON,
    PathwayQueryDtoFromJSON,
    PathwayQueryDtoToJSON,
    PathwayQueryResultsDtoFromJSON,
    PathwayQueryResultsDtoToJSON,
} from '../models/index';

export interface CreateCompositionRequest {
    compositionUid: string;
    compositionDto: CompositionDto;
}

export interface CreateComposition2Request {
    compositionDto: CompositionDto;
}

export interface GetComposition1Request {
    compositionUid: string;
    userId: string;
    userIdNamespace: string;
}

export interface GetCompositionsRequest {
    compositionUids: Array<string>;
    userId: string;
    userIdNamespace: string;
}

export interface RunBasicQueryRequest {
    ehrQueryDto: EhrQueryDto;
}

export interface RunFlatPathwayQueryRequest {
    pathwayQueryDto: PathwayQueryDto;
}

export interface RunMultipleQueryRequest {
    multiEhrQueryDto: MultiEhrQueryDto;
}

export interface RunPathwayQueryRequest {
    pathwayQueryDto: PathwayQueryDto;
}

/**
 * 
 */
export class HealthRecordEndpointApi extends runtime.BaseAPI {

    /**
     * Update a composition on a patient record
     * Update composition
     */
    async createCompositionRaw(requestParameters: CreateCompositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['compositionUid'] == null) {
            throw new runtime.RequiredError(
                'compositionUid',
                'Required parameter "compositionUid" was null or undefined when calling createComposition().'
            );
        }

        if (requestParameters['compositionDto'] == null) {
            throw new runtime.RequiredError(
                'compositionDto',
                'Required parameter "compositionDto" was null or undefined when calling createComposition().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['compositionUid'] != null) {
            queryParameters['compositionUid'] = requestParameters['compositionUid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/ehr/composition`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompositionDtoToJSON(requestParameters['compositionDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update a composition on a patient record
     * Update composition
     */
    async createComposition(requestParameters: CreateCompositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createCompositionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new composition on a patient record
     * create composition
     */
    async createComposition2Raw(requestParameters: CreateComposition2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['compositionDto'] == null) {
            throw new runtime.RequiredError(
                'compositionDto',
                'Required parameter "compositionDto" was null or undefined when calling createComposition2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/ehr/composition`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompositionDtoToJSON(requestParameters['compositionDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new composition on a patient record
     * create composition
     */
    async createComposition2(requestParameters: CreateComposition2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createComposition2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a composition version on a patient record
     * Fetch composition
     */
    async getComposition1Raw(requestParameters: GetComposition1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['compositionUid'] == null) {
            throw new runtime.RequiredError(
                'compositionUid',
                'Required parameter "compositionUid" was null or undefined when calling getComposition1().'
            );
        }

        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getComposition1().'
            );
        }

        if (requestParameters['userIdNamespace'] == null) {
            throw new runtime.RequiredError(
                'userIdNamespace',
                'Required parameter "userIdNamespace" was null or undefined when calling getComposition1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['compositionUid'] != null) {
            queryParameters['compositionUid'] = requestParameters['compositionUid'];
        }

        if (requestParameters['userId'] != null) {
            queryParameters['userId'] = requestParameters['userId'];
        }

        if (requestParameters['userIdNamespace'] != null) {
            queryParameters['userIdNamespace'] = requestParameters['userIdNamespace'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/ehr/composition`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get a composition version on a patient record
     * Fetch composition
     */
    async getComposition1(requestParameters: GetComposition1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getComposition1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a composition version on a patient record
     * Fetch composition
     */
    async getCompositionsRaw(requestParameters: GetCompositionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompositionResultDto>>> {
        if (requestParameters['compositionUids'] == null) {
            throw new runtime.RequiredError(
                'compositionUids',
                'Required parameter "compositionUids" was null or undefined when calling getCompositions().'
            );
        }

        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getCompositions().'
            );
        }

        if (requestParameters['userIdNamespace'] == null) {
            throw new runtime.RequiredError(
                'userIdNamespace',
                'Required parameter "userIdNamespace" was null or undefined when calling getCompositions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['compositionUids'] != null) {
            queryParameters['compositionUids'] = requestParameters['compositionUids'];
        }

        if (requestParameters['userId'] != null) {
            queryParameters['userId'] = requestParameters['userId'];
        }

        if (requestParameters['userIdNamespace'] != null) {
            queryParameters['userIdNamespace'] = requestParameters['userIdNamespace'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/ehr/compositions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompositionResultDtoFromJSON));
    }

    /**
     * Get a composition version on a patient record
     * Fetch composition
     */
    async getCompositions(requestParameters: GetCompositionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompositionResultDto>> {
        const response = await this.getCompositionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Run an ehr query on a patient record. The result of the query is shown in the documentation as a string but will always contain a JSON Array.
     * Run query
     */
    async runBasicQueryRaw(requestParameters: RunBasicQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EhrQueryResultDto>> {
        if (requestParameters['ehrQueryDto'] == null) {
            throw new runtime.RequiredError(
                'ehrQueryDto',
                'Required parameter "ehrQueryDto" was null or undefined when calling runBasicQuery().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/ehr/query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EhrQueryDtoToJSON(requestParameters['ehrQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EhrQueryResultDtoFromJSON(jsonValue));
    }

    /**
     * Run an ehr query on a patient record. The result of the query is shown in the documentation as a string but will always contain a JSON Array.
     * Run query
     */
    async runBasicQuery(requestParameters: RunBasicQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EhrQueryResultDto> {
        const response = await this.runBasicQueryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Query the proms and procedure results for a pathway using the flat path query approach. The results will be processed and returned together.
     * Run flat pathway query
     */
    async runFlatPathwayQueryRaw(requestParameters: RunFlatPathwayQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PathwayQueryResultsDto>> {
        if (requestParameters['pathwayQueryDto'] == null) {
            throw new runtime.RequiredError(
                'pathwayQueryDto',
                'Required parameter "pathwayQueryDto" was null or undefined when calling runFlatPathwayQuery().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/ehr/flatpathway`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PathwayQueryDtoToJSON(requestParameters['pathwayQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PathwayQueryResultsDtoFromJSON(jsonValue));
    }

    /**
     * Query the proms and procedure results for a pathway using the flat path query approach. The results will be processed and returned together.
     * Run flat pathway query
     */
    async runFlatPathwayQuery(requestParameters: RunFlatPathwayQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PathwayQueryResultsDto> {
        const response = await this.runFlatPathwayQueryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Run a set of ehr queries on a patient record. The results will be processed and returned together.
     * Run multiple queries together
     */
    async runMultipleQueryRaw(requestParameters: RunMultipleQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['multiEhrQueryDto'] == null) {
            throw new runtime.RequiredError(
                'multiEhrQueryDto',
                'Required parameter "multiEhrQueryDto" was null or undefined when calling runMultipleQuery().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/ehr/multiquery`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MultiEhrQueryDtoToJSON(requestParameters['multiEhrQueryDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Run a set of ehr queries on a patient record. The results will be processed and returned together.
     * Run multiple queries together
     */
    async runMultipleQuery(requestParameters: RunMultipleQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.runMultipleQueryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Run a set of ehr queries on a patient record. The results will be processed and returned together.
     * Run multiple queries together
     */
    async runPathwayQueryRaw(requestParameters: RunPathwayQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PathwayQueryResultsDto>> {
        if (requestParameters['pathwayQueryDto'] == null) {
            throw new runtime.RequiredError(
                'pathwayQueryDto',
                'Required parameter "pathwayQueryDto" was null or undefined when calling runPathwayQuery().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/ehr/pathway`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PathwayQueryDtoToJSON(requestParameters['pathwayQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PathwayQueryResultsDtoFromJSON(jsonValue));
    }

    /**
     * Run a set of ehr queries on a patient record. The results will be processed and returned together.
     * Run multiple queries together
     */
    async runPathwayQuery(requestParameters: RunPathwayQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PathwayQueryResultsDto> {
        const response = await this.runPathwayQueryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
