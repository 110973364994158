import {
  ADD_PROMS_TEMPLATE,
  PROMS_TEMPLATE_LOADING,
  PROMS_TEMPLATE_FETCH,
  PROMS_TEMPLATE_FETCH_ALL,
  PROMS_TEMPLATE_FETCH_LIST,
  PROMS_TEMPLATE_ERROR,
  PROMS_TEMPLATE_PDF_UPLOAD,
  PROMS_TEMPLATE_PDF_DELETION,
  PROMS_TEMPLATE_SUCCESS_CLEAR,
  LOGOUT
} from '../actions/Actions';

const initialState = {
  list: [],
  promQuestionnaires: [],
  fetchAllTime: 0,
  searchTerm: '',

  hasError: false,
  isLoading: false,
  errorMsg: null,

  updateSuccess: false
};

function replacePromsTemplates(oldArray, newArray) {
  const filteredArray = oldArray.filter((item) => {
    const foundItem = newArray.find((t) => t.id === item.id);
    return foundItem === undefined;
  });

  return filteredArray.concat(newArray);
}

function updatePdfFile(array, templateId, promsId, fileName) {
  return array.map((item) => {
    console.log(item.id);
    if (item.id !== templateId) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    const updatedArray = item.children.map((q) => {
      if (q.id !== promsId) {
        return q;
      }

      if (fileName) {
        q.fileName = fileName;
      } else {
        q.fileName = null;
      }

      return q;
    });

    item.questionnaires = updatedArray;

    return {
      ...item
    };
  });
}

const questionnairesReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case PROMS_TEMPLATE_FETCH_LIST: {
      console.log('fetch proms templates', action);
      const { templateList } = action;

      if (action.pageStart > 0) {
        const updatedTemplateArray = [...state.list, ...templateList];

        return {
          ...state,
          list: updatedTemplateArray,
          currentTotal: updatedTemplateArray.length,
          isLoading: false
        };
      }
      return {
        ...state,
        list: templateList,
        currentTotal: templateList.length,
        total: action.total,
        searchTerm: action.searchTerm,
        orderTerm: action.orderTerm,
        orderDirection: action.orderDirection,
        isLoading: false,
        fetchTime: action.fetchTime
      };
    }
    case PROMS_TEMPLATE_FETCH: {
      console.log('fetched proms template', action);

      const { payload } = action;

      const promQuestionnaires = payload.map((t) => {
        if (t !== null) {
          const pq = JSON.parse(t.template);
          pq.children.forEach((child) => {
            const pdfItem = t.pdfList.find((pd) => pd.promsId === child.id);
            if (pdfItem) {
              child.fileName = pdfItem.fileName;
            } else {
              child.fileName = null;
            }
          });
          return pq;
        }
        return null;
      }).filter((filter) => filter !== null);

      const newList = replacePromsTemplates(state.promQuestionnaires, promQuestionnaires);

      return {
        ...state,
        promQuestionnaires: newList,
        isLoading: false
      };
    }
    case PROMS_TEMPLATE_FETCH_ALL: {
      console.log('fetched proms template', action);

      const { payload } = action;

      const promQuestionnaires = payload.map((t) => {
        if (t !== null) {
          const pq = JSON.parse(t.template);
          pq.children.forEach((child) => {
            const pdfItem = t.pdfList.find((pd) => pd.promsId === child.id);
            if (pdfItem) {
              child.fileName = pdfItem.fileName;
            } else {
              child.fileName = null;
            }
          });
          return pq;
        }
        return null;
      }).filter((filter) => filter !== null);

      return {
        ...state,
        promQuestionnaires,
        isLoading: false,
        fetchAllTime: action.fetchAllTime
      };
    }
    case PROMS_TEMPLATE_ERROR: {
      console.log('Proms template error', action);
      return {
        ...state,
        hasError: action.hasError,
        errorMsg: action.errorMsg,
        updateSuccess: false
      };
    }
    case PROMS_TEMPLATE_LOADING: {
      console.log('isloading', action);

      return { ...state, isLoading: action.isLoading, };
    }
    case ADD_PROMS_TEMPLATE: {
      console.log('Add proms template', action);

      return {
        ...state,
        updateSuccess: true,
        isLoading: false
      };
    }
    case PROMS_TEMPLATE_SUCCESS_CLEAR: {
      console.log('Clear update success', action);

      return { ...state, updateSuccess: false, };
    }
    case PROMS_TEMPLATE_PDF_UPLOAD: {
      console.log('Upload proms pdf', action);

      const { fileName } = action.payload;
      const { templateId } = action.payload;
      const { promsId } = action.payload;

      const updatedArray = updatePdfFile(state.promQuestionnaires, templateId, promsId, fileName);
      return { ...state, promQuestionnaires: updatedArray };
    }
    case PROMS_TEMPLATE_PDF_DELETION: {
      console.log('Remove proms pdf', action);

      const { templateId } = action.payload;
      const { promsId } = action.payload;

      const updatedArray = updatePdfFile(state.promQuestionnaires, templateId, promsId, null);
      return { ...state, promQuestionnaires: updatedArray };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default questionnairesReducer;
