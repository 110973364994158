/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAuthError,
  ApiError,
  ApiValidationErrors,
  HcpDto,
  HcpSummaryDto,
  SearchRequest,
  StaffResponse,
} from '../models/index';
import {
    ApiAuthErrorFromJSON,
    ApiAuthErrorToJSON,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    ApiValidationErrorsFromJSON,
    ApiValidationErrorsToJSON,
    HcpDtoFromJSON,
    HcpDtoToJSON,
    HcpSummaryDtoFromJSON,
    HcpSummaryDtoToJSON,
    SearchRequestFromJSON,
    SearchRequestToJSON,
    StaffResponseFromJSON,
    StaffResponseToJSON,
} from '../models/index';

export interface CreateRequest {
    hcpDto: HcpDto;
}

export interface CreateUpdateProfileRequest {
    hcpDto: HcpDto;
}

export interface DeleteProfileRequest {
    username: string;
}

export interface DisableProfileRequest {
    hcpDto: HcpDto;
}

export interface EnableProfileRequest {
    hcpDto: HcpDto;
}

export interface FindByNhsIdRequest {
    nhsId: string;
}

export interface FindStaffRequest {
    searchRequest: SearchRequest;
}

export interface UpdateProfileRequest {
    hcpDto: HcpDto;
}

/**
 * 
 */
export class HcpApi extends runtime.BaseAPI {

    /**
     * Creates a new staff user
     * create
     */
    async createRaw(requestParameters: CreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HcpDto>> {
        if (requestParameters['hcpDto'] == null) {
            throw new runtime.RequiredError(
                'hcpDto',
                'Required parameter "hcpDto" was null or undefined when calling create().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/user/hcp/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HcpDtoToJSON(requestParameters['hcpDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HcpDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new staff user
     * create
     */
    async create(requestParameters: CreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HcpDto> {
        const response = await this.createRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create or Update HCP information for the authenticated HCP. Caller must have the \'HCP\' role
     * Create/Update HCP Profile
     */
    async createUpdateProfileRaw(requestParameters: CreateUpdateProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['hcpDto'] == null) {
            throw new runtime.RequiredError(
                'hcpDto',
                'Required parameter "hcpDto" was null or undefined when calling createUpdateProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/user/hcp/profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HcpDtoToJSON(requestParameters['hcpDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create or Update HCP information for the authenticated HCP. Caller must have the \'HCP\' role
     * Create/Update HCP Profile
     */
    async createUpdateProfile(requestParameters: CreateUpdateProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createUpdateProfileRaw(requestParameters, initOverrides);
    }

    /**
     * Delete HCP profile
     * Delete HCP Profile
     */
    async deleteProfileRaw(requestParameters: DeleteProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['username'] == null) {
            throw new runtime.RequiredError(
                'username',
                'Required parameter "username" was null or undefined when calling deleteProfile().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['username'] != null) {
            queryParameters['username'] = requestParameters['username'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/user/hcp`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete HCP profile
     * Delete HCP Profile
     */
    async deleteProfile(requestParameters: DeleteProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProfileRaw(requestParameters, initOverrides);
    }

    /**
     * Disable HCP profile
     * Disable HCP Profile
     */
    async disableProfileRaw(requestParameters: DisableProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['hcpDto'] == null) {
            throw new runtime.RequiredError(
                'hcpDto',
                'Required parameter "hcpDto" was null or undefined when calling disableProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/user/hcp/disable`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HcpDtoToJSON(requestParameters['hcpDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Disable HCP profile
     * Disable HCP Profile
     */
    async disableProfile(requestParameters: DisableProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.disableProfileRaw(requestParameters, initOverrides);
    }

    /**
     * Re-enable disabled HCP profile
     * Re-enable disabled HCP Profile
     */
    async enableProfileRaw(requestParameters: EnableProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['hcpDto'] == null) {
            throw new runtime.RequiredError(
                'hcpDto',
                'Required parameter "hcpDto" was null or undefined when calling enableProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/user/hcp/enable`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HcpDtoToJSON(requestParameters['hcpDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Re-enable disabled HCP profile
     * Re-enable disabled HCP Profile
     */
    async enableProfile(requestParameters: EnableProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.enableProfileRaw(requestParameters, initOverrides);
    }

    /**
     * Find an HCP using the corresponding NHS ID. Callers must have the \'Patient\' role
     * Search for HCP
     */
    async findByNhsIdRaw(requestParameters: FindByNhsIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HcpSummaryDto>> {
        if (requestParameters['nhsId'] == null) {
            throw new runtime.RequiredError(
                'nhsId',
                'Required parameter "nhsId" was null or undefined when calling findByNhsId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['nhsId'] != null) {
            queryParameters['nhsId'] = requestParameters['nhsId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/user/hcp/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HcpSummaryDtoFromJSON(jsonValue));
    }

    /**
     * Find an HCP using the corresponding NHS ID. Callers must have the \'Patient\' role
     * Search for HCP
     */
    async findByNhsId(requestParameters: FindByNhsIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HcpSummaryDto> {
        const response = await this.findByNhsIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return list of staff based upon search terms
     * Fetch list of staff profiles
     */
    async findStaffRaw(requestParameters: FindStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StaffResponse>> {
        if (requestParameters['searchRequest'] == null) {
            throw new runtime.RequiredError(
                'searchRequest',
                'Required parameter "searchRequest" was null or undefined when calling findStaff().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/user/hcp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters['searchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffResponseFromJSON(jsonValue));
    }

    /**
     * Return list of staff based upon search terms
     * Fetch list of staff profiles
     */
    async findStaff(requestParameters: FindStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StaffResponse> {
        const response = await this.findStaffRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return HCP information for the authenticated HCP. Caller must have the \'HCP\' role
     * Retrieve HCP Profile
     */
    async getProfile1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HcpDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/user/hcp/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HcpDtoFromJSON(jsonValue));
    }

    /**
     * Return HCP information for the authenticated HCP. Caller must have the \'HCP\' role
     * Retrieve HCP Profile
     */
    async getProfile1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HcpDto> {
        const response = await this.getProfile1Raw(initOverrides);
        return await response.value();
    }

    /**
     * Create or Update HCP information for the authenticated HCP. Caller must have the \'HCP\' role
     * Update HCP Profile
     */
    async updateProfileRaw(requestParameters: UpdateProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['hcpDto'] == null) {
            throw new runtime.RequiredError(
                'hcpDto',
                'Required parameter "hcpDto" was null or undefined when calling updateProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/user/hcp/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HcpDtoToJSON(requestParameters['hcpDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create or Update HCP information for the authenticated HCP. Caller must have the \'HCP\' role
     * Update HCP Profile
     */
    async updateProfile(requestParameters: UpdateProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProfileRaw(requestParameters, initOverrides);
    }

}
