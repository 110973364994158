import {
  FETCH_PATHWAYS,
  FETCH_PATHWAY_LIST,
  ADD_PATHWAY,
  UPDATE_PATHWAY,
  DELETE_PATHWAY,
  PATHWAY_LOADING,
  PATHWAY_UPDATE_ERROR,
  PATHWAY_SUCCESS_CLEAR,
  LOGOUT
} from '../actions/Actions';

const initialState = {
  pathways: [],
  pathwayList: [],
  searchTerm: '',

  hasError: false,
  isLoading: false,
  errorMsg: null,
  updateSuccess: false,
  updateSuccessMessage: null
};

function updatePathwayInArray(array, pathway) {
  let found = false;
  let updatedPathwayArray = array.map((item) => {
    if (item.id !== pathway.id) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    found = true;
    return {
      ...item,
      ...pathway
    };
  });

  if (!found) {
    updatedPathwayArray = [...updatedPathwayArray, pathway];
  }

  return updatedPathwayArray;
}

const pathwaysReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case FETCH_PATHWAY_LIST: {
      console.log('Pathway reducer ', action);
      const { pathwayList } = action;

      if (action.pageStart > 0) {
        const updatedPathwayArray = [...state.pathwayList, ...pathwayList];

        return {
          ...state,
          pathwayList: updatedPathwayArray,
          currentTotal: updatedPathwayArray.length,
          isLoading: false
        };
      }
      return {
        ...state,
        pathwayList,
        currentTotal: pathwayList.length,
        total: action.total,
        searchTerm: action.searchTerm,
        orderTerm: action.orderTerm,
        orderDirection: action.orderDirection,
        isLoading: false,
        fetchTime: action.fetchTime
      };
    }
    case FETCH_PATHWAYS: {
      console.log('Pathway reducer ', action);

      let pathwayArray = state.pathways;
      action.pathways.forEach((p) => {
        const pathway = JSON.parse(p.template);
        pathwayArray = updatePathwayInArray(pathwayArray, pathway);
      });

      return {
        ...state,
        pathways: pathwayArray,
        isLoading: false
      };
    }
    case ADD_PATHWAY: {
      console.log('Pathway reducer ', action);

      const pathway = JSON.parse(action.pathway.template);
      return {
        ...state,
        pathways: [...state.pathways, pathway],
        pathwayList: [...state.pathwayList, action.pathwayListItem],
        isLoading: false,
        updateSuccess: true
      };
    }
    case UPDATE_PATHWAY: {
      console.log('Pathway reducer ', action);
      const pathway = JSON.parse(action.pathway.template);
      const updatedArray = updatePathwayInArray(state.pathways, pathway);
      return {
        ...state,
        pathways: updatedArray,
        isLoading: false,
        updateSuccess: true
      };
    }
    case DELETE_PATHWAY: {
      console.log('Pathway reducer ', action);
      const deletedPathway = action.pathway;
      const updatedPathways = state.pathways.filter((pathway) => pathway.id !== deletedPathway.id);
      const updatedPathwayList = state.pathwayList.filter((p) => p.id !== deletedPathway.id);

      return {
        ...state,
        pathways: updatedPathways,
        pathwayList: updatedPathwayList,
        currentTotal: updatedPathwayList.length - 1,
        total: state.total - 1,
        isLoading: false,
        updateSuccess: true
      };
    }
    case LOGOUT:
      return initialState;
    case PATHWAY_UPDATE_ERROR:
      console.log('Pathway reducer - haserror', action);

      return {
        ...state,
        hasError: action.hasError,
        errorMsg: action.errorMsg,
        updateSuccess: false
      };
    case PATHWAY_LOADING:
      console.log('Pathway reducer - isloading', action);

      return { ...state, isLoading: action.isLoading, };
    case PATHWAY_SUCCESS_CLEAR: {
      console.log('Pathway reducer - sucess clear', action);

      return {
        ...state,
        updateSuccess: false,
        updateSuccessMessage: null
      };
    }
    default:
      return state;
  }
};

export default pathwaysReducer;
