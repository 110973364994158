import {
  UPDATE_HCP_PROFILE,
  HCP_DATA_ERROR,
  HCP_DATA_IS_LOADING,
  HCP_DATA_SUCCESS_CLEAR,
  LOGOUT
} from '../actions/Actions';

const initialState = {
  hcpProfile: null,
  hasError: false,
  isLoading: false,
  errorMsg: null,
  profileUpdateSuccess: false
};

const hcpDataReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case UPDATE_HCP_PROFILE:
      console.log('hcpprofile', action);

      return {
        ...state,
        hcpProfile: action.payload,
        hasError: false,
        profileUpdateSuccess: action.success
      };
    case HCP_DATA_ERROR:
      console.log('haserror', action);

      return {
        ...state,
        hasError: action.hasError,
        errorMsg: action.errorMsg
      };
    case HCP_DATA_IS_LOADING:
      console.log('isloading', action);

      return { ...state, isLoading: action.isLoading, };
    case HCP_DATA_SUCCESS_CLEAR: {
      console.log('isloading', action);

      return { ...state, profileUpdateSuccess: false, };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default hcpDataReducer;
