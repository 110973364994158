/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HcpDto } from './HcpDto';
import {
    HcpDtoFromJSON,
    HcpDtoFromJSONTyped,
    HcpDtoToJSON,
} from './HcpDto';

/**
 * Returns a staff search from an HCP staff search
 * @export
 * @interface StaffResponse
 */
export interface StaffResponse {
    /**
     * Search terms which produced results
     * @type {{ [key: string]: string; }}
     * @memberof StaffResponse
     */
    searchTerms?: { [key: string]: string; };
    /**
     * Paging page size
     * @type {number}
     * @memberof StaffResponse
     */
    pageSize?: number;
    /**
     * Paging start record
     * @type {number}
     * @memberof StaffResponse
     */
    start?: number;
    /**
     * Total number of patient profiles
     * @type {number}
     * @memberof StaffResponse
     */
    total?: number;
    /**
     * Ordered by
     * @type {string}
     * @memberof StaffResponse
     */
    orderTerm?: string;
    /**
     * Order direction
     * @type {string}
     * @memberof StaffResponse
     */
    orderDirection?: string;
    /**
     * List of staff profiles
     * @type {Array<HcpDto>}
     * @memberof StaffResponse
     */
    profiles?: Array<HcpDto>;
}

/**
 * Check if a given object implements the StaffResponse interface.
 */
export function instanceOfStaffResponse(value: object): value is StaffResponse {
    return true;
}

export function StaffResponseFromJSON(json: any): StaffResponse {
    return StaffResponseFromJSONTyped(json, false);
}

export function StaffResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'searchTerms': json['searchTerms'] == null ? undefined : json['searchTerms'],
        'pageSize': json['pageSize'] == null ? undefined : json['pageSize'],
        'start': json['start'] == null ? undefined : json['start'],
        'total': json['total'] == null ? undefined : json['total'],
        'orderTerm': json['orderTerm'] == null ? undefined : json['orderTerm'],
        'orderDirection': json['orderDirection'] == null ? undefined : json['orderDirection'],
        'profiles': json['profiles'] == null ? undefined : ((json['profiles'] as Array<any>).map(HcpDtoFromJSON)),
    };
}

export function StaffResponseToJSON(value?: StaffResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'searchTerms': value['searchTerms'],
        'pageSize': value['pageSize'],
        'start': value['start'],
        'total': value['total'],
        'orderTerm': value['orderTerm'],
        'orderDirection': value['orderDirection'],
        'profiles': value['profiles'] == null ? undefined : ((value['profiles'] as Array<any>).map(HcpDtoToJSON)),
    };
}

