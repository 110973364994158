/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TaskDto } from './TaskDto';
import {
    TaskDtoFromJSON,
    TaskDtoFromJSONTyped,
    TaskDtoToJSON,
} from './TaskDto';

/**
 * Returns task search results 
 * @export
 * @interface TaskSearchResponse
 */
export interface TaskSearchResponse {
    /**
     * Search terms which produced results
     * @type {{ [key: string]: string; }}
     * @memberof TaskSearchResponse
     */
    searchTerms?: { [key: string]: string; };
    /**
     * Paging page size
     * @type {number}
     * @memberof TaskSearchResponse
     */
    pageSize?: number;
    /**
     * Paging start record
     * @type {number}
     * @memberof TaskSearchResponse
     */
    start?: number;
    /**
     * Total number of tasks
     * @type {number}
     * @memberof TaskSearchResponse
     */
    total?: number;
    /**
     * Ordered by
     * @type {string}
     * @memberof TaskSearchResponse
     */
    orderTerm?: string;
    /**
     * Order direction
     * @type {string}
     * @memberof TaskSearchResponse
     */
    orderDirection?: string;
    /**
     * List of tasks
     * @type {Array<TaskDto>}
     * @memberof TaskSearchResponse
     */
    tasks?: Array<TaskDto>;
}

/**
 * Check if a given object implements the TaskSearchResponse interface.
 */
export function instanceOfTaskSearchResponse(value: object): value is TaskSearchResponse {
    return true;
}

export function TaskSearchResponseFromJSON(json: any): TaskSearchResponse {
    return TaskSearchResponseFromJSONTyped(json, false);
}

export function TaskSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskSearchResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'searchTerms': json['searchTerms'] == null ? undefined : json['searchTerms'],
        'pageSize': json['pageSize'] == null ? undefined : json['pageSize'],
        'start': json['start'] == null ? undefined : json['start'],
        'total': json['total'] == null ? undefined : json['total'],
        'orderTerm': json['orderTerm'] == null ? undefined : json['orderTerm'],
        'orderDirection': json['orderDirection'] == null ? undefined : json['orderDirection'],
        'tasks': json['tasks'] == null ? undefined : ((json['tasks'] as Array<any>).map(TaskDtoFromJSON)),
    };
}

export function TaskSearchResponseToJSON(value?: TaskSearchResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'searchTerms': value['searchTerms'],
        'pageSize': value['pageSize'],
        'start': value['start'],
        'total': value['total'],
        'orderTerm': value['orderTerm'],
        'orderDirection': value['orderDirection'],
        'tasks': value['tasks'] == null ? undefined : ((value['tasks'] as Array<any>).map(TaskDtoToJSON)),
    };
}

