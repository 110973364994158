/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a patients address
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * Address lines
     * @type {string}
     * @memberof AddressDto
     */
    lines: string;
    /**
     * City
     * @type {string}
     * @memberof AddressDto
     */
    city?: string;
    /**
     * County
     * @type {string}
     * @memberof AddressDto
     */
    county?: string;
    /**
     * Postcode
     * @type {string}
     * @memberof AddressDto
     */
    postcode: string;
}

/**
 * Check if a given object implements the AddressDto interface.
 */
export function instanceOfAddressDto(value: object): value is AddressDto {
    if (!('lines' in value) || value['lines'] === undefined) return false;
    if (!('postcode' in value) || value['postcode'] === undefined) return false;
    return true;
}

export function AddressDtoFromJSON(json: any): AddressDto {
    return AddressDtoFromJSONTyped(json, false);
}

export function AddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressDto {
    if (json == null) {
        return json;
    }
    return {
        
        'lines': json['lines'],
        'city': json['city'] == null ? undefined : json['city'],
        'county': json['county'] == null ? undefined : json['county'],
        'postcode': json['postcode'],
    };
}

export function AddressDtoToJSON(value?: AddressDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'lines': value['lines'],
        'city': value['city'],
        'county': value['county'],
        'postcode': value['postcode'],
    };
}

