import { PatientsEndpointApi } from 'promsApi';
import {
  FETCH_PATIENTS,
  ADD_PATIENT,
  UPDATE_PATIENT,
  PATIENT_UPDATE_ERROR,
  PATIENTS_LOADING,
  PATIENTS_SUCCESS_CLEAR,
  FETCH_PATIENT_DETAILS,
  PATIENT_EMAIL_SENT_SUCCESS,
  PATIENT_SMS_SENT_SUCCESS,
  PATIENT_DETAILS_CLEAR
} from './Actions';

import getConfiguation from './Configuration';
import onRequest from './Request';

const patientsError = (bool, errorMsg) => {
  return {
    type: PATIENT_UPDATE_ERROR,
    hasError: bool,
    errorMsg
  };
};

const patientsLoading = (bool) => {
  return {
    type: PATIENTS_LOADING,
    isLoading: bool
  };
};

const clearPatientSuccess = () => {
  return {
    type: PATIENTS_SUCCESS_CLEAR
  };
};

const clearPatientDetails = () => {
  return {
    type: PATIENT_DETAILS_CLEAR
  };
};

const clearPatientError = () => {
  return {
    type: PATIENT_UPDATE_ERROR,
    hasError: false,
    errorMsg: null
  };
};

const fetchPatientsSuccessHandler = (payload, searchTerm) => {
  return {
    type: FETCH_PATIENTS,
    patients: payload == null ? {} : payload.profiles,
    total: payload.total,
    pageStart: payload.start,
    searchTerm,
    orderTerm: payload.orderTerm,
    orderDirection: payload.orderDirection,
    fetchTime: new Date().getTime()
  };
};

const fetchDetailsSuccessHandler = (payload) => {
  return {
    type: FETCH_PATIENT_DETAILS,
    patientDetails: payload
  };
};

const fetchPatientDetails = (userId, userIdNamespace, forceRefresh) => {
  console.log('Fetching patient profile');

  return (dispatch, getState) => {
    const { patientDetails } = getState().patients;
    if (patientDetails === null
            || patientDetails.primaryId !== userId
            || patientDetails.primaryIdNamespace !== userIdNamespace
            || forceRefresh === true
    ) {
      console.log('Change of patient');

      if (!forceRefresh) {
        dispatch(clearPatientDetails());
      }

      dispatch(patientsLoading(true));

      const config = getConfiguation(dispatch, getState);
      const patientApi = new PatientsEndpointApi(config);
      const request = patientApi.getProfile2({ userId, userIdNamespace });
      onRequest(request, dispatch, fetchDetailsSuccessHandler, patientsError, patientsLoading);
    }
  };
};

const fetchPatients = (
  searchTerm,
  searchTermMapping,
  pageSize,
  start,
  orderTerm,
  orderDirection
) => {
  console.log('Fetching patient list');

  return (dispatch, getState) => {
    dispatch(patientsLoading(true));

    const config = getConfiguation(dispatch, getState);
    const patientApi = new PatientsEndpointApi(config);

    const patientSearchRequest = {
      pageSize,
      start,
      searchTerms: searchTermMapping,
      orderTerm,
      orderDirection
    };

    const request = patientApi.findPatients({ searchRequest: patientSearchRequest });
    onRequest(
      request,
      dispatch,
      fetchPatientsSuccessHandler,
      patientsError,
      patientsLoading,
      searchTerm
    );
  };
};

const createPatientSuccessHandler = (response) => {
  response.procedures = []; // TODO fetch procedures etc

  return {
    type: ADD_PATIENT,
    patient: response
  };
};

const createPatient = (profile) => {
  console.log('Create Patient');

  return (dispatch, getState) => {
    dispatch(patientsLoading(true));

    const config = getConfiguation(dispatch, getState);
    const patientApi = new PatientsEndpointApi(config);
    const request = patientApi.create1({ profileDto: profile });
    onRequest(request, dispatch, createPatientSuccessHandler, patientsError, patientsLoading);
  };
};

const updatePatientSuccess = (patient) => {
  return {
    type: UPDATE_PATIENT,
    patient
  };
};

const updatePatient = (profile) => {
  console.log('Update Patient');
  console.log(profile);

  return (dispatch, getState) => {
    dispatch(patientsLoading(true));

    const config = getConfiguation(dispatch, getState);
    const patientApi = new PatientsEndpointApi(config);
    const request = patientApi.update({ profileDto: profile });
    onRequest(request, dispatch, updatePatientSuccess, patientsError, patientsLoading);
  };
};

const sendPatientPromsEmailRequestSuccess = () => {
  return {
      type: PATIENT_EMAIL_SENT_SUCCESS
  }
};

const sendPatientPromsSmsRequestSuccess = () => {
  return {
      type: PATIENT_SMS_SENT_SUCCESS
  }
};


const sendPatientPromsNotificationRequest = promsDto => {
  console.log('Sending patient proms notification');

  return (dispatch, getState) => {
    dispatch(patientsLoading(true));
    dispatch(clearPatientError());
    dispatch(clearPatientSuccess());

    var config = getConfiguation(dispatch, getState);
    var patientApi = new PatientsEndpointApi(config);
    var request = patientApi.sendPromsNotification({
      sendPromsNotificationDto: promsDto
    });
    if (promsDto.notificationType  === 'Long') {
      onRequest(
        request,
        dispatch,
        sendPatientPromsEmailRequestSuccess,
        patientsError,
        patientsLoading,
        null,
        'Error sending proms email request'
      );
    } else {
      onRequest(
        request,
        dispatch,
        sendPatientPromsSmsRequestSuccess,
        patientsError,
        patientsLoading,
        null,
        'Error sending proms SMS request'
      );
    }
  };
};

export default {
  fetchPatients,
  updatePatient,
  fetchPatientDetails,
  createPatient,
  clearPatientSuccess,
  clearPatientDetails,
  clearPatientError,
  sendPatientPromsNotificationRequest
};
