import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar
} from '@mui/material';
import { makeStyles } from '@mui/styles'

import MenuIcon from '@mui/icons-material/Menu';
import {Settings as SettingsIcon} from 'react-feather';
import InputIcon from '@mui/icons-material/Input';
import Logo from 'src/components/Logo';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  onLogout,
  ...rest
}) => {
  const classes = useStyles();

  const navigate = useNavigate(); 

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden lgDown>
          <IconButton
            color="inherit"
            onClick={()=>{navigate('/app/profile', { replace: false })}}
            size="large">
              <SettingsIcon />
          </IconButton>
          <IconButton color="inherit" onClick={onLogout} size="large">
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
