export const FETCH_PATIENTS = 'FETCH_PATIENTS';
export const FETCH_PATIENT_DETAILS = 'FETCH_PATIENT_DETAILS';
export const ADD_PATIENT = 'ADD_PATIENTS';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const PATIENT_UPDATE_ERROR = 'PATIENTS_ERROR';
export const PATIENTS_LOADING = 'PATIENT_LOADING';
export const PATIENTS_SUCCESS_CLEAR = 'PATIENTS_SUCCESS_CLEAR';
export const PATIENT_EMAIL_SENT_SUCCESS = 'PATIENT_EMAIL_SENT_SUCCESS';
export const PATIENT_SMS_SENT_SUCCESS = 'PATIENT_SMS_SENT_SUCCESS';

export const ADD_PROMS_TEMPLATE = 'ADD_PROMS_TEMPLATE';
export const PROMS_TEMPLATE_ERROR = 'PROMS_TEMPLATE_ERROR';
export const PROMS_TEMPLATE_LOADING = 'PROMS_TEMPLATE_LOADING';
export const PROMS_TEMPLATE_SUCCESS_CLEAR = 'PROMS_TEMPLATE_SUCCESS_CLEAR';
export const PROMS_TEMPLATE_FETCH = 'PROMS_TEMPLATE_FETCH';
export const PROMS_TEMPLATE_FETCH_ALL = 'PROMS_TEMPLATE_FETCH_ALL';
export const PROMS_TEMPLATE_FETCH_LIST = 'PROMS_TEMPLATE_FETCH_LIST';
export const PROMS_TEMPLATE_PDF_UPLOAD = 'PROMS_TEMPLATE_PDF_UPLOAD';
export const PROMS_TEMPLATE_PDF_DELETION = 'PROMS_TEMPLATE_PDF_DELETION';

export const UPDATE_SERVER_CONFIG = 'UPDATE_SERVER_CONFIG';

export const LOGIN_HAS_ERROR = 'LOGIN_HAS_ERROR';
export const LOGIN_IS_LOADING = 'LOGIN_IS_LOADING';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const USER_PERMISSIONS = 'USER_PERMISSIONS';

export const ADD_PATIENT_DATA_COMPOSITION = 'ADD_PATIENT_DATA_COMPOSITION';
export const FETCH_PATIENT_DATA_COMPOSITION = 'FETCH_PATIENT_DATA_COMPOSITION';
export const FETCH_PATIENT_PATHWAY_RESULTS = 'FETCH_PATIENT_PATHWAY_RESULTS';
export const PATIENT_DATA_ERROR = 'PATIENT_DATA_ERROR';
export const PATIENT_DATA_UPDATE_ERROR = 'PATIENT_DATA_UPDATE_ERROR';
export const PATIENT_DATA_CLEAR_ERROR = 'PATIENT_DATA_CLEAR_ERROR';
export const PATIENT_DATA_IS_LOADING = 'PATIENT_DATA_IS_LOADING';
export const PATIENT_DATA_SUCCESS_CLEAR = 'PATIENT_DATA_SUCCESS_CLEAR';
export const PATIENT_DETAILS_CLEAR = 'PATIENT_DETAILS_CLEAR';
export const FETCH_PATIENT_PATHWAYS = 'FETCH_PATIENT_PATHWAYS';
export const FETCH_PATIENT_PATHWAY_BY_UUID = 'FETCH_PATIENT_PATHWAY_BY_UUID';
export const PATIENT_DATA_DELETE_SUCCESS_CLEAR = 'PATIENT_DATA_DELETE_SUCCESS_CLEAR';

export const FETCH_STAFF_LIST = 'FETCH_STAFF_LIST';
export const ADD_STAFF_PROFILE = 'ADD_STAFF_PROFILE';
export const UPDATE_STAFF_PROFILE = 'UPDATE_STAFF_PROFILE';
export const DELETE_STAFF_PROFILE = 'DELETE_STAFF_PROFILE';
export const STAFF_UPDATE_ERROR = 'STAFF_ERROR';
export const STAFF_LOADING = 'STAFF_LOADING';
export const STAFF_SUCCESS_CLEAR = 'STAFF_SUCCESS_CLEAR';
export const DISABLE_STAFF_PROFILE = 'DISABLE_STAFF_PROFILE';

export const UPDATE_HCP_PROFILE = 'UPDATE_HCP_PROFILE';
export const HCP_DATA_ERROR = 'HCP_DATA_ERROR';
export const HCP_DATA_IS_LOADING = 'HCP_DATA_IS_LOADING';
export const HCP_DATA_SUCCESS_CLEAR = 'HCP_DATA_SUCCESS_CLEAR';

export const ADD_PROCEDURE_TEMPLATE = 'ADD_PROCEDURE_TEMPLATE';
export const PROCEDURE_TEMPLATE_ERROR = 'PROCEDURE_TEMPLATE_ERROR';
export const PROCEDURE_TEMPLATE_LOADING = 'PROCEDURE_TEMPLATE_LOADING';
export const PROCEDURE_TEMPLATE_SUCCESS_CLEAR = 'PROCEDURE_TEMPLATE_SUCCESS_CLEAR';
export const PROCEDURE_TEMPLATE_FETCH = 'PROCEDURE_TEMPLATE_FETCH';
export const PROCEDURE_TEMPLATE_FETCH_LIST = 'PROCEDURE_TEMPLATE_FETCH_LIST';
export const ADD_PROCEDURE = 'ADD_PROCEDURE';
export const ADD_SUBPROCEDURE = 'ADD_SUBPROCEDURE';
export const PROCEDURE_FETCH_ALL = 'PROCEDURE_FETCH_ALL';
export const PROCEDURE_FETCH = 'PROCEDURE_FETCH';
export const DELETE_PROCEDURE = 'DELETE_PROCEDURE';
export const DELETE_SUBPROCEDURE = 'DELETE_SUBPROCEDURE';

export const FETCH_PATHWAY_LIST = 'FETCH_PATHWAY_LIST';
export const FETCH_PATHWAYS = 'FETCH_PATHWAYS';
export const ADD_PATHWAY = 'ADD_PATHWAY';
export const UPDATE_PATHWAY = 'UPDATE_PATHWAY';
export const DELETE_PATHWAY = 'DELETE_PATHWAY';
export const PATHWAY_UPDATE_ERROR = 'PATHWAY_UPDATE_ERROR';
export const PATHWAY_LOADING = 'PATHWAY_LOADING';
export const PATHWAY_SUCCESS_CLEAR = 'PATHWAY_SUCCESS_CLEAR';

export const ADD_PATIENT_PATHWAY = 'ADD_PATIENT_PATHWAY';
export const UPDATE_PATIENT_PATHWAY = 'UPDATE_PATIENT_PATHWAY';
export const DELETE_PATIENT_PATHWAY = 'DELETE_PATIENT_PATHWAY';

export const FETCH_ROLE_DEFAULT_PERMISSIONS = 'FETCH_ROLE_DEFAULT_PERMISSIONS';
export const FETCH_SITES_LIST = 'FETCH_SITES_LIST';
export const ADD_SITE = 'ADD_SITE';
export const UPDATE_SITE = 'UPDATE_SITE';
export const DELETE_SITE = 'DELETE_SITE';
export const CONFIG_ERROR = 'CONFIG_ERROR';
export const CONFIG_LOADING = 'CONFIG_LOADING';
export const SITE_SUCCESS_CLEAR = 'SITES_SUCCESS_CLEAR';
export const FETCH_PROMS_EMAIL_CONFIG = 'FETCH_PROMS_EMAIL_CONFIG';
export const UPDATE_PROMS_EMAIL_CONFIG = 'UPDATE_PROMS_EMAIL_CONFIG';
export const FETCH_PROMS_PRINT_CONFIG = 'FETCH_PROMS_PRINT_CONFIG';
export const UPDATE_PROMS_PRINT_CONFIG = 'UPDATE_PROMS_PRINT_CONFIG';
export const FETCH_LANGUAGE_LIST = 'FETCH_LANGUAGE_LIST';
export const FETCH_ENABLED_LANGUAGE_LIST = 'FETCH_ENABLED_LANGUAGE_LIST';
export const LANGUAGE_ENABLE_STATE = 'LANGUAGE_ENABLE_STATE';
export const REPORTING_URLS = 'REPORTING_URLS';

// PROMS Template development
export const PROMS_DEV_IS_LOADING = 'PROMS_DEV_IS_LOADING';
export const PROMS_DEV_FETCH_OPT_LIST = 'PROMS_DEV_FETCH_OPT_LIST';
export const PROMS_DEV_UPLOAD_TEMPLATE = 'PROMS_DEV_UPLOAD_TEMPLATE';
export const PROMS_DEV_HAS_ERROR = 'PROMS_DEV_HAS_ERROR';
export const PROMS_DEV_SUCCESS_CLEAR = 'PROMS_DEV_SUCCESS_CLEAR';

// Tasks
export const TASKS_FETCH_LIST = 'TASKS_FETCH_LIST';
export const TASKS_MARK_AS_COMPLETE = 'TASKS_MARK_AS_COMPLETE';
export const TASKS_GET_TYPE_COUNT_LIST = 'TASKS_GET_TYPE_COUNT_LIST';
export const TASKS_IS_LOADING = 'TASKS_IS_LOADING';
export const TASKS_ERROR = 'TASKS_ERROR';

// Audit
export const AUDIT_FETCH_LIST = 'AUDIT_FETCH_LIST';
export const AUDIT_IS_LOADING = 'AUDIT_IS_LOADING';
export const AUDIT_ERROR = 'AUDIT_ERROR';

// Dashboard widgets
export const WIDGET_DATA_FETCH = 'WIDGET_DATA_FETCH';
export const WIDGET_DATA_IS_LOADING = 'WIDGET_DATA_IS_LOADING';
export const WIDGET_DATA_ERROR = 'WIDGET_DATA_ERROR';
