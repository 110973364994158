/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Site } from './Site';
import {
    SiteFromJSON,
    SiteFromJSONTyped,
    SiteToJSON,
} from './Site';
import type { Procedure } from './Procedure';
import {
    ProcedureFromJSON,
    ProcedureFromJSONTyped,
    ProcedureToJSON,
} from './Procedure';
import type { Step } from './Step';
import {
    StepFromJSON,
    StepFromJSONTyped,
    StepToJSON,
} from './Step';
import type { Clinician } from './Clinician';
import {
    ClinicianFromJSON,
    ClinicianFromJSONTyped,
    ClinicianToJSON,
} from './Clinician';

/**
 * Request for an Pathway composition
 * @export
 * @interface PathwayDto
 */
export interface PathwayDto {
    /**
     * 
     * @type {string}
     * @memberof PathwayDto
     */
    pathway?: string;
    /**
     * 
     * @type {string}
     * @memberof PathwayDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PathwayDto
     */
    pathwayId?: string;
    /**
     * 
     * @type {Procedure}
     * @memberof PathwayDto
     */
    primaryProcedure?: Procedure;
    /**
     * 
     * @type {string}
     * @memberof PathwayDto
     */
    status?: string;
    /**
     * 
     * @type {Array<Step>}
     * @memberof PathwayDto
     */
    steps?: Array<Step>;
    /**
     * 
     * @type {string}
     * @memberof PathwayDto
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PathwayDto
     */
    initialAppointmentTime?: string;
    /**
     * 
     * @type {Array<Procedure>}
     * @memberof PathwayDto
     */
    secondaryProcedures?: Array<Procedure>;
    /**
     * 
     * @type {Clinician}
     * @memberof PathwayDto
     */
    consultant?: Clinician;
    /**
     * 
     * @type {Site}
     * @memberof PathwayDto
     */
    site?: Site;
}

/**
 * Check if a given object implements the PathwayDto interface.
 */
export function instanceOfPathwayDto(value: object): value is PathwayDto {
    return true;
}

export function PathwayDtoFromJSON(json: any): PathwayDto {
    return PathwayDtoFromJSONTyped(json, false);
}

export function PathwayDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PathwayDto {
    if (json == null) {
        return json;
    }
    return {
        
        'pathway': json['pathway'] == null ? undefined : json['pathway'],
        'description': json['description'] == null ? undefined : json['description'],
        'pathwayId': json['pathwayId'] == null ? undefined : json['pathwayId'],
        'primaryProcedure': json['primaryProcedure'] == null ? undefined : ProcedureFromJSON(json['primaryProcedure']),
        'status': json['status'] == null ? undefined : json['status'],
        'steps': json['steps'] == null ? undefined : ((json['steps'] as Array<any>).map(StepFromJSON)),
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
        'initialAppointmentTime': json['initialAppointmentTime'] == null ? undefined : json['initialAppointmentTime'],
        'secondaryProcedures': json['secondaryProcedures'] == null ? undefined : ((json['secondaryProcedures'] as Array<any>).map(ProcedureFromJSON)),
        'consultant': json['consultant'] == null ? undefined : ClinicianFromJSON(json['consultant']),
        'site': json['site'] == null ? undefined : SiteFromJSON(json['site']),
    };
}

export function PathwayDtoToJSON(value?: PathwayDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pathway': value['pathway'],
        'description': value['description'],
        'pathwayId': value['pathwayId'],
        'primaryProcedure': ProcedureToJSON(value['primaryProcedure']),
        'status': value['status'],
        'steps': value['steps'] == null ? undefined : ((value['steps'] as Array<any>).map(StepToJSON)),
        'uuid': value['uuid'],
        'initialAppointmentTime': value['initialAppointmentTime'],
        'secondaryProcedures': value['secondaryProcedures'] == null ? undefined : ((value['secondaryProcedures'] as Array<any>).map(ProcedureToJSON)),
        'consultant': ClinicianToJSON(value['consultant']),
        'site': SiteToJSON(value['site']),
    };
}

