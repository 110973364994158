import { UPDATE_SERVER_CONFIG } from '../actions/Actions';

const initialState = {
  serverUrl: ''
};

const serverConfigReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_SERVER_CONFIG:
      console.log('Update server config ', action);
      return { ...state, serverUrl: payload.serverUrl };
    default:
      return state;
  }
};

export default serverConfigReducer;
