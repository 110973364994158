/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Prom } from './Prom';
import {
    PromFromJSON,
    PromFromJSONTyped,
    PromToJSON,
} from './Prom';

/**
 * Query for pathway data
 * @export
 * @interface PathwayQueryDto
 */
export interface PathwayQueryDto {
    /**
     * List of proms and subtemplates to be queried
     * @type {Array<Prom>}
     * @memberof PathwayQueryDto
     */
    promQueryList?: Array<Prom>;
    /**
     * Procedure compositions uuids
     * @type {Array<string>}
     * @memberof PathwayQueryDto
     */
    procedureCompositionUUIDs?: Array<string>;
    /**
     * Id of pathway being queried
     * @type {string}
     * @memberof PathwayQueryDto
     */
    pathwayId?: string;
    /**
     * The user for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof PathwayQueryDto
     */
    userId?: string;
    /**
     * The namespace for the userId for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof PathwayQueryDto
     */
    userIdNamespace?: string;
}

/**
 * Check if a given object implements the PathwayQueryDto interface.
 */
export function instanceOfPathwayQueryDto(value: object): value is PathwayQueryDto {
    return true;
}

export function PathwayQueryDtoFromJSON(json: any): PathwayQueryDto {
    return PathwayQueryDtoFromJSONTyped(json, false);
}

export function PathwayQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PathwayQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'promQueryList': json['promQueryList'] == null ? undefined : ((json['promQueryList'] as Array<any>).map(PromFromJSON)),
        'procedureCompositionUUIDs': json['procedureCompositionUUIDs'] == null ? undefined : json['procedureCompositionUUIDs'],
        'pathwayId': json['pathwayId'] == null ? undefined : json['pathwayId'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'userIdNamespace': json['userIdNamespace'] == null ? undefined : json['userIdNamespace'],
    };
}

export function PathwayQueryDtoToJSON(value?: PathwayQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'promQueryList': value['promQueryList'] == null ? undefined : ((value['promQueryList'] as Array<any>).map(PromToJSON)),
        'procedureCompositionUUIDs': value['procedureCompositionUUIDs'],
        'pathwayId': value['pathwayId'],
        'userId': value['userId'],
        'userIdNamespace': value['userIdNamespace'],
    };
}

