/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAuthError,
  ApiValidationErrors,
  PathwayTemplateDto,
  PathwayTemplateSearchResponse,
  SearchRequest,
} from '../models/index';
import {
    ApiAuthErrorFromJSON,
    ApiAuthErrorToJSON,
    ApiValidationErrorsFromJSON,
    ApiValidationErrorsToJSON,
    PathwayTemplateDtoFromJSON,
    PathwayTemplateDtoToJSON,
    PathwayTemplateSearchResponseFromJSON,
    PathwayTemplateSearchResponseToJSON,
    SearchRequestFromJSON,
    SearchRequestToJSON,
} from '../models/index';

export interface DeletePathwayRequest {
    pathwayId: string;
    pathwayName: string;
}

export interface GetPathwayTemplateJsonRequest {
    ids: Array<string>;
}

export interface GetTemplateListRequest {
    searchRequest: SearchRequest;
}

export interface ImportPathwayTemplateRequest {
    pathwayTemplateDto: PathwayTemplateDto;
}

/**
 * 
 */
export class PathwayTemplateEndpointApi extends runtime.BaseAPI {

    /**
     * Delete pathway template
     * Delete Pathway template
     */
    async deletePathwayRaw(requestParameters: DeletePathwayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['pathwayId'] == null) {
            throw new runtime.RequiredError(
                'pathwayId',
                'Required parameter "pathwayId" was null or undefined when calling deletePathway().'
            );
        }

        if (requestParameters['pathwayName'] == null) {
            throw new runtime.RequiredError(
                'pathwayName',
                'Required parameter "pathwayName" was null or undefined when calling deletePathway().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pathwayName'] != null) {
            queryParameters['pathwayName'] = requestParameters['pathwayName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/pathway/{pathwayId}`.replace(`{${"pathwayId"}}`, encodeURIComponent(String(requestParameters['pathwayId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete pathway template
     * Delete Pathway template
     */
    async deletePathway(requestParameters: DeletePathwayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePathwayRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllPathwayTemplateJsonRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PathwayTemplateDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/pathway/fetchall`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PathwayTemplateDtoFromJSON));
    }

    /**
     */
    async getAllPathwayTemplateJson(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PathwayTemplateDto>> {
        const response = await this.getAllPathwayTemplateJsonRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPathwayTemplateJsonRaw(requestParameters: GetPathwayTemplateJsonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PathwayTemplateDto>>> {
        if (requestParameters['ids'] == null) {
            throw new runtime.RequiredError(
                'ids',
                'Required parameter "ids" was null or undefined when calling getPathwayTemplateJson().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ids'] != null) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/pathway/fetch`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PathwayTemplateDtoFromJSON));
    }

    /**
     */
    async getPathwayTemplateJson(requestParameters: GetPathwayTemplateJsonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PathwayTemplateDto>> {
        const response = await this.getPathwayTemplateJsonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return list of pathway templates with search terms
     * Pathway Templates
     */
    async getTemplateListRaw(requestParameters: GetTemplateListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PathwayTemplateSearchResponse>> {
        if (requestParameters['searchRequest'] == null) {
            throw new runtime.RequiredError(
                'searchRequest',
                'Required parameter "searchRequest" was null or undefined when calling getTemplateList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/pathway/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters['searchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PathwayTemplateSearchResponseFromJSON(jsonValue));
    }

    /**
     * Return list of pathway templates with search terms
     * Pathway Templates
     */
    async getTemplateList(requestParameters: GetTemplateListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PathwayTemplateSearchResponse> {
        const response = await this.getTemplateListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async importPathwayTemplateRaw(requestParameters: ImportPathwayTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PathwayTemplateDto>> {
        if (requestParameters['pathwayTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'pathwayTemplateDto',
                'Required parameter "pathwayTemplateDto" was null or undefined when calling importPathwayTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/pathway`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PathwayTemplateDtoToJSON(requestParameters['pathwayTemplateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PathwayTemplateDtoFromJSON(jsonValue));
    }

    /**
     */
    async importPathwayTemplate(requestParameters: ImportPathwayTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PathwayTemplateDto> {
        const response = await this.importPathwayTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
