/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Prom } from './Prom';
import {
    PromFromJSON,
    PromFromJSONTyped,
    PromToJSON,
} from './Prom';

/**
 * 
 * @export
 * @interface Step
 */
export interface Step {
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof Step
     */
    timeScale?: number;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    timeUnits?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    anchor?: string;
    /**
     * 
     * @type {Array<Prom>}
     * @memberof Step
     */
    proms?: Array<Prom>;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    completed?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    scheduled?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    procedureId?: string;
    /**
     * 
     * @type {number}
     * @memberof Step
     */
    timeScaleDays?: number;
    /**
     * 
     * @type {Date}
     * @memberof Step
     */
    anchorStepDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    promsJson?: string;
    /**
     * 
     * @type {Date}
     * @memberof Step
     */
    expires?: Date;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    subProcedureId?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    updatedBy?: string;
}

/**
 * Check if a given object implements the Step interface.
 */
export function instanceOfStep(value: object): value is Step {
    return true;
}

export function StepFromJSON(json: any): Step {
    return StepFromJSONTyped(json, false);
}

export function StepFromJSONTyped(json: any, ignoreDiscriminator: boolean): Step {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'title': json['title'] == null ? undefined : json['title'],
        'type': json['type'] == null ? undefined : json['type'],
        'timeScale': json['timeScale'] == null ? undefined : json['timeScale'],
        'timeUnits': json['timeUnits'] == null ? undefined : json['timeUnits'],
        'anchor': json['anchor'] == null ? undefined : json['anchor'],
        'proms': json['proms'] == null ? undefined : ((json['proms'] as Array<any>).map(PromFromJSON)),
        'status': json['status'] == null ? undefined : json['status'],
        'completed': json['completed'] == null ? undefined : json['completed'],
        'scheduled': json['scheduled'] == null ? undefined : json['scheduled'],
        'procedureId': json['procedureId'] == null ? undefined : json['procedureId'],
        'timeScaleDays': json['timeScaleDays'] == null ? undefined : json['timeScaleDays'],
        'anchorStepDate': json['anchorStepDate'] == null ? undefined : (new Date(json['anchorStepDate'])),
        'promsJson': json['promsJson'] == null ? undefined : json['promsJson'],
        'expires': json['expires'] == null ? undefined : (new Date(json['expires'])),
        'subProcedureId': json['subProcedureId'] == null ? undefined : json['subProcedureId'],
        'updatedBy': json['updatedBy'] == null ? undefined : json['updatedBy'],
    };
}

export function StepToJSON(value?: Step | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'type': value['type'],
        'timeScale': value['timeScale'],
        'timeUnits': value['timeUnits'],
        'anchor': value['anchor'],
        'proms': value['proms'] == null ? undefined : ((value['proms'] as Array<any>).map(PromToJSON)),
        'status': value['status'],
        'completed': value['completed'],
        'scheduled': value['scheduled'],
        'procedureId': value['procedureId'],
        'timeScaleDays': value['timeScaleDays'],
        'anchorStepDate': value['anchorStepDate'] == null ? undefined : ((value['anchorStepDate']).toISOString()),
        'promsJson': value['promsJson'],
        'expires': value['expires'] == null ? undefined : ((value['expires']).toISOString()),
        'subProcedureId': value['subProcedureId'],
        'updatedBy': value['updatedBy'],
    };
}

