/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a questionnnaire pdf file
 * @export
 * @interface QuestionnairePdfDto
 */
export interface QuestionnairePdfDto {
    /**
     * promsId
     * @type {string}
     * @memberof QuestionnairePdfDto
     */
    promsId: string;
    /**
     * Filename
     * @type {string}
     * @memberof QuestionnairePdfDto
     */
    fileName: string;
}

/**
 * Check if a given object implements the QuestionnairePdfDto interface.
 */
export function instanceOfQuestionnairePdfDto(value: object): value is QuestionnairePdfDto {
    if (!('promsId' in value) || value['promsId'] === undefined) return false;
    if (!('fileName' in value) || value['fileName'] === undefined) return false;
    return true;
}

export function QuestionnairePdfDtoFromJSON(json: any): QuestionnairePdfDto {
    return QuestionnairePdfDtoFromJSONTyped(json, false);
}

export function QuestionnairePdfDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionnairePdfDto {
    if (json == null) {
        return json;
    }
    return {
        
        'promsId': json['promsId'],
        'fileName': json['fileName'],
    };
}

export function QuestionnairePdfDtoToJSON(value?: QuestionnairePdfDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'promsId': value['promsId'],
        'fileName': value['fileName'],
    };
}

