/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAuthError,
  ApiValidationErrors,
  OptTemplateDto,
  SearchRequest,
  TemplateDto,
  TemplateSearchResponse,
} from '../models/index';
import {
    ApiAuthErrorFromJSON,
    ApiAuthErrorToJSON,
    ApiValidationErrorsFromJSON,
    ApiValidationErrorsToJSON,
    OptTemplateDtoFromJSON,
    OptTemplateDtoToJSON,
    SearchRequestFromJSON,
    SearchRequestToJSON,
    TemplateDtoFromJSON,
    TemplateDtoToJSON,
    TemplateSearchResponseFromJSON,
    TemplateSearchResponseToJSON,
} from '../models/index';

export interface GenerateTemplateJsonRequest {
    templateId: string;
}

export interface GetOptTemplateListRequest {
    templateType: string;
    searchRequest: SearchRequest;
}

export interface ImportOptTemplateRequest {
    templateType: string;
    optTemplateDto: OptTemplateDto;
}

/**
 * 
 */
export class TemplateDevEndpointApi extends runtime.BaseAPI {

    /**
     */
    async generateTemplateJsonRaw(requestParameters: GenerateTemplateJsonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling generateTemplateJson().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['templateId'] != null) {
            queryParameters['templateId'] = requestParameters['templateId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/uitemplate/editor/generate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async generateTemplateJson(requestParameters: GenerateTemplateJsonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.generateTemplateJsonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOptTemplateListRaw(requestParameters: GetOptTemplateListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateSearchResponse>> {
        if (requestParameters['templateType'] == null) {
            throw new runtime.RequiredError(
                'templateType',
                'Required parameter "templateType" was null or undefined when calling getOptTemplateList().'
            );
        }

        if (requestParameters['searchRequest'] == null) {
            throw new runtime.RequiredError(
                'searchRequest',
                'Required parameter "searchRequest" was null or undefined when calling getOptTemplateList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/uitemplate/editor/{templateType}/search`.replace(`{${"templateType"}}`, encodeURIComponent(String(requestParameters['templateType']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters['searchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async getOptTemplateList(requestParameters: GetOptTemplateListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateSearchResponse> {
        const response = await this.getOptTemplateListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async importOptTemplateRaw(requestParameters: ImportOptTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateDto>> {
        if (requestParameters['templateType'] == null) {
            throw new runtime.RequiredError(
                'templateType',
                'Required parameter "templateType" was null or undefined when calling importOptTemplate().'
            );
        }

        if (requestParameters['optTemplateDto'] == null) {
            throw new runtime.RequiredError(
                'optTemplateDto',
                'Required parameter "optTemplateDto" was null or undefined when calling importOptTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth-user", []);
        }

        const response = await this.request({
            path: `/api/config/uitemplate/editor/{templateType}`.replace(`{${"templateType"}}`, encodeURIComponent(String(requestParameters['templateType']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OptTemplateDtoToJSON(requestParameters['optTemplateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateDtoFromJSON(jsonValue));
    }

    /**
     */
    async importOptTemplate(requestParameters: ImportOptTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateDto> {
        const response = await this.importOptTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
