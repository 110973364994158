/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a report url
 * @export
 * @interface ReportUrlDto
 */
export interface ReportUrlDto {
    /**
     * Report url
     * @type {string}
     * @memberof ReportUrlDto
     */
    url: string;
    /**
     * Report name
     * @type {string}
     * @memberof ReportUrlDto
     */
    name: string;
}

/**
 * Check if a given object implements the ReportUrlDto interface.
 */
export function instanceOfReportUrlDto(value: object): value is ReportUrlDto {
    if (!('url' in value) || value['url'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function ReportUrlDtoFromJSON(json: any): ReportUrlDto {
    return ReportUrlDtoFromJSONTyped(json, false);
}

export function ReportUrlDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportUrlDto {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'],
        'name': json['name'],
    };
}

export function ReportUrlDtoToJSON(value?: ReportUrlDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'url': value['url'],
        'name': value['name'],
    };
}

