/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.2.0-SNAPSHOT2
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PermissionDescriptionDto } from './PermissionDescriptionDto';
import {
    PermissionDescriptionDtoFromJSON,
    PermissionDescriptionDtoFromJSONTyped,
    PermissionDescriptionDtoToJSON,
} from './PermissionDescriptionDto';
import type { RoleDefaultPermissionsDto } from './RoleDefaultPermissionsDto';
import {
    RoleDefaultPermissionsDtoFromJSON,
    RoleDefaultPermissionsDtoFromJSONTyped,
    RoleDefaultPermissionsDtoToJSON,
} from './RoleDefaultPermissionsDto';

/**
 * Represents the configuation of roles and default permissions
 * @export
 * @interface RoleConfigResponseDto
 */
export interface RoleConfigResponseDto {
    /**
     * Roles with default permissions
     * @type {Array<RoleDefaultPermissionsDto>}
     * @memberof RoleConfigResponseDto
     */
    rolePermissions?: Array<RoleDefaultPermissionsDto>;
    /**
     * Description of permissions
     * @type {Array<PermissionDescriptionDto>}
     * @memberof RoleConfigResponseDto
     */
    permissionDescriptions?: Array<PermissionDescriptionDto>;
}

/**
 * Check if a given object implements the RoleConfigResponseDto interface.
 */
export function instanceOfRoleConfigResponseDto(value: object): value is RoleConfigResponseDto {
    return true;
}

export function RoleConfigResponseDtoFromJSON(json: any): RoleConfigResponseDto {
    return RoleConfigResponseDtoFromJSONTyped(json, false);
}

export function RoleConfigResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleConfigResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'rolePermissions': json['rolePermissions'] == null ? undefined : ((json['rolePermissions'] as Array<any>).map(RoleDefaultPermissionsDtoFromJSON)),
        'permissionDescriptions': json['permissionDescriptions'] == null ? undefined : ((json['permissionDescriptions'] as Array<any>).map(PermissionDescriptionDtoFromJSON)),
    };
}

export function RoleConfigResponseDtoToJSON(value?: RoleConfigResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rolePermissions': value['rolePermissions'] == null ? undefined : ((value['rolePermissions'] as Array<any>).map(RoleDefaultPermissionsDtoToJSON)),
        'permissionDescriptions': value['permissionDescriptions'] == null ? undefined : ((value['permissionDescriptions'] as Array<any>).map(PermissionDescriptionDtoToJSON)),
    };
}

