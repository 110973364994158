import { TemplateDevEndpointApi } from 'promsApi';
import {
  PROMS_DEV_IS_LOADING,
  PROMS_DEV_FETCH_OPT_LIST,
  PROMS_DEV_UPLOAD_TEMPLATE,
  PROMS_DEV_HAS_ERROR,
  PROMS_DEV_SUCCESS_CLEAR
} from './Actions';

import getConfiguation from './Configuration';
import onRequest from './Request';

const onError = (bool, errorMsg) => {
  return {
    type: PROMS_DEV_HAS_ERROR,
    hasError: bool,
    errorMsg
  };
};

const isLoading = (bool) => {
  return {
    type: PROMS_DEV_IS_LOADING,
    isLoading: bool
  };
};

const clearSuccess = () => {
  return {
    type: PROMS_DEV_SUCCESS_CLEAR
  };
};

const onFetchListSuccess = (payload, templateType) => {
  return {
    type: PROMS_DEV_FETCH_OPT_LIST,
    templateType,
    list: payload == null ? {} : payload.templateDtoList,
    total: payload.total,
    pageStart: payload.start,
    searchTerm: payload.searchTerm,
    orderTerm: payload.orderTerm,
    orderDirection: payload.orderDirection,
    fetchTime: new Date().getTime()
  };
};

const fetchTemplateList = (
  templateType,
  searchTerm,
  pageSize,
  start,
  orderTerm,
  orderDirection
) => {
  console.log('Fetch template list');

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const searchRequest = {
      searchTerms: { TEMPLATEID: searchTerm },
      pageSize,
      start,
      orderTerm,
      orderDirection
    };

    const config = getConfiguation(dispatch, getState);
    const templateApi = new TemplateDevEndpointApi(config);
    const request = templateApi.getOptTemplateList({ templateType, searchRequest });
    onRequest(request, dispatch, onFetchListSuccess, onError, isLoading, templateType, 'Error fetching templates');
  };
};

const onUploadTemplateSuccess = (response, templateType) => {
  return {
    type: PROMS_DEV_UPLOAD_TEMPLATE,
    payload: response,
    success: true,
    templateType
  };
};

const uploadOptTemplate = (optTemplate, templateType) => {
  console.log('upload Template');

  return (dispatch, getState) => {
    dispatch(isLoading(true));

    const config = getConfiguation(dispatch, getState);
    const templateApi = new TemplateDevEndpointApi(config);
    const dto = { template: optTemplate };
    const request = templateApi.importOptTemplate({ templateType, optTemplateDto: dto });
    onRequest(request, dispatch, onUploadTemplateSuccess, onError, isLoading, templateType, 'Error uploading template');
  };
};

export default {
  fetchTemplateList,
  uploadOptTemplate,
  clearSuccess,
  onError,

  PROMS: 'proms',
  PROCEDURE: 'procedure'
};
